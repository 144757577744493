/* CreateNewPie */

/*
  Accept the pieces of a pie (name, desrciption, slices), make the necessary
  calls and data transformations to turn them into a persisted pie. Returns
  the resultant pie ID.

  A slice can be passed as either a string (the sliceable ID) or a Slice as
  defined by updatePieTree. The slice array can be a combination of both.
*/

import { ApolloError } from '@apollo/client';
import castArray from 'lodash-es/castArray';
import isString from 'lodash-es/isString';
import partition from 'lodash-es/partition';
import { call, put } from 'redux-saga/effects';

import {
  SlicesQuerySagaDocument,
  SlicesQuerySagaQueryResult,
  UpdatePieTreeDocument,
  UpdatePieTreeMutationResult,
} from '~/graphql/hooks';

import { PieLabelEnum, UpdatePieTreeInput } from '~/graphql/types';
import { displaySuccessNotification } from '~/redux/actions';

import { ToastProps } from '~/toolbox/toast';

import { LocalSlice } from '../../../pie-trees';
import { apolloMutationSaga } from '../apolloMutationSaga';
import { apolloQuerySaga } from '../apolloQuerySaga';

export const INVALID_SLICEABLE = 'invalid';

type PieTreeSlice = {
  percentage: number;
  to: {
    id: string;
    type: string;
  };
};
type SliceInput = string | PieTreeSlice;

export function* createNewPie(
  name: string,
  description: string | null | undefined,
  slices: Array<SliceInput>,
  label?: PieLabelEnum,
  isCrypto?: boolean | null | undefined,
  skipTradeEstimation?: boolean | undefined,
): Generator<any, any, any> {
  const builtSlices = yield call(buildSlices, slices);

  // Filter out any slices with percentage 0
  const filteredSlices: Array<LocalSlice> = builtSlices.filter(
    // @ts-expect-error - TS7006 - Parameter 'slice' implicitly has an 'any' type.
    (slice) => slice.percentage > 0,
  );
  const pieTree = {
    type: 'new_pie',
    name,
    description,
    slices: filteredSlices,
  };
  const serializedTree = JSON.stringify(pieTree);

  try {
    const result: UpdatePieTreeMutationResult = yield call(apolloMutationSaga, {
      mutation: UpdatePieTreeDocument,
      variables: {
        input: {
          label,
          serializedTree,
          isCrypto,
          skipTradeEstimation,
        } satisfies UpdatePieTreeInput,
      },
    });

    yield put(displaySuccessNotification('Portfolio created'));

    return result.data?.updatePieTree?.pie?.id;
  } catch (e: any) {
    const error = e as ApolloError;
    // TODO we should handle the failure case and short circuit this saga
    // However we need to have a team discussion on how we want to handle this.
    for (const err of error.graphQLErrors) {
      const errorCode = err.extensions.code;
      if (
        errorCode === 'SLICEABLE_NOT_ACTIVE' ||
        errorCode === 'SLICEABLE_NOT_FOUND'
      ) {
        yield put({
          payload: {
            content: `We can't add this Pie to your Pies because it contains a delisted or inactive security`,
            kind: 'alert',
          } satisfies ToastProps,
          type: 'ADD_TOAST',
        });

        return INVALID_SLICEABLE;
      }
    }
  }
}

function* buildSlices(slices: Array<SliceInput>): Generator<any, any, any> {
  const [sliceablesToFetch, preBuiltSlices]: [
    Array<string>,
    Array<PieTreeSlice>,
  ] = partition(slices, isString);
  if (sliceablesToFetch.length === 0) {
    return preBuiltSlices;
  }

  const { data }: SlicesQuerySagaQueryResult = yield call(apolloQuerySaga, {
    query: SlicesQuerySagaDocument,
    variables: {
      sliceableIds: sliceablesToFetch,
    },
  });

  const remoteSliceables = data?.nodes as { id: string; __typename: string }[];

  const remoteSlices = castArray(remoteSliceables).map(readRemoteSliceable);

  const toAllocate = 100 - preBuiltSlices.reduce((p, v) => p + v.percentage, 0);
  const allocated = allocateSlices(remoteSlices, toAllocate);
  return [...preBuiltSlices, ...allocated];
}

function readRemoteSliceable(sliceable: {
  id: string;
  __typename: string;
}): PieTreeSlice {
  return {
    to: {
      type: readTypename(sliceable.__typename),
      id: sliceable.id,
    },
    percentage: 0,
  };
}

function readTypename(typename: string): string {
  switch (typename) {
    case 'Equity':
    case 'Fund':
      return 'security';
    case 'SystemPie':
    case 'UserPie':
      return 'old_pie';
    default:
      throw new Error('Invalid typename provided to readTypename');
  }
}

function allocateSlices(
  slices: Array<PieTreeSlice>,
  toAllocate: number,
): Array<PieTreeSlice> {
  const percentage = Math.floor(toAllocate / slices.length);
  const mod = toAllocate % slices.length;

  return slices.map((slice, index) => {
    return {
      ...slice,
      percentage: index < mod ? percentage + 1 : percentage,
    };
  });
}
