import { PXL, PM, Flex, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { RadioGroupField } from '~/forms/fields';
import {
  TransferAmountPresetOption,
  ScheduledTransferPresetOption,
} from '~/graphql/types';
import { Radio } from '~/toolbox/radio';
import { isNotNil, isNil } from '~/utils';
import { formatCurrencyWithCommas } from '~/utils/formatting';
type PaymentPresetsProps = {
  otherAmountField: React.ReactNode;
  selectedPreset: string | null | undefined;
  isOtherDisabled: boolean;
  isSchedule: boolean;
  onSelect: (value: any) => void;
  presets:
    | Maybe<TransferAmountPresetOption>[]
    | Maybe<ScheduledTransferPresetOption>[]
    | null
    | undefined;
  presetOther: string;
};

export const PaymentPresets = ({
  otherAmountField,
  selectedPreset,
  onSelect,
  presets,
  presetOther,
  isOtherDisabled,
  isSchedule,
}: PaymentPresetsProps) => {
  if (isNil(presets)) {
    return null;
  }
  const validPresets = presets.filter((preset) => isNotNil(preset));

  return (
    <RadioGroupField
      style={{
        padding: '16px 0 64px 16px',
      }}
      name="selectedPreset"
      size="small"
      onChange={onSelect}
    >
      {validPresets.map((preset) => {
        const numericValue =
          typeof preset.value === 'string'
            ? parseFloat(preset.value)
            : (preset.value ?? 0);
        const disabled = numericValue <= 0;

        const paymentValue =
          typeof preset.value === 'number' ? preset.value.toFixed(2) : '0.00';

        const secondaryContent = isSchedule
          ? preset.value
          : `$${formatCurrencyWithCommas(paymentValue)}`;

        const presetValue =
          preset.__typename === 'ScheduledTransferPresetOption'
            ? preset.indicator
            : `${String(preset.value)}-${String(preset.label)}`;

        return (
          <Radio.Choice
            key={preset.label}
            label={
              <>
                <PXL
                  fontWeight={600}
                  display="inline-flex"
                  alignItems="center"
                  content={
                    <>
                      {preset.label}{' '}
                      {preset.description && (
                        <Tooltip
                          placement="bottom-start"
                          body={<PM content={preset.description} />}
                          icon="tooltip16"
                          iconColor="foregroundPrimary"
                        />
                      )}
                    </>
                  }
                  color={
                    disabled
                      ? 'foregroundNeutralSecondary'
                      : 'foregroundNeutralMain'
                  }
                />
                <PM
                  color={
                    disabled
                      ? 'foregroundNeutralSecondary'
                      : 'foregroundNeutralMain'
                  }
                  content={secondaryContent}
                />
              </>
            }
            disabled={disabled}
            // Create a unique value in case multiple options of same value for one-time payments
            // Scheduled CC payments just use the indicator as the value
            value={presetValue}
            checked={presetValue === selectedPreset}
          />
        );
      })}
      <Radio.Choice
        key={presetOther}
        disabled={isOtherDisabled}
        checked={selectedPreset === presetOther}
        label={
          <Flex inline alignItems="center" m="-16px 0 -8px 0" height={70}>
            <PXL
              fontWeight={600}
              content={isSchedule ? 'Fixed amount' : 'Other amount'}
              color={
                isOtherDisabled
                  ? 'foregroundNeutralSecondary'
                  : 'foregroundNeutralMain'
              }
              minWidth={130}
              mt={8}
            />
            <Flex height={60}>{otherAmountField}</Flex>
          </Flex>
        }
        value={presetOther}
      />
    </RadioGroupField>
  );
};
