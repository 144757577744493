import { SagaIterator } from 'redux-saga';
import { call, put, select, spawn } from 'redux-saga/effects';

import {
  FullUserProfileSagaDocument,
  FullUserProfileSagaQueryResult,
} from '~/graphql/hooks';

import { OpenAccountInput, Profile, ProfileInput } from '~/graphql/types';
import { NavigateFunction } from '~/hooks/useNavigate';
import {
  hideLoadingSpinner,
  investAccountSetupFlowFinished,
  showLoadingSpinner,
} from '~/redux/actions';
import { apolloQuerySaga } from '~/redux/sagas/apolloQuerySaga';
import {
  changeStep,
  logTotalNetWorthAmount,
  logTotalNetWorthAnalytics,
  replaceRouterHistory,
} from '~/redux/sagas/flows/utils';
import {
  IDENTITY_FIRST_FLOW_STEPS as STEPS,
  INVEST_ACCOUNT_TYPES,
  RETIREMENT_ACCOUNT_TYPES,
} from '~/static-constants';

import { ToastProps } from '~/toolbox/toast';

import type { AppState } from '../../../../../reducers/types';
import { getLoggers } from '../../../../common';
import { openAccount } from '../../../../common/openAccountSaga';
import { mergeProfiles } from '../../../../common/updateUserProfile';
import { showFailureToast } from '../../../../showFailureToastSaga';
import { queryAccountCount } from '../../identityFirst/remote';

export function* handleFinishedReview(): SagaIterator {
  const { analytics } = yield call(getLoggers);

  try {
    yield put(showLoadingSpinner());

    const {
      userProfileInput,
      investInput,
      fundingType,
      phone,
      navigate,
      subProduct,
    }: {
      userProfileInput: ProfileInput | null;
      investInput: OpenAccountInput;
      fundingType: 'NEW' | 'ROLLOVER' | null;
      navigate: NavigateFunction;
      subProduct: string | null | undefined;
      phone: string | null | undefined;
    } = yield select((state: AppState) => ({
      investInput: state.newFlows.INVEST_ONBOARDING.input,
      phone: state.newFlows.INVEST_ONBOARDING.phone,
      fundingType: state.newFlows.onboarding.fundingType,
      navigate: state.routing.navigate,
      subProduct: state.newFlows.onboarding.subProduct,
      userProfileInput: state.newFlows.onboarding.userProfileInput,
    }));

    // Query the remote user profile so we can fill in whatever we don't have locally.
    const { data }: FullUserProfileSagaQueryResult = yield call(
      apolloQuerySaga,
      {
        query: FullUserProfileSagaDocument,
      },
    );

    const viewer = data?.viewer;

    const userProfile = viewer?.profile ? viewer?.profile : userProfileInput;
    const mergedProfile = yield call(
      mergeProfiles,
      userProfile as Profile,
      investInput as any,
      {
        isUpdateProfile: false,
      },
    );

    const openAccountInput = {
      ...mergedProfile,
      registration: investInput.registration ?? subProduct,
      transferInfoOptIn: investInput.transferInfoOptIn,
    } as OpenAccountInput;

    const { registration, suitability, transferInfoOptIn } = openAccountInput;
    const accountRegistration =
      registration || INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE;

    const result = yield call(openAccount, openAccountInput, phone);

    if (!result.didSucceed) {
      yield call(showFailureToast);
    } else {
      const accountsCount = yield call(queryAccountCount);

      // if initial account, fire total net worth analytics
      if (accountsCount === 1) {
        const totalNetWorthAmount = suitability?.totalNetWorth;

        if (!totalNetWorthAmount) {
          throw new Error(
            'Failed to collect totalNetWorthAmount for initial Invest Account.',
          );
        }

        yield spawn(logTotalNetWorthAnalytics, totalNetWorthAmount);
        yield spawn(logTotalNetWorthAmount, totalNetWorthAmount);
      }

      const accountId = readAccountId(result);

      const isCrypto = accountRegistration === 'CRYPTO';
      if (!accountId) {
        if (isCrypto) {
          analytics.recordEvent('m1_crypto_account_rejected');
        }
        throw new Error('Something went wrong.');
      }

      if (isCrypto) {
        analytics.recordEvent('m1_crypto_account_opened');
      } else if (
        accountRegistration === INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE
      ) {
        // if brokerage account, fire investment account opened
        analytics.recordEvent('investmentAccountOpened');
      } else if (
        Object.values(RETIREMENT_ACCOUNT_TYPES).includes(accountRegistration)
      ) {
        // analytics.recordEvent('m1_ira_account_opened_onboarding');
        analytics.recordEvent('m1_ira_account_opened_onboarding');
      }

      yield put({
        type: 'ENDED_INVEST_MARKETING_SESSION',
      });
      yield put({
        type: isCrypto
          ? 'SET_ACTIVE_CRYPTO_ACCOUNT'
          : 'SET_ACTIVE_INVEST_ACCOUNT',
        payload: accountId,
      });
      yield put({
        type: 'SET_USER_HAS_ONBOARDED',
        payload: Boolean(accountId),
      });

      // Could be used for non-crypto flow in the future too! Step will need updates, details are in the step component
      if (isCrypto) {
        yield call(changeStep, STEPS.CHOOSE_PIE);
      } else if (transferInfoOptIn) {
        yield call(changeStep, STEPS.SHOW_ROLLOVER_RECEIPT);
      } else {
        yield put(investAccountSetupFlowFinished(accountId));

        if (fundingType && fundingType === 'ROLLOVER') {
          // Update the history so that if the user tries to navigate back after this step they are redirected to the home page.
          yield call(navigate, { to: '/d/home', options: { replace: true } });
          yield call(navigate, {
            to: '/d/w/acat-wizard',
            options: {
              state: { accountId, isIRA: true },
            },
          });
        } else {
          // @ts-ignore Argument of type '(...args: any[]) => any' is not assignable to parameter of type 'Action '.
          yield call(replaceRouterHistory, '/d/home');
          yield call(navigate, {
            to: '/onboarding/initial-funding',
          });
        }
      }
    }
  } catch (e: any) {
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function readAccountId(result: Record<string, any>): string {
  return result?.outcome?.account?.id;
}
