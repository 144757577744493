import { Enhancer, withProps } from '~/hocs';

import { TextField, TextFieldProps } from './text-field';

const enhancer: Enhancer<TextFieldProps, any> = withProps({
  name: 'amount',
  maskType: 'money',
  autoComplete: 'off',
  maxLength: 12,
});

export const TransferAmountField = enhancer(TextField) as any;
