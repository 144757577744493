import { Box, Flex, HXXS, PS, PM, Tooltip } from '@m1/liquid-react';
import * as React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { PasswordInput } from '~/components/form/Inputs/PasswordInput';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { useWizardContext } from '~/flows/wizard';
import { FormMockerReactHookForm } from '~/forms/FormMockers/FormMockerReactHookForm';
import { email as emailValidator, securePassword } from '~/forms/validators';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { ProfileConfirmationRequiredDocuments } from '~/pages/onboarding/ProfileConfirmationRequiredDocuments';
import type { AppState } from '~/redux';
import { submitRegisterUserForm, submitReactFormData } from '~/redux/actions';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { getEnumEntries } from '~/utils';

import {
  EMAIL_AND_PASSWORD_DISCLAIMER,
  LEGAL_NAME_TOOLTIP_CONTENT,
  SIGNUP_DISCLAIMER,
} from '../constants';

export interface DirectToPersonalLoansSignUpFormValues {
  firstName: string;
  lastName: string;
  middleInitial?: string;
  suffix?: string;
  username: string;
  password: string;
}

export const DirectToPersonalLoansSignUpForm = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const queryStep = searchParams.get('step');
  const navigate = useNavigate();
  const { goTo } = useWizardContext();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, isDirty },
  } = useForm<DirectToPersonalLoansSignUpFormValues>();

  const isLoggedIn = useSelector((state: AppState) =>
    Boolean(state.auth.accessToken),
  );

  React.useEffect(() => {
    // If the user is actually logged in, reroute them to home page

    if (
      !isDirty &&
      isLoggedIn &&
      !isSubmitted &&
      Boolean(queryStep) === false
    ) {
      navigate({ to: '/d/home' });
    }
    isSubmitted && isLoggedIn && goTo('LOAN_USE_AMOUNT_AND_DURATION');
  }, [isLoggedIn, isDirty, isSubmitted, goTo, queryStep, navigate]);

  const onSubmit: SubmitHandler<DirectToPersonalLoansSignUpFormValues> = (
    data,
  ) => {
    // TODO: Double check best practices here but overriding password before storing it in redux store for now.
    const formDataWithoutPassword = { ...data, password: '********' };

    dispatch(
      submitReactFormData({
        directToPersonalLoansSignUpInfo: formDataWithoutPassword,
      }),
    );
    dispatch(
      submitRegisterUserForm({
        username: data.username,
        password: data.password,
      }),
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex alignItems="center" mt={24}>
          <HXXS content="Legal name" fontWeight={600} />
          <Tooltip
            icon="tooltip20"
            body={LEGAL_NAME_TOOLTIP_CONTENT}
            iconColor="primary"
          />
        </Flex>
        <Flex>
          <Box flexGrow={1} pr={16}>
            <ControlledInput
              name="firstName"
              rules={{
                required: 'Required',
              }}
              control={control}
              label="First name"
              maxLength={FIELD_MAX_LENGTHS.FIRST_NAME}
            />
          </Box>
          <Box width={120}>
            <ControlledInput
              name="middleInitial"
              rules={{
                required: false,
              }}
              control={control}
              label="M.I. (Opt.)"
              maxLength={FIELD_MAX_LENGTHS.MIDDLE_INITIAL}
            />
          </Box>
        </Flex>
        <ControlledInput
          name="lastName"
          rules={{
            required: 'Required',
          }}
          control={control}
          label="Last name"
          maxLength={FIELD_MAX_LENGTHS.LAST_NAME}
        />
        <ControlledDropdown
          name="suffix"
          label="Suffix"
          isSearchable={false}
          control={control}
          placeholder="Select"
          options={getEnumEntries('NameSuffixEnum').map(
            ({ name, description }) => ({
              label: description,
              value: name,
            }),
          )}
        />
        <HXXS content="Email and password" fontWeight={600} mb={8} mt={24} />
        <PM content={EMAIL_AND_PASSWORD_DISCLAIMER} />
        <ControlledInput
          name="username"
          rules={{
            required: 'Required',
            validate: (value = '') => {
              if (!value) {
                return undefined;
              }
              const errorMessage = emailValidator(value);
              return errorMessage ?? undefined;
            },
          }}
          label="Email"
          control={control}
          error={errors.username?.message}
        />
        <PasswordInput
          name="password"
          rules={{
            required: 'Required',
            validate: (value = '') => {
              if (!value) {
                return undefined;
              }
              const errorMessage = securePassword(value);
              return errorMessage ?? undefined;
            },
          }}
          maxLength={FIELD_MAX_LENGTHS.DEFAULT}
          label="Password"
          control={control}
          error={errors.password?.message}
        />
        <ProfileConfirmationRequiredDocuments />
        <PS color="foregroundNeutralSecondary" mt={16}>
          {SIGNUP_DISCLAIMER}
        </PS>
        <ResponsiveButton
          mt={48}
          type="submit"
          size="large"
          label="Continue"
          kind="primary"
        />
        <FormMockerReactHookForm
          setValue={setValue}
          fields={[
            {
              name: 'firstName',
              value: 'B0b',
            },
            {
              name: 'middleInitial',
              value: 'N',
            },
            {
              name: 'lastName',
              value: 'R0ss',
            },
            {
              name: 'username',
              value: `loan${Math.floor(
                Math.random() * 1000000,
              )}-ploe@example.com`,
            },
            {
              name: 'password',
              value: 'Test123456',
            },
          ]}
        />
      </form>
    </>
  );
};
