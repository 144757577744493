import { Box, Button, Flex, HM, PM, PXL, useTheme } from '@m1/liquid-react';

import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { EmailVerificationBanner } from '~/components/Banners';
import { CloseButton } from '~/components/Cover/components';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  FinancialInformationConfirm,
  IdentityFirstConfirmationScreenProfileSection,
  IdentityFirstConfirmationScreenSection,
} from '~/components/Onboarding';
import { SectionRowData } from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers';

import { useSavingsConfirmationQuery } from '~/graphql/hooks';
import { SavingsCoOwnerInformation } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import { useDispatch, useSelector } from '~/redux/hooks';
import { SavingsAccountType } from '~/redux/reducers/newFlows/reducers/savingsOnboardingReducer';
import { Divider } from '~/toolbox/divider';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { formatTrustedContactAsAnArray } from '../../invest/steps/InvestConfirmationStep/InvestConfirmationHelpers';

import { SavingsFullyPaidLendingDisclosure } from './../components/SavingsFullyPaidLendingDisclosure';

type SavingsCreateAccountReviewProps = {
  onFinishStep: (args?: 'edit') => void;
};

const coOwnerDetails = (
  { firstName, lastName, phoneNumber, email }: SavingsCoOwnerInformation,
  disabled: boolean,
) => [
  {
    label: "Co-owner's name",
    value: `${firstName} ${lastName}`,
    disabled,
  },
  {
    label: "Co-owner's phone",
    value: phoneNumber,
    disabled,
  },
  {
    label: "Co-owner's email",
    value: email,
    disabled,
  },
];

const getHeadingLabel = (accountType: SavingsAccountType | null) => {
  switch (accountType) {
    case 'IndividualSave':
      return 'Individual Savings Account details';
    case 'JointSaveCoOwner':
    case 'JointSaveInitiate':
      return 'Joint Save Account details';
    default:
      return 'Account details';
  }
};

export const SavingsConfirmation = ({
  onFinishStep,
}: SavingsCreateAccountReviewProps) => {
  const onboardingValue = useSelector<SavingsAccountType | null>(
    (state: AppState) => state.newFlows.SAVINGS_ONBOARDING.accountType,
  );
  const { data, loading } = useSavingsConfirmationQuery({
    variables: {
      onboardingValue,
    },
    skip: !onboardingValue,
  });
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallViewport = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.SMALL})`,
  });

  const {
    accountType,
    hasJointAccountInvitation,
    dueDiligenceAnswers,
    dueDiligenceQuestions,
    accountName,
    coOwner,
  } = useSelector((state) => state.newFlows.SAVINGS_ONBOARDING);
  const trustedContact = useSelector(
    (state) => state.newFlows.INVEST_ONBOARDING.input.trustedContact,
  );

  const requiredAgreements =
    data?.viewer.save?.savings?.savingsAgreements?.requiredAgreements;
  React.useEffect(() => {
    if (requiredAgreements?.signature) {
      dispatch({
        type: 'SET_TERMS_CONDITIONS_SIGNATURE',
        payload: requiredAgreements.signature,
      });
    }
  }, [dispatch, requiredAgreements?.signature]);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        navigate({ to: '/d/home' });
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  if (
    !onboardingValue ||
    !data?.viewer.save?.savings?.savingsAgreements?.requiredAgreements
  ) {
    return <GenericSystemError />;
  }

  const invitations =
    data?.viewer.save?.savings?.jointAccountInvitationsList?.edges;
  const filteredAgreements = requiredAgreements?.documents?.filter(
    (doc) => doc.title !== 'E-Sign Agreement',
  );

  const dueDiligenceRows = dueDiligenceQuestions?.reduce(
    (rows, question, i) => {
      // return question and matching answer at same index
      const correspondingAnswer = dueDiligenceAnswers[i];
      if (question.questionLabel && correspondingAnswer?.selectedAnswers) {
        rows.push({
          label: question.questionLabel,
          value: correspondingAnswer?.selectedAnswers
            .map((ans) => ans.answerLabel)
            .join(', '),
        });
      }
      return rows;
    },
    [] as Array<SectionRowData>,
  );
  const detailsRows = dueDiligenceRows;
  const accountTypeIsCash =
    accountType === 'IndividualCash' ||
    accountType === 'JointCashInitiate' ||
    accountType === 'JointCashCoOwner';

  let accountNameMerged = accountName;
  if (accountType !== 'IndividualSave' && accountType !== 'IndividualCash') {
    if (hasJointAccountInvitation && invitations?.length) {
      // Invitee should see the inviter's details from the invitation
      const invitation = invitations[0]?.node;
      if (invitation) {
        detailsRows?.unshift(...coOwnerDetails(invitation, true));
        accountNameMerged = invitation.accountName;
      }
    } else if (coOwner) {
      // Inviter should see the invitee's details from redux
      detailsRows?.unshift(...coOwnerDetails(coOwner, false));
    }
  }
  if (accountNameMerged) {
    // Account name should be at top
    detailsRows?.unshift({
      label: 'Account name',
      value: accountNameMerged,
      disabled:
        accountType === 'JointSaveCoOwner' ||
        accountType === 'JointCashCoOwner',
    });
  }

  if (accountTypeIsCash && trustedContact) {
    detailsRows?.push({
      label: 'Trusted contact',
      value: formatTrustedContactAsAnArray(trustedContact),
    });
  }

  if (!data?.viewer.profile || !data.viewer.onboarding || !data.viewer.user) {
    return <GenericSystemError />;
  }

  const { profile, onboarding, user } = data.viewer;
  const showModuleOneSection = user?.hasProduct;

  return (
    <Flex flexDirection="column" margin="0 auto" maxWidth="996px" px={16}>
      <EmailVerificationBanner />
      <HM my={32}>Does everything look correct?</HM>
      <CloseButton
        color="foregroundNeutralSecondary"
        onClick={() => navigate({ to: '/d/home' })}
      />
      {isSmallViewport ? (
        <Flex flexDirection="column">
          {showModuleOneSection && (
            <IdentityFirstConfirmationScreenProfileSection
              profile={profile}
              user={user}
            />
          )}
          <FinancialInformationConfirm
            analyticsEvent="m1_hysa_confirmation_edit"
            onboarding={onboarding}
            profile={profile}
          />
          {detailsRows && (
            <IdentityFirstConfirmationScreenSection
              headingLabel={getHeadingLabel(accountType)}
              editLabel="Edit"
              onEdit={() => {
                analytics.recordEvent('m1_hysa_confirmation_edit', null, {
                  section_clicked: 'save',
                });
                onFinishStep('edit');
              }}
              rows={detailsRows}
            />
          )}
        </Flex>
      ) : (
        <Flex>
          <Flex flexDirection="column" width="50%" pr={24}>
            {showModuleOneSection ? (
              <IdentityFirstConfirmationScreenProfileSection
                profile={profile}
                user={user}
              />
            ) : (
              <FinancialInformationConfirm
                analyticsEvent="m1_hysa_confirmation_edit"
                onboarding={onboarding}
                profile={profile}
              />
            )}
          </Flex>
          <Flex flexDirection="column" width="50%" pl={24}>
            {showModuleOneSection && (
              <FinancialInformationConfirm
                analyticsEvent="m1_hysa_confirmation_edit"
                onboarding={onboarding}
                profile={profile}
              />
            )}
            {detailsRows && (
              <IdentityFirstConfirmationScreenSection
                headingLabel={getHeadingLabel(accountType)}
                editLabel="Edit"
                onEdit={() => {
                  analytics.recordEvent('m1_hysa_confirmation_edit', null, {
                    section_clicked: 'save',
                  });
                  onFinishStep('edit');
                }}
                rows={detailsRows}
              />
            )}
          </Flex>
        </Flex>
      )}
      <Divider spacing="compact" />
      <PXL my="16px">Agreements</PXL>
      <Flex
        flexDirection="column"
        flexWrap="wrap"
        minHeight={188}
        maxHeight={450}
        minWidth={940}
      >
        {filteredAgreements?.map((agreement) => (
          <Box key={agreement.url} pb={8} maxWidth="50%">
            <Link
              fontWeight={400}
              key={agreement.title}
              mb="8px"
              target="_blank"
              to={agreement.url}
              underline
            >
              {agreement.title}
            </Link>
          </Box>
        ))}
      </Flex>

      <SavingsFullyPaidLendingDisclosure onboardingValue={onboardingValue} />

      <PM my={8} color="foregroundNeutralSecondary">
        {requiredAgreements?.postamble}
      </PM>
      <Divider spacing="compact" />
      <Flex>
        <Button
          my={16}
          kind="primary"
          size="large"
          label="Continue"
          type="submit"
          onClick={() => {
            analytics.recordEvent('m1_hysa_confirmation_clicked');
            return onFinishStep();
          }}
        />
      </Flex>
    </Flex>
  );
};
