import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import {
  SecurityDetail_Fund_Fragment,
  SecurityDetail_Equity_Fragment,
} from '~/graphql/types';
import { formatCurrencyOrNull } from '~/utils';

import { SecurityDataContainer } from '../SecurityDataContainer';
import { SecurityDataRow } from '../SecurityDataRow';

import { KeyDataLearnMore } from './KeyDataLearnMore';

type SecurityKeyDataProps = {
  security: SecurityDetail_Equity_Fragment | SecurityDetail_Fund_Fragment;
};

const StyledFlexContainer = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    flex-wrap: wrap;
    max-width: 380px;
  }
`;

export const EquityKeyData = ({ security }: SecurityKeyDataProps) => {
  const { fundamentals, __typename } = security;

  const dividendYield =
    fundamentals?.dividendYield && `${fundamentals.dividendYield}%`;

  return (
    <SecurityDataContainer
      headingContent="Key Data"
      flexDirection="column"
      learnMore={<KeyDataLearnMore />}
      maxWidth={790}
    >
      <StyledFlexContainer flexDirection="row" justifyContent="space-between">
        <Flex flexDirection="column" maxWidth={380} pr={100}>
          <SecurityDataRow
            headingContent="Current price"
            value={formatCurrencyOrNull(security.latestQuote?.lastPrice)}
          />
          <SecurityDataRow
            headingContent="Market cap"
            value={fundamentals?.marketCapShort}
          />
          <SecurityDataRow
            headingContent="Average daily volume"
            value={fundamentals?.averageDailyVolumeShort}
          />
          <SecurityDataRow
            headingContent="P/E ratio"
            value={fundamentals?.peRatio}
          />
        </Flex>
        <Flex flexDirection="column" maxWidth={380}>
          <SecurityDataRow headingContent="EPS" value={fundamentals?.eps} />
          <SecurityDataRow
            headingContent="Price to sales"
            value={fundamentals?.priceToSales}
          />
          <SecurityDataRow
            headingContent="Dividend yield"
            value={dividendYield}
          />

          {__typename === 'Fund' &&
            security.fundFundamentals?.netExpenseRatio && (
              <SecurityDataRow
                headingContent="Expense ratio"
                value={`${security.fundFundamentals?.netExpenseRatio}%`}
              />
            )}

          <SecurityDataRow headingContent="Beta" value={fundamentals?.beta} />
        </Flex>
      </StyledFlexContainer>
    </SecurityDataContainer>
  );
};
