import { PL, Box } from '@m1/liquid-react';
import * as React from 'react';

import { AuthenticateErrorEnum } from '~/graphql/types';
import type { AppState } from '~/redux';
import { useDispatch, useSelector, Dispatch } from '~/redux/hooks';
import { CodeInputDeprecated } from '~/toolbox/CodeInputDeprecated';

type SubmitInput = {
  code: string | null | undefined;
  dispatch: Dispatch;
};

const submitForm = ({ code, dispatch }: SubmitInput): void => {
  if (typeof code === 'string') {
    dispatch({
      type: 'SUBMIT_AMFA_CODE_FORM',
      payload: {
        emailCode: code,
      },
    });
  }
};

type ErrorMessageProps = {
  flowStateErrorCode: AuthenticateErrorEnum | null | undefined;
  showError: boolean;
};

const INNOCUOUS_ERROR_CODES = ['EMAIL_AMFA_REQUIRED'];

function mapErrorCodeToCopyMessage(
  flowStateErrorCode: AuthenticateErrorEnum,
): string {
  switch (flowStateErrorCode) {
    case 'INCORRECT_AMFA_CODE':
      return 'Incorrect code. Please re-enter your code or go back to the login screen and try again.';
    case 'AMFA_TOKEN_EXPIRED':
      return 'Your code has expired. Please try signing in again to get a new code.';
    default:
      return 'Something went wrong. Please re-enter your code or go back to the login screen and try again.';
  }
}

export const AmfaErrorMessage = ({
  flowStateErrorCode,
  showError,
}: ErrorMessageProps) => {
  if (
    !showError ||
    !flowStateErrorCode ||
    INNOCUOUS_ERROR_CODES.includes(flowStateErrorCode)
  ) {
    return null;
  }

  return (
    <PL
      color="critical"
      content={mapErrorCodeToCopyMessage(flowStateErrorCode)}
      pt={16}
      px={16}
      textAlign="center"
    />
  );
};

type UseErrorMessageStateInput = {
  attemptInProgress: boolean;
  hasAmfaError: boolean;
};

function useErrorMessageState({
  attemptInProgress,
  hasAmfaError,
}: UseErrorMessageStateInput): [boolean, (arg0: boolean) => void] {
  const [showError, setShowError] = React.useState<boolean>(true);

  React.useEffect(() => {
    return setShowError(!attemptInProgress && hasAmfaError);
  }, [attemptInProgress, hasAmfaError]);

  return [showError, setShowError];
}

export const AdaptiveMultiFactorAuthForm = () => {
  const dispatch = useDispatch();

  const flowStateErrorCode = useSelector<
    AuthenticateErrorEnum | null | undefined
  >((state: AppState) => state.newFlows.login.error);

  const attemptInProgress = useSelector<boolean>((state: AppState) => {
    return state.newFlows.login.attemptInProgress;
  });

  const hasAmfaError = React.useMemo<boolean>(() => {
    return Boolean(
      flowStateErrorCode && !INNOCUOUS_ERROR_CODES.includes(flowStateErrorCode),
    );
  }, [flowStateErrorCode]);

  const [showError, setShowError] = useErrorMessageState({
    attemptInProgress,
    hasAmfaError,
  });

  if (flowStateErrorCode === 'USER_ACCOUNT_LOCKED') {
    return (
      <PL color="critical" pt={16} px={16} textAlign="center">
        Something went wrong. Please try again later or contact Client Support.
      </PL>
    );
  }

  return (
    <Box mx="auto">
      <CodeInputDeprecated
        flexProps={{
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        behavior="auto-submit-just-once"
        loading={attemptInProgress}
        blockInternalSubmit={false}
        message={
          <AmfaErrorMessage
            flowStateErrorCode={flowStateErrorCode}
            showError={showError}
          />
        }
        onSubmit={(code) => {
          submitForm({
            code,
            dispatch,
          });
        }}
        onChange={() => {
          setShowError(false);
        }}
        hasError={showError}
        validationType="numeric"
      />
    </Box>
  );
};
