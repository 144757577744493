import React from 'react';

import { Limit, LimitChange } from '~/components/limit';

import { useFundScreenerResult } from './useFundScreenerResult';

export const FundLimits = () => {
  const { handleLimitChange, limitState } = useFundScreenerResult();

  const onLimitChange = handleLimitChange as LimitChange;

  return (
    <>
      <Limit
        limit={limitState.FUND_TOTAL_ASSETS}
        label="Total assets (AUM)"
        format="currency"
        onLimitChange={onLimitChange}
      />
      <Limit
        limit={limitState.FUND_DIVIDEND_YIELD}
        label="Dividend yield (%)"
        onLimitChange={onLimitChange}
      />
      <Limit
        limit={limitState.FUND_NET_EXPENSE_RATIO}
        label="Expense ratio (%)"
        onLimitChange={onLimitChange}
      />
    </>
  );
};
