import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';

import { SetRootPieDocument } from '~/graphql/hooks';
import { SetRootPieInput } from '~/graphql/types';
import { NavigateFunction } from '~/hooks/useNavigate';
import { hideLoadingSpinner, showLoadingSpinner } from '~/redux/actions';

import { ToastProps } from '~/toolbox/toast';

import { apolloMutationSaga } from '../apolloMutationSaga';
import { call } from '../effects';

export function* setRootPieSaga(
  accountId: string | null | undefined,
  pieId: string | null | undefined,
): SagaIterator<void> {
  try {
    const navigate: NavigateFunction = yield select(
      (state) => state.routing.navigate,
    );

    yield put(showLoadingSpinner());

    if (!accountId || !pieId) {
      throw new Error('accountId or pieId is missing');
    }
    yield call(apolloMutationSaga, {
      mutation: SetRootPieDocument,
      variables: {
        input: {
          accountId,
          pieId,
        } satisfies SetRootPieInput,
      },
    });
    yield call(navigate, { to: '/d/invest/fund-account' });
  } catch (e: any) {
    const defaultErrorMessage =
      'This update could not be saved. Try again or contact us for further assistance. ';

    yield put({
      payload: {
        content: e.message ?? defaultErrorMessage,
        duration: 'short',
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}
