import {
  AvailableFundingSourcesPageDocument,
  useCancelAchRelationshipMutation,
} from '~/graphql/hooks';
import { CancelAchRelationshipInput } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { useNavigate } from './useNavigate';
import { usePortaledSpinner } from './usePortaledSpinner';

export const useCancelAchRelationship = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const accountId = useSelector((state) => state.global.activeAccountId);
  const [cancelAchRelationship, { loading }] = useCancelAchRelationshipMutation(
    {
      refetchQueries: [{ query: AvailableFundingSourcesPageDocument }],
    },
  );

  usePortaledSpinner(loading);

  const handleCancel = ({
    achRelationshipId,
  }: {
    achRelationshipId: CancelAchRelationshipInput['achRelationshipId'];
  }) => {
    cancelAchRelationship({
      variables: {
        input: {
          accountId: accountId as string,
          achRelationshipId,
        },
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
          duration: 'long',
        });
      },
    });
    navigate({ to: '/d/invest/bank-connection' });
  };

  return handleCancel;
};
