import { Button, Flex, HM, HS, PS, Card } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { LabelWithValue } from '~/components/label-with-value';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { TransferRequirementsTimingDescriptionCell } from '~/components/transfers/TransferRequirementsTimingDescriptionCell';
import { WidthConstrainerWithArrow } from '~/components/WidthConstrainerWithArrow';
import { useConfirmPaymentStepQuery } from '~/graphql/hooks';
import { TransferParticipantFragment, TransferTypeEnum } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';
import { CashFormatter, isNil, isNotNil } from '~/utils';

type Props = {
  onFinishStep: (...args: Array<any>) => any;
};

export type WithholdingTotals = {
  bankAmount: number;
  federalDollarAmount: number;
  stateDollarAmount: number;
  isValid: boolean;
} | null;

export const ConfirmPaymentStep = ({ onFinishStep }: Props) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleConfirm = () => onFinishStep();
  const { amount, fromParticipantId, toParticipantId, transferType } =
    useSelector((state) => {
      const { input, mode } = state.newFlows.CREATE_PAYMENT;

      return {
        amount: input.amount,
        fromParticipantId: input.fromParticipantId,
        toParticipantId: input.toParticipantId,
        transferType:
          mode === MODES.SCHEDULE
            ? 'SCHEDULED_TRANSFER_RULE'
            : 'TRANSFER_INSTANCE',
      };
    });

  const { data, loading } = useConfirmPaymentStepQuery({
    variables: {
      fromParticipantId: fromParticipantId as string,
      toParticipantId: toParticipantId as string,
      transferType: transferType as TransferTypeEnum,
      amount,
    },
    skip: !fromParticipantId || !toParticipantId || !transferType,
  });
  if (loading) {
    return <Spinner />;
  }

  if (isNil(data)) {
    return <GenericSystemError />;
  }

  const requirements = data?.viewer.transfers?.requirements;
  const isCreditCardPayment = Boolean(
    data?.viewer.transfers?.requirements?.isCreditCardPaymentTransfer,
  );
  const isPersonalLoanPayment = Boolean(
    data?.viewer.transfers?.requirements?.isLoanPaymentTransfer,
  );
  const source = data?.source as TransferParticipantFragment | null | undefined;
  const destination = data?.destination as
    | TransferParticipantFragment
    | null
    | undefined;

  return (
    <>
      <HS>
        Confirm one-time{' '}
        {isCreditCardPayment || isPersonalLoanPayment ? 'payment' : 'transfer'}
      </HS>
      <Card mt={32} p={32}>
        {isNotNil(amount) && (
          <LabelWithValue
            label="Amount"
            value={<HM content={CashFormatter.format(amount)} />}
          />
        )}
        {isNotNil(requirements?.summaryMessage) && (
          <PS
            color="foregroundNeutralSecondary"
            content={requirements.summaryMessage}
            pt={16}
            pb={8}
          />
        )}
        <Divider />

        <Grid.Row
          style={{
            marginTop: 16,
          }}
        >
          <Grid.Col xs={6}>
            <LabelWithValue
              label="From"
              value={
                source ? (
                  <WidthConstrainerWithArrow>
                    <TransferParticipantCell transferParticipant={source} />
                  </WidthConstrainerWithArrow>
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <LabelWithValue
              label="To"
              value={
                destination ? (
                  <TransferParticipantCell transferParticipant={destination} />
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          {isNotNil(requirements) && (
            <TransferRequirementsTimingDescriptionCell
              transferRequirements={requirements}
            />
          )}
        </Grid.Row>
        <Flex justifyContent="space-evenly" flexDirection="row" mt={32}>
          <Flex width={203}>
            <Button
              kind="secondary"
              label="Back"
              onClick={handleBack}
              size="large"
              fullWidth
            />
          </Flex>
          <Flex width={203}>
            <Button
              kind="primary"
              label="Confirm"
              onClick={handleConfirm}
              size="large"
              fullWidth
            />
          </Flex>
        </Flex>
      </Card>
    </>
  );
};
