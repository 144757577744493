import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { PageErrorBoundary } from '~/components/page-error-boundary';
import { returnToResolveMarginCallInformation } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { CreateTransferPage } from '../../create-transfer/CreateTransferPage';

// Styled Components
const StyledContainer = styled(Flex)`
  margin: auto;
  width: 550px;
`;

export const ResolveMarginCallCreateTransfer = () => {
  const dispatch = useDispatch();

  return (
    <PageErrorBoundary>
      <StyledContainer flexDirection="column" pb={32} pt={100}>
        <BackArrow
          content="Resolve Call Options"
          mb={-80}
          onClick={() =>
            dispatch(returnToResolveMarginCallInformation(undefined))
          }
        />
        <CreateTransferPage />
      </StyledContainer>
    </PageErrorBoundary>
  );
};
