import { SagaIterator } from 'redux-saga';
import { call, select, take } from 'redux-saga/effects';

import { NavigateFunction } from '~/hooks/useNavigate';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

export function* referralsSignupMonitor(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield take(ACTIONS.FINISHED_REGISTER_USER_FLOW);
  yield call(navigate, { to: '/onboarding/phone-verification' });
}
