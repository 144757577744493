import mapValues from 'lodash-es/mapValues';
import { SagaIterator, Task } from 'redux-saga';
import { cancel, spawn, takeEvery } from 'redux-saga/effects';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import {
  EnteredPageAction,
  ExitedPageAction,
} from '~/redux/actions/app/appActions.types';

import { isNotNil } from '~/utils';

import { editSmartTransferMonitor } from './edit-smart-transfer';
import { loginMonitor } from './login';
import { openAdditionalAccountsMonitor } from './open-additional-account';
import { referralsSignupMonitor } from './referrals-signup';
import { shareMonitor } from './share';
import { signupMonitorSaga } from './signupMonitorSaga';
import { transferInstanceDetailsMonitor } from './transfer-instance-details';
import { verifyEmailMonitor } from './verify-email';

/*
  This was put together pretty quickly, but the concept seems strong. General
  idea is to keep around tasks for each defined page monitor. These sagas
  will auto spawn and cancel these tasks when the page is entered and exited.

  This allows custom logic for flows on a per-page level, without tying the
  flow to the specific page.

  TODO
  - Explore abstractions around action dispatches
  - Further investigate cancel handling
  - Finish converting existing events
*/

export function* monitorPages(): SagaIterator<void> {
  const monitors = {
    EDIT_SMART_TRANSFER: editSmartTransferMonitor,
    LOGIN: loginMonitor,
    OPEN_ADDITIONAL_ACCOUNT: openAdditionalAccountsMonitor,
    REFERRALS_SIGNUP: referralsSignupMonitor,
    RESEARCH: null,
    SHARE: shareMonitor,
    SIGNUP: signupMonitorSaga,
    SPEND_ACTIVITY_ENTRY_DETAILS: null,
    SPEND_OVERVIEW: null,
    TRANSFERS: null,
    TRANSFER_INSTANCE_DETAILS: transferInstanceDetailsMonitor,
    VERIFY_EMAIL: verifyEmailMonitor,
  };
  const tasks: { [key: string]: Task | null } = mapValues(monitors, () => null);

  yield takeEvery(
    ACTIONS.ENTERED_PAGE,
    function* (action: EnteredPageAction): SagaIterator<void> {
      const page: keyof typeof monitors = action.meta.page;
      const task = tasks[page];
      const monitor = monitors[page];
      if (isNotNil(task) && task.isRunning()) {
        yield cancel(task);
      } else if (isNotNil(monitor)) {
        tasks[page] = yield spawn(monitor, action.payload);
      } else {
        // eslint-disable-next-line
        console.warn(`${page} monitor task is not defined, skipping spawn...`);
      }
    },
  );

  yield takeEvery(
    ACTIONS.EXITED_PAGE,
    function* (action: ExitedPageAction): SagaIterator<void> {
      const page = action.meta.page;
      const task = tasks[page];
      if (isNotNil(task)) {
        yield cancel(task);
        tasks[page] = null;
      }
    },
  );
}
