import { Box, Button, Flex, HXS, HXXS } from '@m1/liquid-react';
import React from 'react';

import { TransferParticipantDropdown } from '~/forms/fields';
import { TransferParticipantFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { DropdownGroup } from '~/toolbox/Dropdown';
import { isNotNil } from '~/utils';

import { useWireTransferContext } from '../useWireTransferContext';

const readOptions = (
  participants: TransferParticipantFragment[],
): DropdownGroup[] => {
  const sources = new Map();

  for (const {
    id,
    transferParticipantType,
    transferParticipantName,
  } of participants) {
    const option = {
      label: transferParticipantName,
      value: id,
    };
    const participantTypeLabel =
      transferParticipantType === 'SAVE' ? 'EARN' : transferParticipantType;
    const options = sources.get(participantTypeLabel);
    if (isNotNil(options)) {
      sources.set(participantTypeLabel, [...options, option]);
    } else {
      sources.set(participantTypeLabel, [option]);
    }
  }

  return Array.from(sources, ([label, options]) => ({
    label,
    options,
  }));
};

export const AccountSelectionForm = () => {
  const { accounts, selectedAccount, setSelectedAccount } =
    useWireTransferContext();
  const navigate = useNavigate();

  const accountOptions = isNotNil(accounts) ? readOptions(accounts) : null;

  return (
    <Flex width={490} mx="auto" py={100} flexDirection="column">
      <HXS color="foregroundNeutralMain" content="Wire money" />
      <Flex
        mt={32}
        p={32}
        backgroundColor="backgroundNeutralSecondary"
        borderColor="borderMain"
        borderStyle="solid"
        borderWidth={1}
        borderRadius={8}
        flexDirection="column"
      >
        <HXXS color="foregroundNeutralMain" content="Accounts" />
        <TransferParticipantDropdown
          name="selectedAccount"
          label="To"
          options={accountOptions}
          value={selectedAccount}
          onChange={(id: string) => setSelectedAccount?.(id)}
        />
        <Box alignSelf="center" pt={100}>
          <Button
            kind="primary"
            label="Continue"
            size="large"
            disabled={!selectedAccount}
            onClick={() =>
              navigate({ to: '/d/c/wire-transfer/wire-instructions' })
            }
          />
        </Box>
      </Flex>
    </Flex>
  );
};
