import { Box, Flex } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { useAccountCashBalanceControlQuery } from '~/graphql/hooks';
import { SetMaxCashThresholdInput } from '~/graphql/types';

import { Collapse } from '~/toolbox/collapse';
import { Spinner } from '~/toolbox/spinner';
import { Switch } from '~/toolbox/switch';
import { isNotNil } from '~/utils';

import { AutoInvestPopup } from './AutoInvestPopup';
import { StyledUpdateText } from './StyledUpdateText';
import { SuccessIcon } from './SuccessIcon';
import { useSetMaxCashThreshold } from './useSetMaxCashThreshold';

type CashBalanceControlFormProps = {
  hideCashMinimumInput?: boolean;
  accountId: string;
};

export const CashBalanceControlForm = ({
  hideCashMinimumInput,
  accountId,
}: CashBalanceControlFormProps) => {
  const { data, loading } = useAccountCashBalanceControlQuery({
    variables: {
      id: accountId,
    },
  });

  const { control, handleSubmit, formState, watch, setValue, reset } = useForm<
    SetMaxCashThresholdInput & { toggleState: boolean }
  >({
    defaultValues: {
      accountId,
      maxCashThreshold: 0,
      toggleState: false,
    },
  });
  const autoInvestSwitchState = watch('toggleState');
  const cashInputVisible =
    autoInvestSwitchState && hideCashMinimumInput !== true;

  React.useEffect(() => {
    if (data?.node?.__typename === 'Account') {
      const maxCashThreshold = data?.node?.maxCashThreshold;

      reset({
        accountId,
        maxCashThreshold,
        toggleState:
          typeof maxCashThreshold !== 'number' ? false : maxCashThreshold >= 0,
      });
    }
  }, [data]);

  const {
    handleUpdate,
    mutationResult: { loading: mutationLoading, data: mutationData },
  } = useSetMaxCashThreshold();

  React.useEffect(() => {
    if (mutationData) {
      setValue(
        'toggleState',
        isNotNil(mutationData?.setMaxCashThreshold?.account?.maxCashThreshold),
      );
      setValue(
        'maxCashThreshold',
        mutationData?.setMaxCashThreshold?.account?.maxCashThreshold,
      );
    }
  }, [mutationData, setValue]);

  if (loading) {
    return null;
  }

  if (data?.node?.__typename !== 'Account') {
    return null;
  }

  const disabled = !formState.isValid || !formState.isDirty;

  const renderSubmissionText = () => {
    const showSuccess = mutationData?.setMaxCashThreshold?.result.didSucceed;

    let updateTextByMutationState = 'Update';
    if (mutationLoading) {
      updateTextByMutationState = 'Updating';
    }

    if (showSuccess) {
      updateTextByMutationState = 'Updated';
    }

    if (formState.isDirty) {
      updateTextByMutationState = 'Update';
    }

    return (
      <Flex alignItems="center">
        <StyledUpdateText
          $disabled={disabled}
          $showLoading={mutationLoading}
          $showSuccess={showSuccess ?? false}
          color={disabled ? 'foregroundNeutralMain' : 'primary'}
          content={updateTextByMutationState}
          font="LS"
          onClick={handleSubmit(handleUpdateClick)}
        />
        {mutationLoading && <Spinner radius={22} thickness={3} />}
        {showSuccess && <SuccessIcon />}
      </Flex>
    );
  };

  const handleUpdateClick = (input: SetMaxCashThresholdInput) => {
    handleUpdate({
      input: {
        accountId: input.accountId,
        maxCashThreshold: input.maxCashThreshold,
      },
      analyticEvent: 'm1_auto_invest_cash_threshold_updated',
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !disabled && !mutationLoading) {
      handleSubmit(handleUpdateClick)();
    }
  };

  const onSwitchInputChange = (switchExpectedFutureState: boolean) => {
    const analyticEvent = switchExpectedFutureState
      ? 'm1_auto_invest_toggled_on'
      : 'm1_auto_invest_toggled_off';
    const maxCashThreshold = switchExpectedFutureState ? 0 : null;

    handleUpdate({
      input: {
        accountId,
        maxCashThreshold,
      },
      analyticEvent,
    });
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Flex alignItems="center" justifyContent="space-between">
        <AutoInvestPopup
          autoInvestTriggerMinimum={data.node.autoInvestTriggerMinimum}
        />
        <Switch
          checked={autoInvestSwitchState}
          disabled={mutationLoading}
          loading={mutationLoading}
          onChange={onSwitchInputChange}
        />
      </Flex>
      {hideCashMinimumInput ? null : (
        /* @ts-expect-error - TS2769 - No overload matches this call. */
        <Collapse isOpened={cashInputVisible}>
          <Box mb={16} pt={16}>
            <ControlledInput
              name="maxCashThreshold"
              autoComplete="off"
              maskType="money"
              control={control}
              additionalContent={renderSubmissionText()}
              label="Minimum cash"
              maxLength={11}
              min="0"
              rules={{
                required: true,
              }}
              placeholder="$0"
              onKeyDown={handleKeyDown}
              mask={{
                decimalScale: 0,
              }}
            />
          </Box>
        </Collapse>
      )}
    </form>
  );
};
