import React from 'react';

import { SliceableNameCell } from '~/components/pie';
import { StyledPerformanceCell } from '~/components/research/Screener';
import { SecurityMiniChartAndReturn } from '~/components/security/SecurityMiniChartAndReturn';
import { SecurityRiskLevel } from '~/components/security/SecurityRiskLevel';
import {
  HistoricalQuotePeriodEnum,
  ScreenerSecurityFragment,
} from '~/graphql/types';
import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { Table } from '~/toolbox/table';
import { formatNumber } from '~/utils';

import { useStockScreenerResult } from './useStockScreenerResult';

type StockScreenerTableRowProps = {
  security: ScreenerSecurityFragment | null | undefined;
};

export const StockScreenerTableRow = ({
  security,
}: StockScreenerTableRowProps) => {
  const { queryResult } = useStockScreenerResult();
  const dispatch = useDispatch();

  const { loading } = queryResult;

  if (!security?.node) {
    return null;
  }
  const { fundamentals, priceChange, symbol, id } = security.node;

  const marketCap =
    typeof fundamentals?.marketCap === 'number'
      ? formatNumber(fundamentals.marketCap, '0.0a').toUpperCase()
      : '--';

  const dividendYield =
    typeof fundamentals?.dividendYield === 'number'
      ? `${fundamentals.dividendYield.toFixed(2)}%`
      : '--';

  return (
    <Table.Row
      key={symbol}
      onClick={(event) => {
        // Explicitly check that the click target is not the checkbox inside the row.
        if (event.target?.nodeName !== 'INPUT') {
          dispatch(clickedOnSliceable(id));
        }
      }}
    >
      <Table.Cell
        content={<SliceableNameCell selectable sliceable={security.node} />}
      />
      <Table.Cell align="right" content={marketCap} />
      <Table.Cell align="right" content={dividendYield} />
      <Table.Cell align="right">
        <StyledPerformanceCell>
          {loading ? (
            <Spinner
              radius={22}
              thickness={2}
              secondaryColor="backgroundNeutralMain"
            />
          ) : (
            <SecurityMiniChartAndReturn
              period={
                queryResult.variables?.period ||
                HistoricalQuotePeriodEnum.OneYear
              }
              securityId={id}
              percentChange={priceChange?.percentChange}
            />
          )}
        </StyledPerformanceCell>
      </Table.Cell>
      <Table.Cell align="right">
        <SecurityRiskLevel
          align="right"
          security={security.node}
          size="small"
        />
      </Table.Cell>
    </Table.Row>
  );
};
