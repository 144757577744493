import { CampaignAttributionInput } from '~/graphql/types';
import type { AppState } from '~/redux';

export const APP_ACTIONS = Object.freeze({
  INITIALIZE_APP: 'INITIALIZE_APP' as const,
  APP_INIT_FAILURE: 'APP_INIT_FAILURE' as const,
  APP_INITIALIZED: 'APP_INITIALIZED' as const,
  LOAD_FROM_STORAGE: 'LOAD_FROM_STORAGE' as const,
  LOAD_SYSTEM_STATUS: 'LOAD_SYSTEM_STATUS' as const,
  CACHE_APP_CONFIG: 'CACHE_APP_CONFIG' as const,
  DETERMINED_REFERRAL: 'DETERMINED_REFERRAL' as const,
  DETERMINED_CAMPAIGN_ATTRIBUTION: 'DETERMINED_CAMPAIGN_ATTRIBUTION' as const,
  ENTERED_PAGE: 'ENTERED_PAGE' as const,
  EXITED_PAGE: 'EXITED_PAGE' as const,
});

export type Page =
  | 'EDIT_SMART_TRANSFER'
  | 'LOGIN'
  | 'OPEN_ADDITIONAL_ACCOUNT'
  | 'REFERRALS_SIGNUP'
  | 'RESEARCH'
  | 'SHARE'
  | 'SIGNUP'
  | 'SPEND_ACTIVITY_ENTRY_DETAILS'
  | 'SPEND_OVERVIEW'
  | 'TRANSFER_INSTANCE_DETAILS'
  | 'TRANSFERS'
  | 'VERIFY_EMAIL';

export type LoadFromStorageAction = {
  payload: Partial<AppState>;
  type: typeof APP_ACTIONS.LOAD_FROM_STORAGE;
};

export type DeterminedCampaignAttributionAction = {
  payload: CampaignAttributionInput | null | undefined;
  type: typeof APP_ACTIONS.DETERMINED_CAMPAIGN_ATTRIBUTION;
};

export type EnteredPageAction = {
  meta: {
    page: Page;
  };
  payload: any;
  type: 'ENTERED_PAGE';
};

export type ExitedPageAction = {
  meta: {
    page: Page;
  };
  payload: any;
  type: 'EXITED_PAGE';
};

export type InitializeAppAction = {
  type: typeof APP_ACTIONS.INITIALIZE_APP;
};

export type AppInitializedAction = {
  type: typeof APP_ACTIONS.APP_INITIALIZED;
};

export type AppInitFailureAction = {
  payload?: Error;
  type: typeof APP_ACTIONS.APP_INIT_FAILURE;
};

export type AppAction =
  | LoadFromStorageAction
  | DeterminedCampaignAttributionAction
  | EnteredPageAction
  | ExitedPageAction
  | InitializeAppAction
  | AppInitializedAction
  | AppInitFailureAction;
