import { Button, HM, HXS, Card } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { LabelWithValue } from '~/components/label-with-value';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { WidthConstrainerWithArrow } from '~/components/WidthConstrainerWithArrow';
import { useConfirmAutoPayQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';
import { CashFormatter, isNil, isNotNil } from '~/utils';

type Props = {
  onFinishStep: (...args: Array<any>) => any;
  router: any;
};

export const ConfirmAutoPayStep = (props: Props) => {
  const state = useSelector((state) => state.newFlows.CREATE_PAYMENT.input);
  const navigate = useNavigate();
  const {
    fromParticipantId,
    toParticipantId,
    amount,
    schedule,
    isPersonalLoanPayment,
    label,
  } = state;

  const date = React.useMemo(() => moment().toISOString(), []);

  const { data, loading } = useConfirmAutoPayQuery({
    variables: {
      date,
      destinationId: toParticipantId as string,
      schedule,
      sourceId: fromParticipantId as string,
    },
    skip: isNil(toParticipantId) || isNil(fromParticipantId),
  });
  const analytics = useAnalytics();

  if (loading) {
    return null;
  }

  const getScheduleAmount = () => {
    if (isNotNil(label)) {
      return label;
    }
    if (typeof amount === 'number') {
      return CashFormatter.format(amount);
    }
    return '$--';
  };

  const handleBack = () => navigate(-1);
  const handleConfirm = () => {
    if (isPersonalLoanPayment) {
      analytics.recordEvent('m1_personal_loan_autopay_confirmed');
    }
    props.onFinishStep();
  };

  const frequency = data?.viewer?.transfers?.scheduleDetails?.description;

  return (
    <>
      <HXS content="Confirm AutoPay" />
      <Card mt={32} p={32}>
        <LabelWithValue
          label="Amount"
          value={<HM content={getScheduleAmount()} />}
        />
        <Divider />

        <Grid.Row
          style={{
            marginTop: 16,
          }}
        >
          <Grid.Col xs={6}>
            <LabelWithValue
              label="From"
              value={
                data?.source && 'transferParticipantName' in data.source ? (
                  <WidthConstrainerWithArrow>
                    <TransferParticipantCell
                      transferParticipant={data.source}
                    />
                  </WidthConstrainerWithArrow>
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <LabelWithValue
              label="To"
              value={
                data?.destination &&
                'transferParticipantName' in data.destination ? (
                  <TransferParticipantCell
                    transferParticipant={data.destination}
                  />
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          {frequency && (
            <Grid.Col xs={12}>
              <LabelWithValue
                label="Frequency"
                style={{
                  marginTop: 32,
                }}
                value={frequency}
              />
            </Grid.Col>
          )}
        </Grid.Row>
      </Card>
      <ButtonGroup mt={64}>
        <Button kind="secondary" onClick={handleBack} size="large">
          Back
        </Button>
        <Button kind="primary" onClick={handleConfirm} size="large">
          Confirm
        </Button>
      </ButtonGroup>
    </>
  );
};
