import {
  BeginOnboardingFlowAction,
  ChangeOnboardingFlowStep,
  FinishedOnboardingAccountSetupAction,
  FinishedOnboardingBankSetupAction,
  FinishedReadingOnboardingInterstitialAction,
  ONBOARDING_ACTIONS,
  ReviewedInitialDepositErrorAction,
  SetIRAFundingTypeAction,
  SetOnboardingProduct,
  SetOnboardingSubProduct,
  SkippedBankSetupAction,
  SkippedInitialDepositAction,
  SkippedInitialFundingAction,
  SubmittedInitialDepositAction,
} from './onboardingActions.types';

export const beginOnboardingFlow = (
  payload: BeginOnboardingFlowAction['payload'],
): BeginOnboardingFlowAction => ({
  payload,
  type: ONBOARDING_ACTIONS.BEGIN_ONBOARDING_FLOW,
});

export const finishedReadingOnboardingInterstitial = (
  payload: FinishedReadingOnboardingInterstitialAction['payload'],
): FinishedReadingOnboardingInterstitialAction => ({
  payload,
  type: ONBOARDING_ACTIONS.FINISHED_READING_ONBOARDING_INTERSTITIAL,
});

export const finishedOnboardingAccountSetup = (
  payload: FinishedOnboardingAccountSetupAction['payload'],
): FinishedOnboardingAccountSetupAction => ({
  payload,
  type: ONBOARDING_ACTIONS.FINISHED_ONBOARDING_ACCOUNT_SETUP,
});

export const finishedOnboardingBankSetup = (
  payload: FinishedOnboardingBankSetupAction['payload'],
): FinishedOnboardingBankSetupAction => ({
  payload,
  type: ONBOARDING_ACTIONS.FINISHED_ONBOARDING_BANK_SETUP,
});

export const submittedInitialDeposit = (
  payload: SubmittedInitialDepositAction['payload'],
): SubmittedInitialDepositAction => ({
  payload,
  type: ONBOARDING_ACTIONS.SUBMITTED_INITIAL_DEPOSIT,
});

export const skippedInitialDeposit = (
  payload: SkippedInitialDepositAction['payload'],
): SkippedInitialDepositAction => ({
  payload,
  type: ONBOARDING_ACTIONS.SKIPPED_INITIAL_DEPOSIT,
});

export const reviewedInitialDepositError = (
  payload: ReviewedInitialDepositErrorAction['payload'],
): ReviewedInitialDepositErrorAction => ({
  payload,
  type: ONBOARDING_ACTIONS.REVIEWED_INITIAL_DEPOSIT_ERROR,
});

export const setOnboardingProduct = (
  payload: SetOnboardingProduct['payload'],
): SetOnboardingProduct => ({
  type: ONBOARDING_ACTIONS.SET_ONBOARDING_PRODUCT,
  payload,
});

export const setOnboardingSubProduct = (
  payload: SetOnboardingSubProduct['payload'],
): SetOnboardingSubProduct => ({
  type: ONBOARDING_ACTIONS.SET_ONBOARDING_SUBPRODUCT,
  payload,
});

export const skippedInitialFunding = (): SkippedInitialFundingAction => ({
  type: ONBOARDING_ACTIONS.SKIPPED_INITIAL_FUNDING,
});

export const skippedBankSetup = (): SkippedBankSetupAction => ({
  type: ONBOARDING_ACTIONS.SKIPPED_BANK_SETUP,
});

export const changeOnboardingFlowStep = (
  payload: ChangeOnboardingFlowStep['payload'],
) => ({
  type: ONBOARDING_ACTIONS.CHANGE_ONBOARDING_FLOW_STEP,
  payload,
});

export const setIRAFundingType = (
  payload: SetIRAFundingTypeAction['payload'],
) => ({
  type: ONBOARDING_ACTIONS.SET_IRA_FUNDING_TYPE,
  payload,
});
