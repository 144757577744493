import { Box, Flex, PS } from '@m1/liquid-react';
import * as React from 'react';

import { RichTextFragment } from '~/graphql/types';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useNavigate } from '~/hooks/useNavigate';
import { RichText } from '~/lens-toolbox/RichText';
import { useDispatch, useSelector } from '~/redux/hooks';
import { CashFormatter } from '~/utils/formatting';

import {
  StyledHeading,
  StyledHeadingLabel,
  StyledBorder,
} from '../Navigation.styled';
import { InvestAccount } from '../utils/getInvestAccounts';

import { SideNavLink } from './SideNavLink';

interface AccountsByType {
  crypto: InvestAccount[];
  retirement: InvestAccount[];
  broker: InvestAccount[];
}

export type SideNavInvestProps = {
  hasActiveInvestAccount: boolean;
  accounts: Array<InvestAccount>;
  selectedNavigation: DashboardNavigation;
  acatPromotion?: RichTextFragment | null;
};

const retirementAccountTypes = [
  'TRADITIONAL_IRA',
  'ROTH_IRA',
  'SEP_IRA',
  'ROLLOVER',
  'BENEFICIARY_IRA',
  'SIMPLE_IRA',
];

export const SideNavInvest = ({
  hasActiveInvestAccount,
  accounts,
  selectedNavigation,
  acatPromotion,
}: SideNavInvestProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cryptoSelected, setCryptoSelected] = React.useState<boolean>(false);
  const selectedCryptoAcctId = useSelector(
    (state) => state.global.cryptoAccountId,
  );

  const investOnClick = React.useCallback(
    (activeInvestAccount: InvestAccount) => {
      const isCrypto = activeInvestAccount.registration === 'CRYPTO';
      navigate({ to: '/d/invest/portfolio' });
      setCryptoSelected(isCrypto);
      dispatch({
        type: isCrypto
          ? 'SET_ACTIVE_CRYPTO_ACCOUNT'
          : 'SET_ACTIVE_INVEST_ACCOUNT',
        payload: activeInvestAccount.id,
      });
    },
    [dispatch, navigate],
  );

  const accountsByType = accounts.reduce(
    (acc, account) => {
      if (account.registration === 'CRYPTO') {
        acc.crypto.push(account);
      } else if (retirementAccountTypes.includes(account.registration)) {
        acc.retirement.push(account);
      } else {
        acc.broker.push(account);
      }
      return acc;
    },
    {
      crypto: [],
      retirement: [],
      broker: [],
    } as AccountsByType,
  );

  const l3paths = ['portfolio', 'activity', 'holdings', 'funding'];

  const getBrokerMarginSubText = (account: InvestAccount) => {
    const buyingMarginNumericValue =
      account.rootPortfolioSlice?.manualOrderBreakdown?.marginBuyingPower
        ?.numericValue;

    if (
      typeof buyingMarginNumericValue === 'number' &&
      buyingMarginNumericValue > 0
    ) {
      return `Invest up to ${account.rootPortfolioSlice?.buyingPower?.total?.value} with margin`;
    }
    return null;
  };

  return (
    <>
      {accountsByType.broker.length > 0 ? (
        <>
          <StyledHeading>
            <StyledHeadingLabel>Brokerage</StyledHeadingLabel>
          </StyledHeading>
          {accountsByType.broker.map((account, index) => {
            return (
              <Flex key={index}>
                <SideNavLink
                  data-testid={account.name}
                  selected={
                    selectedNavigation.activeL2Nav === account.id &&
                    l3paths.includes(selectedNavigation.activeL3Nav ?? '') &&
                    !cryptoSelected
                  }
                  key={index}
                  label={account.name}
                  subText={
                    <>
                      <PS>
                        {CashFormatter.format(
                          account.balance.totalValue?.value ?? 0,
                        )}
                      </PS>
                      <PS>{getBrokerMarginSubText(account)}</PS>
                    </>
                  }
                  onClick={() => investOnClick(account)}
                />
              </Flex>
            );
          })}
        </>
      ) : null}

      {accountsByType.retirement.length > 0 ? (
        <>
          <StyledHeading>
            <StyledHeadingLabel>Retirement</StyledHeadingLabel>
          </StyledHeading>
          {accountsByType.retirement.map((account, index) => {
            return (
              <Flex key={index}>
                <SideNavLink
                  selected={
                    selectedNavigation.activeL2Nav === account.id &&
                    l3paths.includes(selectedNavigation.activeL3Nav ?? '') &&
                    !cryptoSelected
                  }
                  key={index}
                  label={account.name}
                  subText={CashFormatter.format(
                    account.balance.totalValue?.value ?? 0,
                  )}
                  onClick={() => investOnClick(account)}
                />
              </Flex>
            );
          })}
        </>
      ) : null}

      {accountsByType.crypto.length > 0 ? (
        <>
          <StyledHeading>
            <StyledHeadingLabel>Crypto</StyledHeadingLabel>
          </StyledHeading>
          {accountsByType.crypto.map((account, index) => {
            return (
              <Flex key={index}>
                <SideNavLink
                  selected={
                    selectedCryptoAcctId === account.id &&
                    l3paths.includes(selectedNavigation.activeL3Nav ?? '') &&
                    cryptoSelected
                  }
                  key={index}
                  label={account.name}
                  subText={CashFormatter.format(
                    account.balance.totalValue?.value ?? 0,
                  )}
                  onClick={() => investOnClick(account)}
                />
              </Flex>
            );
          })}
        </>
      ) : null}

      <StyledBorder />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'open-invest-account'}
        key={accounts.length}
        label="Add account"
        subText="Brokerage, retirement and crypto"
        icon={{
          position: 'right',
          default: 'addBubble16',
          active: 'addBubble16',
        }}
        linkTo={
          hasActiveInvestAccount === true
            ? '/d/open-invest-account'
            : '/d/invest/invest-marketing'
        }
        analyticsProps={{
          name: 'add_account_clicked',
          parameters: {
            product: 'invest',
          },
        }}
        id="add-account-invest"
      />
      <StyledBorder />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'acat-wizard'}
        key={accounts.length + 1}
        label="Transfer to M1"
        subText={
          <Flex flexDirection="column">
            <Box>Partial or full account</Box>
            <RichText richText={acatPromotion} />
          </Flex>
        }
        icon={{
          position: 'right',
          default: 'transfer16',
          active: 'transfer16',
        }}
        linkTo="/d/w/acat-wizard"
      />
      <StyledBorder />
    </>
  );
};
