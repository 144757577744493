import { combineReducers } from 'redux';

import { FLOWS } from './newFlowsReducer.definition';

import {
  AccountSetupFlowState,
  accountSetup,
} from './reducers/accountSetupReducer';
import {
  ConnectBankFlowState,
  connectBank,
} from './reducers/connectBankReducer';
import {
  CreatePaymentFlowState,
  createPayment,
} from './reducers/createPaymentReducer';
import {
  CreateSmartTransferFlowState,
  createSmartTransfer,
} from './reducers/createSmartTransferReducer';
import {
  CreditCardApplicationFlowState,
  creditCardApplication,
} from './reducers/creditCardApplicationReducer';
import {
  IdentityFirstFlowState,
  IdentityFirst,
} from './reducers/identityFirstReducer';
import {
  IdentityProfileFlowState,
  identityProfile,
} from './reducers/identityProfileReducer';
import {
  InitialFundingState,
  initialFunding,
} from './reducers/initialFundingReducer';
import {
  InvestOnboardingFlowState,
  investOnboardingReducer,
} from './reducers/investOnboardingReducer';
import { LoginFlowState, login } from './reducers/loginReducer';
import {
  MarginCallInformationFlowState,
  marginCallInformation,
} from './reducers/marginCallInformationReducer';
import { MoveMoneyFlowState, moveMoney } from './reducers/moveMoneyReducer';
import { OnboardingFlowState, onboarding } from './reducers/onboardingReducer';
import {
  OpenInvestAccountFlowState,
  openInvestAccountReducer,
} from './reducers/openInvestAccountReducer';
import {
  PersonalLoansApplicationFlowState,
  personalLoansApplication,
} from './reducers/personalLoansApplicationReducer';
import {
  PhoneVerificationFlowState,
  phoneVerification,
} from './reducers/phoneVerificationReducer';
import {
  PortfolioEditorFlowState,
  portfolioEditorReducer,
} from './reducers/portfolioEditorReducer';
import {
  RegisterUserFlowState,
  registerUser,
} from './reducers/registerUserReducer';
import {
  ResetPasswordFlowState,
  resetPassword,
} from './reducers/resetPasswordReducer';
import {
  ResolveCallFlowState,
  resolveCall,
} from './reducers/resolveCallReducer';

import {
  SavingsOnboardingFlowState,
  savingsOnboardingReducer,
} from './reducers/savingsOnboardingReducer';
import { SetOrderFlowState, setOrder } from './reducers/setOrderReducer';
import { WaitlistFlowState, waitlistReducer } from './reducers/waitlistReducer';

// Flow doesn't allow keys to be symbolic. Be sure to match keys with values of FLOWS.
export type NewFlowsState = {
  accountSetup: AccountSetupFlowState;
  connectBank: ConnectBankFlowState;
  CREATE_SMART_TRANSFER: CreateSmartTransferFlowState;
  CREATE_PAYMENT: CreatePaymentFlowState;
  CREDIT_CARD_APPLICATION: CreditCardApplicationFlowState;
  IDENTITY_PROFILE: IdentityProfileFlowState;
  initialFunding: InitialFundingState;
  login: LoginFlowState;
  MARGIN_CALL_INFORMATION: MarginCallInformationFlowState;
  MOVE_MONEY: MoveMoneyFlowState;
  onboarding: OnboardingFlowState;
  IDENTITY_FIRST: IdentityFirstFlowState;
  INVEST_ONBOARDING: InvestOnboardingFlowState;
  PERSONAL_LOANS_APPLICATION: PersonalLoansApplicationFlowState;
  PHONE_VERIFICATION: PhoneVerificationFlowState;
  PORTFOLIO_EDITOR: PortfolioEditorFlowState;
  registerUser: RegisterUserFlowState;
  RESET_PASSWORD: ResetPasswordFlowState;
  RESOLVE_CALL: ResolveCallFlowState;
  SAVINGS_ONBOARDING: SavingsOnboardingFlowState;
  OPEN_INVEST_ACCOUNT: OpenInvestAccountFlowState;
  SET_ORDER: SetOrderFlowState;
  WAITLIST: WaitlistFlowState;
  // right now FINANCIAL_SUITABILITY is not a true flow
  // will remove/refactor when we implement useWizard progress bar updates for Module 2 & 3 for CX IO
  FINANCIAL_SUITABILITY: any;
};

export const createFlowsReducer = () => {
  return combineReducers({
    accountSetup,
    connectBank,
    [FLOWS.CREATE_SMART_TRANSFER]: createSmartTransfer,
    [FLOWS.CREATE_PAYMENT]: createPayment,
    [FLOWS.CREDIT_CARD_APPLICATION]: creditCardApplication,
    [FLOWS.IDENTITY_PROFILE]: identityProfile,
    initialFunding,
    login,
    [FLOWS.MARGIN_CALL_INFORMATION]: marginCallInformation,
    [FLOWS.MOVE_MONEY]: moveMoney,
    [FLOWS.IDENTITY_FIRST]: IdentityFirst,
    [FLOWS.PERSONAL_LOANS_APPLICATION]: personalLoansApplication,
    [FLOWS.PORTFOLIO_EDITOR]: portfolioEditorReducer,
    [FLOWS.INVEST_ONBOARDING]: investOnboardingReducer,
    onboarding,
    [FLOWS.PHONE_VERIFICATION]: phoneVerification,
    registerUser,
    [FLOWS.RESET_PASSWORD]: resetPassword,
    [FLOWS.SET_ORDER]: setOrder,
    [FLOWS.RESOLVE_CALL]: resolveCall,
    [FLOWS.WAITLIST]: waitlistReducer,
    [FLOWS.SAVINGS_ONBOARDING]: savingsOnboardingReducer,
    [FLOWS.OPEN_INVEST_ACCOUNT]: openInvestAccountReducer,
  });
};
