import { TransferRuleScheduleFragment } from '~/graphql/types';
import { getEnumDescription, isNil, isNotNil } from '~/utils';

type DataItem = {
  label: string;
  data: string;
};

export const getFrequencyDetails = (
  schedule: Maybe<TransferRuleScheduleFragment>,
): Maybe<DataItem> => {
  if (isNil(schedule)) {
    return null;
  }
  switch (schedule.__typename) {
    case 'MonthlySchedule':
      return {
        label: 'Day of month',
        data: getEnumDescription(
          schedule.dayOfMonth,
          'MonthlyScheduleDateEnum',
        ),
      };
    case 'BiweeklySchedule':
    case 'WeeklySchedule':
      return {
        label: 'Day of week',
        data: getEnumDescription(schedule.dayOfWeek, 'WeeklyScheduleDayEnum'),
      };
    default:
      return null;
  }
};

export const formatFrequencyTabularData = ({
  schedule,
  isAutoPay,
}: {
  schedule: TransferRuleScheduleFragment;
  isAutoPay: boolean;
}): DataItem[] => {
  const frequency = {
    label: 'Frequency',
    data: schedule.title,
  };

  const frequencyDetails = getFrequencyDetails(schedule);

  const frequencyTabularData = [frequency];

  if (isNotNil(frequencyDetails) && !isAutoPay) {
    frequencyTabularData.push(frequencyDetails);
  }

  return frequencyTabularData;
};
