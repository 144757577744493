import { styled } from '@m1/liquid-react';
import * as React from 'react';

import { TabProps } from './Tab';

export type TabsProps = {
  as?: React.ElementType;
  children: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
};

const StyledTabs = styled.div<TabsProps>`
  display: inline-flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const Tabs = ({ children, ...rest }: TabsProps) => {
  return <StyledTabs {...rest}>{children}</StyledTabs>;
};
