import {
  Button,
  HXL,
  PM,
  Flex,
  Box,
  styled,
  HXS,
  HXXS,
  PXL,
  Skeleton,
  useSkeleton,
  SemanticColorNames,
} from '@m1/liquid-react';
import moment from 'moment-timezone';
import * as React from 'react';

import { ChartableSliceChart } from '~/components/ChartableSliceChart/ChartableSliceChart';
import { ChartableSliceChartRangeSelectionChange } from '~/components/ChartableSliceChart/ChartableSliceChart.types';
import { useChartableSliceChartContext } from '~/components/ChartableSliceChart/ChartableSliceChartContext';
import { ChartableSliceChartProvider } from '~/components/ChartableSliceChart/ChartableSliceChartProvider';
import { MoveMoneyMenu } from '~/components/MoveMoneyMenu/MoveMoneyMenu';
import {
  HomePageQuery,
  OverviewBalancesFragment,
  ChartableSliceDatumFragment,
  PortfolioSlicePerformancePeriodEnum,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { CONTENT_PADDING, useLayout } from '~/hooks/useLayout';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { useSelector } from '~/redux/hooks';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { CashFormatter, formatNumber } from '~/utils';

import { HomeHeaderBankConnector } from './HomeHeaderBankConnector';
import { NetWorthChart } from './NetWorthChart';
import { NetWorthTooltip } from './NetWorthTooltip';

const StyledHeaderWrapper = styled(Flex)`
  background: ${(props) => props.theme.colors.gradientLinearFeature};
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    margin-bottom: 0;
  }
`;

const StyledHeaderContainer = styled(StyledPageContent)<{
  showAssetsAndLiabilities: boolean;
}>`
  display: flex;
  min-height: ${(props) => (props.showAssetsAndLiabilities ? '260px' : '84px')};
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 ${CONTENT_PADDING}px
    ${(props) => (props.showAssetsAndLiabilities ? '32px' : '0')} 80px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    padding: 0 48px 32px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    padding: 0 24px 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
    padding: 0 16px 16px;
  }
`;

const StyledHomeHeader = styled(Flex)`
  flex-direction: row;
  height: 84px;
  justify-content: space-between;
  padding: 20px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    height: 144px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
    align-items: center;
    flex-direction: column;
    height: 196px;
    width: inherit;
  }
`;

const StyledFlexNetWorth = styled(Flex)`
  border-top: ${(props) => `solid 1px ${props.theme.colors.borderFeature}`};
  flex-direction: row;
  justify-content: space-between;
  padding-top: 32px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    flex-direction: column;
  }
`;

const StyledBankAndMoveMoneyContainer = styled(Flex)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    flex-direction: column;
    width: 232px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
    width: 100%;
  }
`;

const StyledBankConnector = styled(Flex)`
  height: 44px;
  margin: 0 16px;
  max-width: 272px;
  min-width: 232px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    margin: 0 0 8px 0;
    max-width: none;
  }
`;

const AssetsLiabilitiesBox = styled(Box)`
  margin-top: 12px;
  width: 480px;

  @media screen and ${RESPONSIVE_BREAKPOINTS.MEDIUM} {
    width: 360px;
  }

  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    width: 300px;
  }
`;

const ChartContainer = styled(Box)`
  max-width: 860px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`;

const WelcomeAndName = ({ name }: { name: Maybe<string> }) => {
  if (window.matchMedia('(max-width: 927px)').matches) {
    return (
      <HXXS
        height={28}
        alignSelf="center"
        fontWeight={300}
        id="home-header-welcome"
        color="foregroundNeutralOnDark"
        content={`Welcome, ${name}`}
      />
    );
  }

  return (
    <HXS
      height={28}
      alignSelf="center"
      fontWeight={300}
      id="home-header-welcome"
      color="foregroundNeutralOnDark"
      content={`Welcome, ${name}`}
    />
  );
};

type HomePageQueryViewer = HomePageQuery['viewer'];

type HomeHeaderComponentProps = Partial<
  Pick<HomePageQueryViewer, 'netWorth' | 'profile' | 'save' | 'transfers'>
> & {
  balances?: OverviewBalancesFragment | null | undefined;
  liveNetWorthData?: ChartableSliceDatumFragment | null | undefined;
  showMoveMoney: boolean;
};

export const HomeHeader = ({
  showMoveMoney,
  balances,
  liveNetWorthData,
  netWorth,
  profile,
  save,
  transfers,
}: HomeHeaderComponentProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState<any>('max-content');
  const { contentWidth } = useLayout();
  const netWorthId = useSelector((state) => state.global.netWorthNodeId);
  const [chartRange, setChartRange] =
    React.useState<ChartableSliceChartRangeSelectionChange | null>(null);
  const { isLoading } = useSkeleton();
  const [period, setPeriod] =
    React.useState<PortfolioSlicePerformancePeriodEnum | null>(null);
  const { isChartDataNull } = useChartableSliceChartContext();

  const name = profile?.primary.firstName;
  const analytics = useAnalytics();

  const assetsValue = balances?.assets?.value;
  const liabilitiesValue = balances?.liabilities?.value;
  const assetRatio = balances?.assetRatio;

  const showAssetsAndLiabilities =
    typeof assetsValue === 'number' &&
    typeof liabilitiesValue === 'number' &&
    typeof assetRatio === 'number' &&
    (isChartDataNull || liveNetWorthData === null);
  const showNetWorthSection = typeof balances?.total === 'number';
  const netWorthTooltip = netWorth?.disclosuresTooltip;
  let netWorthDeltaCopy = null;
  let isNetWorthDeltaPositive = null;
  let netWorthDeltaColor: SemanticColorNames = 'foregroundNeutralOnDark';
  let netWorthDeltaPrefix = null;

  if (chartRange) {
    const { start, end, chartStartDate } = chartRange;
    const deltaDate = moment.utc(chartStartDate).format('MMM D, YYYY');

    if (period && period === PortfolioSlicePerformancePeriodEnum.Max) {
      netWorthDeltaCopy = `Since ${deltaDate}`;
    } else if (typeof end.y === 'number' && typeof start.y === 'number') {
      isNetWorthDeltaPositive = end.y - start.y >= 0;
      netWorthDeltaCopy = `${CashFormatter.format(end.y - start.y)} since ${deltaDate}`;
      netWorthDeltaPrefix = isNetWorthDeltaPositive ? '▲' : '▼';
      netWorthDeltaColor = isNetWorthDeltaPositive
        ? 'foregroundSuccessOnFeature'
        : 'foregroundCriticalOnFeature';
    }
  }

  React.useEffect(() => {
    setHeight(ref.current?.clientHeight);
  }, [
    ref.current?.clientHeight,
    isChartDataNull,
    showAssetsAndLiabilities,
    isLoading,
  ]);

  return (
    <StyledHeaderWrapper
      justifyContent="center"
      alignItems="start"
      height={height}
      maxHeight={height}
    >
      <StyledHeaderContainer
        ref={ref}
        showAssetsAndLiabilities={showAssetsAndLiabilities}
        contentWidth={contentWidth}
      >
        <StyledHomeHeader>
          <Skeleton skeletonHeight="100%" skeletonWidth={120} display="flex">
            <WelcomeAndName name={name} />
          </Skeleton>
          {showMoveMoney && (
            <StyledBankAndMoveMoneyContainer>
              <StyledBankConnector>
                <Skeleton
                  skeletonHeight="100%"
                  skeletonWidth="100%"
                  display="flex"
                  width="100%"
                >
                  <Flex width="100%">
                    <HomeHeaderBankConnector
                      save={save}
                      transfers={transfers}
                    />
                  </Flex>
                </Skeleton>
              </StyledBankConnector>
              <Skeleton borderRadius={1000}>
                <MoveMoneyMenu>
                  <Button
                    id="home-header-move-money"
                    kind="inverse-primary"
                    label="Move money"
                    size="medium"
                    fullWidth
                    onClick={(): void => {
                      analytics.recordEvent('m1_home_move_money_clicked');
                    }}
                  />
                </MoveMoneyMenu>
              </Skeleton>
            </StyledBankAndMoveMoneyContainer>
          )}
        </StyledHomeHeader>
        {(showNetWorthSection || isLoading) && (
          <StyledFlexNetWorth minHeight={isLoading ? 400 : 'unset'}>
            <Box flex="1">
              <Flex flexDirection="column" mb={8}>
                <Skeleton width="fit-content" mb={8}>
                  <PM
                    fontWeight={400}
                    content="M1 net worth"
                    color="foregroundNeutralOnDark"
                  />
                </Skeleton>
                <Skeleton
                  display="flex"
                  alignItems="center"
                  skeletonWidth={200}
                  width="fit-content"
                  mb={8}
                >
                  <HXL
                    fontWeight={300}
                    mr={8}
                    id="net-worth-total"
                    color="foregroundNeutralOnDark"
                    content={balances ? formatNumber(balances.total) : null}
                    data-testid="net-worth-total"
                  />
                  <ChartableSliceChartProvider>
                    <NetWorthTooltip
                      netWorthTooltip={netWorthTooltip}
                      showAssetsAndLiabilities={showAssetsAndLiabilities}
                    />
                  </ChartableSliceChartProvider>
                </Skeleton>

                <Skeleton
                  skeletonHeight={24}
                  skeletonWidth={160}
                  width="fit-content"
                  isLoading={
                    isLoading || (!netWorthDeltaCopy && !isChartDataNull)
                  }
                >
                  {netWorthDeltaCopy && (
                    <Flex color={netWorthDeltaColor} alignItems="center">
                      <Flex display="inline-flex" mr={4}>
                        {netWorthDeltaPrefix}
                      </Flex>
                      <PXL content={netWorthDeltaCopy} />
                    </Flex>
                  )}
                </Skeleton>
              </Flex>
              <AssetsLiabilitiesBox>
                {showAssetsAndLiabilities && (
                  <NetWorthChart
                    assets={assetsValue}
                    liabilities={liabilitiesValue}
                    assetRatio={assetRatio}
                  />
                )}
                {isChartDataNull && showAssetsAndLiabilities && (
                  <PM
                    mt={24}
                    color="borderFeature"
                    content="The larger net worth performance chart can't be displayed right now."
                  />
                )}
              </AssetsLiabilitiesBox>
            </Box>
            {!isChartDataNull && (
              <Flex
                flex="2"
                width="100%"
                justifyContent="end"
                overflowX="hidden"
              >
                <ChartContainer>
                  <ChartableSliceChart
                    chartableSliceIds={[netWorthId]}
                    chartName="net_worth"
                    features={{ print: false }}
                    periods={['1w', '1M', '3M', '6M', 'ytd', '1y', 'all']}
                    isOnDarkBackground
                    onRangeSelectionChange={setChartRange}
                    onChangePeriod={setPeriod}
                    liveDatum={liveNetWorthData}
                  />
                </ChartContainer>
              </Flex>
            )}
          </StyledFlexNetWorth>
        )}
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};
