import { useQuery } from '@apollo/client';
import React from 'react';

import {
  handleNextScreenerPage,
  handlePreviousScreenerPage,
  onlyActiveLimits,
  onScreenerSortChange,
} from '~/components/research/Screener/utils';

import {
  FundScreenerDocument,
  SecurityScreenerDocument,
} from '~/graphql/hooks';
import { HistoricalQuotePeriodEnum, SecurityTypeEnum } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';

import { useSearchParams } from '~/hooks/useSearchParams';

import {
  LimitOption,
  ScreenerQuery,
  ScreenerState,
  ScreenerVariables,
  useScreenerStateReturn,
} from './types';

export const useScreenerState = <T extends SecurityTypeEnum>({
  screenerType,
  defaultVariables,
  defaultLimits,
}: ScreenerState<T>): useScreenerStateReturn<T> => {
  const [prevCursor, setPrevCursor] = React.useState<(string | null)[]>([null]);
  const [limitState, setLimitState] = React.useState(defaultLimits);
  const { search, state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const getLimitArray = React.useCallback(
    (limitObject: typeof defaultLimits = limitState) => {
      return Object.values(limitObject).filter(
        onlyActiveLimits,
      ) as ScreenerVariables<T>['limit'];
    },
    [limitState],
  );

  const screenerQuery =
    screenerType === SecurityTypeEnum.Equity
      ? SecurityScreenerDocument
      : FundScreenerDocument;

  const { refetch, ...queryResultRest } = useQuery<
    ScreenerQuery<T>,
    ScreenerVariables<T>
  >(screenerQuery, {
    variables: defaultVariables,
  });

  React.useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const sort = queryParams.get('sort');
      const sortType = queryParams.get('sortType');
      const period = queryParams.get('period');
      const filterCategory = queryParams.getAll('filterCategory');

      const sortVariables =
        sort && sortType
          ? {
              direction: sort,
              type: sortType,
            }
          : defaultVariables.sort;

      const variables = {
        ...defaultVariables,
        period: period
          ? (period as HistoricalQuotePeriodEnum)
          : defaultVariables.period,
        filterCategory: filterCategory
          ? filterCategory
          : defaultVariables.filterCategory,
        sort: sortVariables,
        limit: getLimitArray(),
      } as ScreenerVariables<T>;

      refetch(variables);
    }
  }, [getLimitArray, refetch, search, defaultVariables]);

  const updateScreenerQuery = (args: any) => {
    setSearchParams(
      (prev) => {
        const sort = prev.get('sort');
        const sortType = prev.get('sortType');
        const period = prev.get('period');
        const filterCategory = prev.getAll('filterCategory');

        return {
          ...(sort && { sort }),
          ...(sortType && { sortType }),

          limit: getLimitArray(),
          filterCategory: filterCategory
            ? filterCategory
            : defaultVariables.filterCategory,
          period: period ? period : defaultVariables.period,
          ...args,
        };
      },
      {
        replace: true,
        state,
      },
    );
  };

  const handleSortChange = (
    sortType: Exclude<
      typeof defaultVariables.sort,
      any[] | null | undefined
    >['type'],
  ) => {
    const { direction, type } = onScreenerSortChange(
      queryResultRest.variables?.sort,
      sortType,
    )[0];

    updateScreenerQuery({
      sort: direction,
      sortType: type,
    });
  };

  const handleLimitChange = (limit: LimitOption<T>) => {
    const newLimit = { ...limitState, [limit.type]: limit };
    setLimitState(newLimit);

    const limits = getLimitArray(newLimit);

    refetch({
      limit: limits,
    } as ScreenerVariables<T>);
  };

  const handlePeriodChange = (period: HistoricalQuotePeriodEnum) => {
    updateScreenerQuery({ period });
  };

  const handleReset = () => {
    const { sort, period, filterCategory } = defaultVariables;

    const sortOptions = Array.isArray(sort) ? sort[0] : sort;

    const sortDirection = sortOptions?.direction;
    const sortType = sortOptions?.type;
    setLimitState(defaultLimits);

    setSearchParams(
      {
        ...(period && { period }),
        ...(filterCategory && { filterCategory }),
        ...(sortDirection && { sort: sortDirection }),
        ...(sortType && { sortType }),
      },
      {
        replace: true,
        state,
      },
    );
  };

  const hasCategoryFilters =
    searchParams.get('filterCategory') !== undefined &&
    Boolean(searchParams.getAll('filterCategory')?.length);
  const hasLimitFilters = Boolean(
    (queryResultRest.variables?.limit as any[])?.filter(onlyActiveLimits)
      .length,
  );

  const hasFilters = hasCategoryFilters || hasLimitFilters;

  const handleNextPage = React.useCallback(() => {
    handleNextScreenerPage({
      endCursor: queryResultRest.data?.viewer.securities.pageInfo.endCursor,
      fetchMore: queryResultRest.fetchMore,
      setPrevCursor,
    });
  }, [
    queryResultRest.fetchMore,
    queryResultRest.data?.viewer.securities.pageInfo.endCursor,
  ]);

  const handlePreviousPage = React.useCallback(() => {
    handlePreviousScreenerPage({
      setPrevCursor,
      prevCursor,
      fetchMore: queryResultRest.fetchMore,
    });
  }, [queryResultRest.fetchMore, prevCursor]);

  const hasPreviousPage = prevCursor.length > 1;

  return {
    updateScreenerQuery,
    hasFilters,
    handleLimitChange,
    handleSortChange,
    handlePeriodChange,
    handleReset,
    queryResult: { refetch, ...queryResultRest },
    handleNextPage,
    handlePreviousPage,
    hasPreviousPage,
    limitState,
    setLimitState,
  };
};
