import React from 'react';

import { ScreenerPriceHistory } from '~/components/research/Screener';
import {
  FundSortOptionInput,
  FundSortTypeEnum,
  HistoricalQuotePeriodEnum,
} from '~/graphql/types';
import { Table } from '~/toolbox/table';

import { PERIOD_TO_SORT } from './constants';
import { useFundScreenerResult } from './useFundScreenerResult';

export const FundScreenerTableHeader = () => {
  const {
    queryResult: { variables },
    handleSortChange,
    handlePeriodChange,
  } = useFundScreenerResult();

  const readSortDirection = (
    type: FundSortTypeEnum,
    sort: FundSortOptionInput | null | undefined,
  ): FundSortOptionInput['direction'] | null | undefined => {
    return sort && sort.type === type ? sort.direction : null;
  };

  const sort = Array.isArray(variables?.sort)
    ? variables?.sort[0]
    : variables?.sort;
  const period = variables?.period;

  const currentPeriodSort =
    PERIOD_TO_SORT[period || HistoricalQuotePeriodEnum.OneYear] ||
    FundSortTypeEnum.PriceChange_1Y;

  return (
    <Table.Head fixed>
      <Table.Row>
        <Table.HeaderCell
          width="36.5%"
          content="Name"
          onClick={() => handleSortChange(FundSortTypeEnum.Name)}
          sort={readSortDirection(FundSortTypeEnum.Name, sort)}
        />
        <Table.HeaderCell
          width="10%"
          align="right"
          content="AUM"
          onClick={() => handleSortChange(FundSortTypeEnum.FundTotalAssets)}
          sort={readSortDirection(FundSortTypeEnum.FundTotalAssets, sort)}
        />
        <Table.HeaderCell
          width="12%"
          align="right"
          content="Div. Yield"
          onClick={() => handleSortChange(FundSortTypeEnum.FundDividendYield)}
          sort={readSortDirection(FundSortTypeEnum.FundDividendYield, sort)}
        />
        <Table.HeaderCell
          align="right"
          onClick={() => handleSortChange(currentPeriodSort)}
          sort={readSortDirection(currentPeriodSort, sort)}
          // @ts-expect-error - TS2769 - No overload matches this call.
          style={{
            paddingLeft: 0,
            width: '30%',
          }}
        >
          <ScreenerPriceHistory handlePeriodChange={handlePeriodChange} />
        </Table.HeaderCell>

        <Table.HeaderCell
          width="12%"
          align="right"
          content="Exp. Ratio"
          onClick={() => handleSortChange(FundSortTypeEnum.FundNetExpenseRatio)}
          sort={readSortDirection(FundSortTypeEnum.FundNetExpenseRatio, sort)}
        />
      </Table.Row>
    </Table.Head>
  );
};
