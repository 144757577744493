import cloneDeep from 'lodash-es/cloneDeep';
import { SagaIterator } from 'redux-saga';
import { fork, setContext } from 'redux-saga/effects';

import {
  MoveSlicesDocument,
  MoveSlicesMutationResult,
  PortfolioEditorSagaFetchSliceableDocument,
  PortfolioEditorSagaFetchSliceableQueryResult,
  PortfolioEditorSagaFetchSliceablesDocument,
  PortfolioEditorSagaFetchSliceablesQueryResult,
  UpdatePieTreeMutationResult,
} from '~/graphql/hooks';
import {
  MoveSlicesInput,
  PortfolioEditorSagaFetchSliceableQuery,
  PortfolioEditorSagaFetchSliceablesQuery,
} from '~/graphql/types';
import { Navigate, NavigateFunction } from '~/hooks/useNavigate';
import { SentryReporter } from '~/loggers';
import {
  hasCircularReference,
  mapRemoteSliceableByType,
  Pie,
  preparePieTreeForUpdate,
  readPieTreeByPath,
  sortSlicesByPercentage,
} from '~/pie-trees';
import {
  AppAction,
  fetchedPieDataForPortfolioEditor,
  fetchedPieSliceablesForPortfolioEditor,
  fetchPieDataForPortfolioEditor,
  hideLoadingSpinner,
  showLoadingSpinner,
  updateUserData,
} from '~/redux/actions';
import {
  AddSlicesToPortfolioEditorAction as AddSlicesAction,
  ClickedMoveSlicesDestinationAction as ClickedMoveSlicesAction,
  FetchPieDataForPortfolioEditorAction as FetchPieDataAction,
  MoveSlicesAction,
} from '~/redux/actions/newFlows/portfolioEditor/portfolioEditorActions.types';
import { getActivePie } from '~/redux/reducers/newFlows/reducers/portfolioEditorReducer';
import { PORTFOLIO_EDITOR_STEPS } from '~/static-constants';

import { ToastProps } from '~/toolbox/toast';

import { apolloMutationSaga } from '../../apolloMutationSaga';
import { apolloQuerySaga } from '../../apolloQuerySaga';
import { getLoggers, getSentryReporter, updatePieTreeSaga } from '../../common';
import { call, put, select, takeEvery } from '../../effects';
import { changeStep, makeFlowFuncs } from '../utils';

import { mapFetchSliceablesResponseToSlices } from './portfolioEditorMappers';
import { mergeSlicesForMove } from './portfolioEditorSaga.utils';

const { takeFlow, takeFlowStep } = makeFlowFuncs('PORTFOLIO_EDITOR');

export function* portfolioEditorSaga(): SagaIterator<void> {
  yield fork(takeFlow, portfolioEditorFlow);

  yield takeEvery('BEGIN_FLOW', handleBeginPortfolioEditorFlow);

  yield takeEvery(
    'ADD_SLICES_TO_PORTFOLIO_EDITOR',
    handleGetSliceablesForEditor,
  );

  yield takeEvery('CLICKED_MOVE_SLICES_DESTINATION', clickedMoveSlices);

  yield takeEvery(
    'FETCH_PIE_DATA_FOR_PORTFOLIO_EDITOR',
    handleGetPieDataForEditor,
  );

  yield takeEvery(
    'CREATED_PORTFOLIO_EDITOR_NEW_PIE_SLICE',
    handleCreatedNewPieSlice,
  );

  yield takeEvery('MOVE_SLICES', handleMoveSlices);
  yield takeEvery('SAVE_PORTFOLIO_EDITOR', handleSavePortfolioEditor);
}

function* handleMoveSlices(action: MoveSlicesAction): SagaIterator {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const { analytics } = yield call(getLoggers);

  yield put(showLoadingSpinner());

  const defaultErrorMessage =
    'Something went wrong. Your holdings have not moved and your targets have not changed. Please try again or contact us.';

  try {
    const result: MoveSlicesMutationResult = yield call(apolloMutationSaga, {
      mutation: MoveSlicesDocument,
      variables: {
        input: {
          destinationPiePortfolioSliceId:
            action.payload.destinationPiePortfolioSliceId,
          destinationPieSerialized: action.payload.destinationPieSerialized,
          moveSliceIds: action.payload.moveSliceIds,
          sourcePieBeforeUpdatesSerialized:
            action.payload.sourcePieBeforeUpdatesSerialized,
          sourcePiePortfolioSliceId: action.payload.sourcePiePortfolioSliceId,
          sourcePieSerialized: action.payload.sourcePieSerialized,
        } satisfies MoveSlicesInput,
      },
    });

    if (result.data?.moveSlices?.didSucceed) {
      analytics.recordEvent('m1_moving_slices_success');

      yield call(navigate, { to: '/d/invest' });

      yield put(
        updateUserData({
          key: 'HAS_MOVED_VALUE',
          value: 'true',
        }),
      );

      yield put({
        type: 'ADD_TOAST',
        payload: {
          kind: 'success',
          duration: 'short',
          content:
            result.data?.moveSlices.successMessage ||
            'Your changes have been made.',
        } satisfies ToastProps,
      });
    } else {
      // because our network layer inspects the mutation response for the didSucceed flag this will never run.
      // however, it is included for correctness and future compatibility.
      analytics.recordEvent('m1_moving_slices_failure');

      yield put({
        type: 'ADD_TOAST',
        payload: {
          kind: 'alert',
          duration: 'short',
          content: result.data?.moveSlices?.errorMessage || defaultErrorMessage,
        } satisfies ToastProps,
      });
    }
  } catch (e: any) {
    analytics.recordEvent('m1_moving_slices_failure');

    yield put({
      type: 'ADD_TOAST',
      payload: {
        kind: 'alert',
        duration: 'short',
        content: e.message || defaultErrorMessage,
      } satisfies ToastProps,
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* portfolioEditorFlow(action: any): SagaIterator<void> {
  const { onFinish } = action.payload;

  yield fork(
    takeFlowStep,
    PORTFOLIO_EDITOR_STEPS.EDIT_PORTFOLIO,
    finishedEditPortfolio,
  );

  yield fork(
    takeFlowStep,
    PORTFOLIO_EDITOR_STEPS.SOURCE_PIE,
    finishedSourcePie,
  );

  yield fork(
    takeFlowStep,
    PORTFOLIO_EDITOR_STEPS.DESTINATION_PIE,
    finishedDestinationPie,
  );

  yield fork(
    takeFlowStep,
    PORTFOLIO_EDITOR_STEPS.CONFIRMATION_SCREEN,
    finishedConfirmationScreen,
    onFinish,
  );
}

// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
function* handleBeginPortfolioEditorFlow(action): SagaIterator<void> {
  const { portfolioSliceableId } = action.payload;

  if (action.meta.flow === 'PORTFOLIO_EDITOR' && portfolioSliceableId) {
    yield put(
      fetchPieDataForPortfolioEditor({
        pieId: portfolioSliceableId,
        type: 'SOURCE_PIE',
      }),
    );
  }
}

type BasePortfolioEditorAction = {
  payload?: {
    goBack?: boolean;
  };
  type: AppAction;
};

function* finishedEditPortfolio(
  action: BasePortfolioEditorAction,
): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  if (action.payload?.goBack) {
    const sourcePiePortfolioSliceId = yield select<string>(
      (state) => state.newFlows.PORTFOLIO_EDITOR.sourcePiePortfolioSliceId,
    );

    const route: Navigate = sourcePiePortfolioSliceId
      ? {
          to: `/d/invest/portfolio/:portfolioSliceId`,
          params: { portfolioSliceId: sourcePiePortfolioSliceId },
        }
      : { to: '/d/invest' };

    yield call(navigate, route);
  }
}

type DestinationPieNavBackSelector = {
  activePie: Pie | null | undefined;
  history: Array<Pie>;
};

function* finishedDestinationPie(
  action: BasePortfolioEditorAction,
): SagaIterator<void> {
  if (action.payload?.goBack) {
    const { activePie, history: currentHistory } =
      yield select<DestinationPieNavBackSelector>((state) => {
        const { history } = state.newFlows.PORTFOLIO_EDITOR;
        const activePie = getActivePie(state.newFlows.PORTFOLIO_EDITOR);

        return {
          activePie,
          history,
        };
      });

    const history = [...currentHistory];
    const previous: Pie | null | undefined = history.pop();

    if (previous && activePie) {
      // Revert the sourcePie back to previous state
      const sourcePie = cloneDeep(previous);

      yield put({
        payload: {
          history,
          sourcePie,
        },
        type: 'GO_BACK_FROM_SET_DESTINATION_TARGETS',
      });
    }

    yield call(changeStep, PORTFOLIO_EDITOR_STEPS.EDIT_PORTFOLIO);
  } else {
    yield call(changeStep, PORTFOLIO_EDITOR_STEPS.SOURCE_PIE);
  }
}

function* finishedSourcePie(
  action: BasePortfolioEditorAction,
): SagaIterator<void> {
  if (action.payload?.goBack) {
    const history = yield select<Array<Pie>>((state) => {
      const { history } = state.newFlows.PORTFOLIO_EDITOR;

      return history ? [...history] : [];
    });

    const previous: Pie | null | undefined = history.pop();
    const lastSourcePieState = history[history.length - 1];

    if (previous && lastSourcePieState) {
      const slices = lastSourcePieState.slices || [];

      yield put({
        payload: {
          history,
          activePieId: previous.__id,
          sourcePie: {
            ...lastSourcePieState,
            // @ts-expect-error - TS7006 - Parameter 'slice' implicitly has an 'any' type.
            slices: slices.filter((slice) => !slice.to.__checked),
          },
        },
        type: 'GO_BACK_FROM_SET_SOURCE_PIE',
      });
    }

    yield call(changeStep, PORTFOLIO_EDITOR_STEPS.DESTINATION_PIE);
  }
}

function* finishedConfirmationScreen(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  yield call(onFinish);
}

function* clickedMoveSlices(
  action: ClickedMoveSlicesAction,
): SagaIterator<void> {
  const { destinationSliceableId, slicesToMove: portfolioSlicesToMove } =
    action.payload;

  const basePath = yield select<string>(
    (state) => state.newFlows.PORTFOLIO_EDITOR.basePath,
  );

  // Need to set the sagas context to include the basePath
  // otherwise changing step won't work due to this saga handler
  // not being a part of the flow saga
  yield setContext({
    basePath,
  });
  yield call(changeStep, PORTFOLIO_EDITOR_STEPS.DESTINATION_PIE);

  // Grab the slices off of our portfolioSlicesToMove
  const slicesToMove = portfolioSlicesToMove.map(
    (portfolioSlice) => portfolioSlice.to,
  );

  // Remove the slices being moved from the source pie
  yield put({
    payload: slicesToMove,
    type: 'REMOVE_PORTFOLIO_EDITOR_SLICES',
  });

  // Get the destination pie data and add the slices to move to it
  yield put(
    fetchPieDataForPortfolioEditor({
      pieId: destinationSliceableId,
      slicesToMove,
      type: 'DESTINATION_PIE',
    }),
  );
}

function* handleCreatedNewPieSlice(): SagaIterator<void> {
  yield put({
    payload: {
      content: 'Pie details created.',
      duration: 'short',
      kind: 'success',
    } satisfies ToastProps,
    type: 'ADD_TOAST',
  });
}

function* handleSavePortfolioEditor(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const pieTree: Pie = yield select(
    (state) => state.newFlows.PORTFOLIO_EDITOR.sourcePie,
  );

  yield put(showLoadingSpinner());

  try {
    const serializedTree = JSON.stringify(preparePieTreeForUpdate(pieTree));
    const updatePieTreeResult: UpdatePieTreeMutationResult = yield call(
      updatePieTreeSaga,
      serializedTree,
    );

    if (updatePieTreeResult.data?.updatePieTree?.result.didSucceed) {
      yield call(navigate, { to: '/d/invest' });
    }
  } catch (e: any) {
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

type AddSlicesStateData = {
  isCrypto: boolean;
  path: Array<string>;
  pieTree: Pie | null | undefined;
};

function* handleGetSliceablesForEditor(
  action: AddSlicesAction,
): SagaIterator<void> {
  const sliceableIds = action.payload;
  const { pieTree, path, isCrypto } = yield select<AddSlicesStateData>(
    (state) => {
      return {
        isCrypto: state.global.activeAccountIsCrypto,
        path: state.newFlows.PORTFOLIO_EDITOR.path,
        pieTree: getActivePie(state.newFlows.PORTFOLIO_EDITOR),
      };
    },
  );

  if (!pieTree) {
    // something went wrong and we can't access the pieTree
    yield put(fetchedPieSliceablesForPortfolioEditor([]));
  } else {
    const currentPie = readPieTreeByPath(pieTree, path);
    const currentPieSliceIds =
      currentPie.slices?.map((slice) => slice.to.__id) || [];

    const hasDuplicateSlices = currentPieSliceIds.some((id) =>
      sliceableIds.includes(id),
    );

    const pieIsCircular = hasCircularReference(sliceableIds, pieTree, path);

    // if we're trying to add a duplicate slice or adding a slice
    // would cause a circular pie to be formed, fail fast
    if (hasDuplicateSlices) {
      yield put({
        payload: {
          content: 'Some of your chosen slices already exist in this Pie.',
          kind: 'alert',
          duration: 'short',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });

      yield put(fetchedPieSliceablesForPortfolioEditor([]));
    } else if (pieIsCircular) {
      yield put({
        payload: {
          content: 'This Pie is already in the Pie you are editing.',
          kind: 'alert',
          duration: 'short',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });

      yield put(fetchedPieSliceablesForPortfolioEditor([]));
    } else {
      const response:
        | PortfolioEditorSagaFetchSliceablesQuery
        | null
        | undefined = yield call(fetchSliceables, sliceableIds);

      const mappedSliceables = mapFetchSliceablesResponseToSlices(response);

      yield put(fetchedPieSliceablesForPortfolioEditor(mappedSliceables));

      const successMessage = isCrypto
        ? 'Coin(s) successfully added to your Pie.'
        : 'Securities added.';

      yield put({
        payload: {
          content: successMessage,
          kind: 'success',
          duration: 'short',
        } satisfies ToastProps,
        type: 'ADD_TOAST',
      });
    }
  }
}

function* handleGetPieDataForEditor(
  action: FetchPieDataAction,
): SagaIterator<void> {
  const { pieId, slicesToMove, type } = action.payload;

  const data: PortfolioEditorSagaFetchSliceableQuery | null | undefined =
    yield call(fetchSliceable, pieId);

  const pie = data?.node;
  if (pie && 'id' in pie && 'name' in pie && 'type' in pie) {
    const mappedPie = mapRemoteSliceableByType({
      ...pie,
      id: pie.id,
      name: pie.name,
      type: pie.type,
    });

    // We only support retrieving existing pies for this saga
    if (mappedPie.type === 'old_pie') {
      const slicesBeingMoved = slicesToMove || [];
      const existingSlices = sortSlicesByPercentage(
        mappedPie.slices || [],
        'DESC',
      );

      const newSlices = mergeSlicesForMove(existingSlices, slicesBeingMoved);
      mappedPie.slices = newSlices;

      yield put(
        fetchedPieDataForPortfolioEditor({
          pie: mappedPie,
          type,
        }),
      );
    }
  } else {
    const errorMessage =
      'No data found for pie, please try again or contact support.';

    yield put({
      payload: {
        content: errorMessage,
        duration: 'short',
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    });
  }
}

function* fetchSliceable(
  sliceableId: string,
): SagaIterator<PortfolioEditorSagaFetchSliceableQuery | null | undefined> {
  const sentry: SentryReporter = yield call(getSentryReporter);
  const accountId: string | null | undefined = yield select<
    string | null | undefined
  >((state) => state.global.activeAccountId);

  yield put(showLoadingSpinner());

  try {
    const { data }: PortfolioEditorSagaFetchSliceableQueryResult = yield call(
      apolloQuerySaga,
      {
        query: PortfolioEditorSagaFetchSliceableDocument,
        variables: {
          accountId,
          sliceableId,
        },
      },
    );

    return data;
  } catch (e: any) {
    sentry.message('Error fetching PortfolioEditorSagaFetchSliceableQuery.', {
      rawError: e,
    });
    return null;
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* fetchSliceables(
  sliceableIds: Array<string>,
): SagaIterator<PortfolioEditorSagaFetchSliceablesQuery | null | undefined> {
  const accountId: string | null | undefined = yield select(
    (state) => state.global.activeAccountId,
  );

  yield put(showLoadingSpinner());

  try {
    const { data }: PortfolioEditorSagaFetchSliceablesQueryResult = yield call(
      apolloQuerySaga,
      {
        query: PortfolioEditorSagaFetchSliceablesDocument,
        variables: {
          accountId,
          sliceableIds,
        },
      },
    );
    return data;
  } catch (e: any) {
    // do something
  } finally {
    yield put(hideLoadingSpinner());
  }

  return null;
}
