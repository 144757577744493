import { Box, PL } from '@m1/liquid-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledCheckbox } from '~/components/form/ControlledCheckbox';
import { ControlledInput } from '~/components/form/ControlledInput';
import { printableAsciiChars } from '~/forms/validators';

import { Checkbox } from '../../../toolbox/checkbox/Checkbox';

import {
  financialDisclosuresFormDefaultValues,
  FinancialDisclosuresFormValues,
} from './FinancialDisclosuresForm.constants';

export const FinancialDisclosuresForm = () => {
  const { control, setValue, watch, clearErrors, reset } =
    useFormContext<FinancialDisclosuresFormValues>();
  const values = watch();

  const {
    exchangeOrFinraAffiliationDisclosure,
    controlPersonDisclosure,
    politicalExposureDisclosure,
    isSubjectToBackupWithholding,
  } = values;

  const isAffiliated =
    exchangeOrFinraAffiliationDisclosure.isAffiliated ?? false;
  const isControlPerson = controlPersonDisclosure?.isControlPerson ?? false;
  const isPoliticallyExposed =
    politicalExposureDisclosure?.isPoliticallyExposed ?? false;

  const hasNoDisclosures =
    Boolean(
      isAffiliated ||
        isControlPerson ||
        isPoliticallyExposed ||
        isSubjectToBackupWithholding,
    ) === false;
  const uncheckAllDisclosures = () => {
    // exchangeOrFinraAffiliationDisclosure
    setValue('exchangeOrFinraAffiliationDisclosure.isAffiliated', false);
    setValue('exchangeOrFinraAffiliationDisclosure.firmName', null);

    // controlPersonDisclosure
    setValue('controlPersonDisclosure.isControlPerson', false);
    setValue('controlPersonDisclosure.companySymbols', null);

    // politicalExposureDisclosure
    setValue('politicalExposureDisclosure.isPoliticallyExposed', false);
    setValue('politicalExposureDisclosure.politicalOrganization', null);
    setValue('politicalExposureDisclosure.immediateFamilyMembers', null);

    // isSubjectToBackupWithholding
    setValue('isSubjectToBackupWithholding', false);
  };

  React.useEffect(() => {
    // Clear out fields tied to any disclosures if it's unchecked
    if (!isAffiliated) {
      setValue('exchangeOrFinraAffiliationDisclosure.firmName', null, {
        shouldValidate: true,
      });
      clearErrors('exchangeOrFinraAffiliationDisclosure.firmName');
    }
    if (!isControlPerson) {
      setValue('controlPersonDisclosure.companySymbols', null, {
        shouldValidate: true,
      });
      clearErrors('controlPersonDisclosure.companySymbols');
    }
    if (!isPoliticallyExposed) {
      setValue('politicalExposureDisclosure.politicalOrganization', null, {
        shouldValidate: true,
      });
      setValue('politicalExposureDisclosure.immediateFamilyMembers', null, {
        shouldValidate: true,
      });
      clearErrors([
        'politicalExposureDisclosure.immediateFamilyMembers',
        'politicalExposureDisclosure.politicalOrganization',
      ]);
    }
    if (
      !(isSubjectToBackupWithholding ?? false) &&
      !isAffiliated &&
      !isControlPerson &&
      !isPoliticallyExposed
    ) {
      reset(financialDisclosuresFormDefaultValues);
    }
  }, [
    isAffiliated,
    isControlPerson,
    isPoliticallyExposed,
    isSubjectToBackupWithholding,
    clearErrors,
    reset,
    setValue,
  ]);
  return (
    <>
      <Box>
        <ControlledCheckbox
          name="exchangeOrFinraAffiliationDisclosure.isAffiliated"
          label="I am affiliated with or work for a broker/dealer"
          control={control}
        />
        {isAffiliated && (
          <ControlledInput
            name="exchangeOrFinraAffiliationDisclosure.firmName"
            label="Firm Name"
            placeholder="M1 Finance, etc."
            control={control}
            rules={{
              required: 'Required',
              validate: {
                printableAsciiChars: (value) =>
                  typeof value === 'string' && printableAsciiChars(value),
              },
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
        )}
      </Box>
      <Box>
        <ControlledCheckbox
          name="controlPersonDisclosure.isControlPerson"
          label="I am a 10% shareholder or director of a publicly traded company."
          control={control}
        />
        {isControlPerson && (
          <ControlledInput
            name="controlPersonDisclosure.companySymbols"
            label="Symbols"
            placeholder="GOOG, AMZN, META"
            control={control}
            valueIsArray
            rules={{
              required: 'Required',
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
        )}
      </Box>
      <Box>
        <ControlledCheckbox
          name="politicalExposureDisclosure.isPoliticallyExposed"
          label="I am or an immediate family member is a current or former Public Official."
          control={control}
        />
        {isPoliticallyExposed && (
          <>
            <ControlledInput
              name="politicalExposureDisclosure.politicalOrganization"
              label="Affiliated public entities"
              placeholder="e.g. Director of the Port Authority"
              control={control}
              rules={{
                required: 'Required',
              }}
            />
            <ControlledInput
              name="politicalExposureDisclosure.immediateFamilyMembers"
              label="Names of immediate family members"
              placeholder="e.g. George Washington, President of USA"
              valueIsArray
              control={control}
              rules={{
                required: true,
              }}
            />
          </>
        )}
      </Box>
      <Box>
        <ControlledCheckbox
          name="isSubjectToBackupWithholding"
          label="I am subject to backup withholding by the IRS."
          my={8}
          control={control}
        />
      </Box>
      <Box>
        <Checkbox
          checked={hasNoDisclosures}
          label={<PL fontWeight={600}>None of these apply to me.</PL>}
          onChange={() => !hasNoDisclosures && uncheckAllDisclosures()}
        />
      </Box>
    </>
  );
};
