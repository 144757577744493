import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import {
  CreateProfileDocument,
  CreateProfileMutationResult,
} from '~/graphql/hooks';

import {
  CreateProfileError,
  CreateProfileMutationVariables,
  OnboardingIdentityFirstQuestion,
  OnboardingIdentityFirstQuestionType,
} from '~/graphql/types';
import { Navigate, NavigateFunction } from '~/hooks/useNavigate';
import {
  hideLoadingSpinner,
  showLoadingSpinner,
  setProfileCreated,
  setUserHasOnboarded,
} from '~/redux/actions';
import { AppState } from '~/redux/reducers';
import { apolloMutationSaga } from '~/redux/sagas/apolloMutationSaga';
import { IDENTITY_FIRST_FLOW_STEPS as STEPS } from '~/static-constants';
import { ToastProps } from '~/toolbox/toast';
import { deepLinkPaths } from '~/utils';

import { showFailureToast } from '../../../../showFailureToastSaga';
import { changeStep } from '../../../utils';
import { primaryQuestionMap, secondaryQuestionMap } from '../mappings';
import { queryViewerProfile } from '../remote';

export const getInitialUserProfileQuestion = (
  allQuestions: Array<OnboardingIdentityFirstQuestion>,
): OnboardingIdentityFirstQuestionType | null =>
  allQuestions?.filter((question) => !question.previousQuestionType)?.[0]
    ?.questionType || null;

function* handleUserProfileQuestionNavigation(
  questionType: OnboardingIdentityFirstQuestionType,
  isPrimary: boolean,
) {
  if (isPrimary) {
    yield call(changeStep, primaryQuestionMap(questionType));
  } else {
    yield call(changeStep, secondaryQuestionMap(questionType));
  }
}

export function* finishedUserProfileQuestion(
  action: any,
  nextQuestion: OnboardingIdentityFirstQuestionType | null | undefined,
): SagaIterator<void> {
  const isPrimary = action.payload.holder === 'primary';

  if (!nextQuestion) {
    yield call(finishUserProfileSection, isPrimary);
  } else {
    yield call(handleUserProfileQuestionNavigation, nextQuestion, isPrimary);
  }
}

export function* finishedUserProfileModuleOne() {
  yield call(changeStep, STEPS.CONFIRM_PROFILE);
}

function* finishUserProfileSection(
  isPrimaryAccount: boolean,
): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const profile = yield call(queryViewerProfile);

  if (!profile) {
    try {
      yield put(showLoadingSpinner());

      const result: CreateProfileMutationResult['data'] =
        yield call(createUserProfile);

      if (result?.createProfile?.didSucceed) {
        const landingUrl = yield select((state) => state.config.landingUrl);
        yield put(setProfileCreated(true));
        yield put(setUserHasOnboarded(true));

        // Only redirect to deep links from signup if they are a valid deep link
        const finalRoute: Navigate =
          landingUrl in deepLinkPaths ? { to: landingUrl } : { to: '/d/home' };
        yield call(navigate, finalRoute);
        return;
      }
      yield call(showFailureToast);
    } catch (e: any) {
      const errorCode = e?.graphQLErrors?.[0]?.extensions?.code;
      if (errorCode === CreateProfileError.DuplicateProfile) {
        yield put({
          payload: {
            content: e.message,
            kind: 'alert',
            link: {
              children: 'Log out',
              to: '/logout',
            },
          } satisfies ToastProps,
          type: 'ADD_TOAST',
        });
      } else {
        yield call(showFailureToast);
      }
      return;
    } finally {
      yield put(hideLoadingSpinner());
    }
  }
  if (isPrimaryAccount) {
    yield call(changeStep, STEPS.COLLECT_PRIMARY_DISCLOSURES);
  } else {
    yield call(changeStep, STEPS.COLLECT_SECONDARY_DISCLOSURES);
  }
}

export function* createUserProfile(): SagaIterator<any | null> {
  const {
    address,
    countryOfCitizenship,
    dateOfBirth,
    name,
    phoneNumber,
    ssn,
    productSelection,
    suitability,
  } = yield select((state: AppState) => {
    const { primary, primarySsn, suitability } =
      state.newFlows.IDENTITY_FIRST.input;
    const { productIdentifier } = state.newFlows.onboarding.product;
    const {
      countryOfCitizenship,
      dateOfBirth,
      firstName,
      homeAddress,
      lastName,
      middleInitial,
      phoneNumber,
      suffix,
    } = primary;

    return {
      address: {
        city: homeAddress.city,
        country: 'USA', // We only support US mailing addresses
        lineOne: homeAddress.lineOne,
        lineTwo: homeAddress.lineTwo,
        stateOrProvince: homeAddress.stateOrProvince,
        postalCode: homeAddress.postalCode,
      },
      countryOfCitizenship,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
      name: {
        firstName,
        middleInitial,
        lastName,
        suffix,
      },
      phoneNumber,
      ssn: primarySsn,
      productSelection: productIdentifier,
      suitability: suitability
        ? {
            annualIncomeAmount: suitability?.annualIncomeAmount,
            liquidNetWorth: suitability?.liquidNetWorth,
          }
        : null,
    };
  });

  const result: CreateProfileMutationResult = yield call(apolloMutationSaga, {
    mutation: CreateProfileDocument,
    variables: {
      input: {
        phoneNumber,
        name,
        countryOfCitizenship,
        address,
        dateOfBirth,
        ssn,
        productSelection,
        suitability,
      },
    } satisfies CreateProfileMutationVariables,
  });

  return result.data;
}
