import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { CreatePaymentFlow } from '~/flows';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';

export const CreatePaymentPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const modes = Object.values(MODES);
  const queryMode = searchParams.get('mode');
  const mode =
    queryMode && modes.includes(queryMode as (typeof modes)[number])
      ? queryMode
      : MODES.ONE_TIME;

  const fallbackRoute =
    mode === MODES.SCHEDULE ? '/d/transfers/rules' : '/d/transfers';

  const previousRouteName =
    searchParams.get('previousRouteName') || fallbackRoute;

  const handleFinish = (): void =>
    navigate({
      to: previousRouteName,
      options: { state: { forceRefetch: true } },
    });

  return (
    <Box width={490} m="0 auto" p="100px 0">
      <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
        <CreatePaymentFlow
          basePath="d/c/create-transfer"
          mode={mode}
          onFinish={handleFinish}
        />
      </PageErrorBoundary>
    </Box>
  );
};
