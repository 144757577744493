import * as React from 'react';

import { SecurityTypeEnum } from '~/graphql/types';

import { useScreenerState } from '../../useScreenerState';

import {
  defaultSecurityLimits,
  INITIAL_EQUITY_SCREENER_VARIABLES,
} from './constants';

import { SecurityScreenerQueryResultContextValue } from './types';

export const SecurityScreenerQueryResultContext = React.createContext(
  {} as SecurityScreenerQueryResultContextValue,
);

type StockScreenerProviderProps = {
  children: React.ReactNode;
};

export const StockScreenerProvider = ({
  children,
}: StockScreenerProviderProps) => {
  const screenerState = useScreenerState({
    screenerType: SecurityTypeEnum.Equity,
    defaultVariables: INITIAL_EQUITY_SCREENER_VARIABLES,
    defaultLimits: defaultSecurityLimits,
  });

  return (
    <SecurityScreenerQueryResultContext.Provider value={screenerState}>
      {children}
    </SecurityScreenerQueryResultContext.Provider>
  );
};
