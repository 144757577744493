import * as React from 'react';

import { SecurityScreenerQueryResultContext } from './StockScreenerProvider';

export const useStockScreenerResult = () => {
  const queryResult = React.useContext(SecurityScreenerQueryResultContext);
  if (!queryResult) {
    throw new Error(
      'useStockScreenerResult must be used within a SecurityScreenerProvider',
    );
  }
  return queryResult;
};
