import { createStepFade } from '~/flows/components/step-fade-transition';
import { CREATE_PAYMENT_FLOW_STEPS as STEPS } from '~/static-constants';

import { makeFlowComponent } from '../../../../flows/components/utils';

import { ConfirmAutoPayStep } from './ConfirmAutopayStep';
import { ConfirmPaymentStep } from './ConfirmPaymentStep';
import { SetupPaymentStep } from './SetupPaymentStep';
import { ShowPaymentReceiptStep } from './ShowPaymentReceiptStep';

export const CreatePaymentFlow = makeFlowComponent({
  name: 'CREATE_PAYMENT',
  Component: createStepFade({
    [STEPS.SETUP_PAYMENT]: SetupPaymentStep,
    [STEPS.CONFIRM_PAYMENT]: ConfirmPaymentStep,
    [STEPS.CONFIRM_AUTOPAY_CREATION]: ConfirmAutoPayStep,
    [STEPS.SHOW_RECEIPT]: ShowPaymentReceiptStep,
  }),
});
