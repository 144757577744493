import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';

import { ToastProps } from '~/toolbox/toast';

export function* showFailureToast(content: string): SagaIterator<void> {
  yield put({
    payload: {
      content,
      kind: 'alert',
    } satisfies ToastProps,
    type: 'ADD_TOAST',
  });
}

export function* showSuccessToast(content: string): SagaIterator<void> {
  yield put({
    payload: {
      content,
      kind: 'success',
    } satisfies ToastProps,
    type: 'ADD_TOAST',
  });
}
