import { ApolloError } from '@apollo/client';
import toNumber from 'lodash-es/toNumber';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { STEPS } from '~/flows/components/personal-loans/application';
import { finishedAutoPayEnrollment } from '~/flows/components/personal-loans/application/steps/AutoPayEnrollment';

import {
  AcceptPersonalLoanDocument,
  AcceptPersonalLoanMutationResult,
  AcceptPersonalLoanOfferDocument,
  AcceptPersonalLoanOfferMutationResult,
  CompletePlaidIncomeVerificationDocument,
  CompletePlaidIncomeVerificationMutationResult,
  QueueLoanDisbursementDocument,
  QueueLoanDisbursementMutationResult,
  ResubmitLoanAfterCreditFreezeDocument,
  ResubmitLoanAfterCreditFreezeMutationResult,
  SubmitPersonalLoanApplicationDocument,
  SubmitPersonalLoanApplicationMutationResult,
  WithdrawPersonalLoanApplicationDocument,
  WithdrawPersonalLoanApplicationMutationResult,
  WithdrawPersonalLoanDocument,
  WithdrawPersonalLoanMutationResult,
} from '~/graphql/hooks';

import {
  AcceptPersonalLoanInput,
  AcceptPersonalLoanOfferInput,
  CompletePlaidIncomeVerificationInput,
  LoanApplicationStatusEnum,
  PersonalLoanStatusEnum,
  QueueLoanDisbursementMutationVariables,
  ResubmitLoanAfterCreditFreezeInput,
  SubmitPersonalLoanApplicationInput,
  WithdrawPersonalLoanApplicationInput,
  WithdrawPersonalLoanInput,
} from '~/graphql/types';

import { NavigateFunction } from '~/hooks/useNavigate';
import { AppState } from '~/redux';
import {
  ACTION_TYPES as ACTIONS,
  CompletePlaidIncomeVerificationAction,
  hideLoadingSpinner,
  showLoadingSpinner,
  startFlow,
} from '~/redux/actions';

import { userHasIncompleteProfile, getLoggers } from '~/redux/sagas/common';
import { updateUserProfile } from '~/redux/sagas/common/updateUserProfile';
import { ToastProps } from '~/toolbox/toast';
import { delay } from '~/utils';

import { apolloMutationSaga } from '../../apolloMutationSaga';
import { changeStep, makeFlowFuncs, replaceRouterHistory } from '../utils';

import {
  ApplicationByIdStatusPolling,
  fetchPersonalLoansApplicationByIdInfo,
} from './personalLoansApplicationByIdQuery';
import {
  ApplicationInformation,
  fetchPersonalLoansApplicationInfo,
} from './personalLoansApplicationQuery';

const { takeFlow, takeFlowStep } = makeFlowFuncs('PERSONAL_LOANS_APPLICATION');

// Timeout for checking status of application & open account
const POLL_INTERVAL = 2000;
const POLL_DURATION = 60000;

const withdrawErrorToast = {
  payload: {
    content:
      'We couldn’t withdraw your application. Try again or contact Client Support.',
    duration: 'short',
    kind: 'alert',
  } satisfies ToastProps,
  type: 'ADD_TOAST',
};

const withdrawSuccessToast = {
  payload: {
    content: 'Your application has been withdrawn.',
    duration: 'short',
    kind: 'success',
  } satisfies ToastProps,
  type: 'ADD_TOAST',
};

export function* personalLoansApplicationSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginPersonalLoansApplicationFlow);
}

function* beginPersonalLoansApplicationFlow(action: any): SagaIterator<void> {
  const savedLoanIdFromApplication = yield select((state) => {
    return state.newFlows.PERSONAL_LOANS_APPLICATION.loanId;
  });

  const { step, onFinish } = action.payload;

  yield fork(takeFlowStep, STEPS.LOAN_INFORMATION, finishedLoanInformation);

  yield fork(
    takeFlowStep,
    STEPS.FINANCIAL_INFORMATION,
    finishedFinancialInformation,
  );

  yield fork(takeFlowStep, STEPS.FINANCIAL_REVIEW, finishedFinancialReview);

  yield fork(
    takeFlowStep,
    STEPS.LOAN_OFFERS_AND_SUBMIT,
    finishedSelectOfferSubmission,
  );

  yield fork(
    takeFlowStep,
    STEPS.LOAN_TERMS_AND_ACCEPT,
    finishedLoanTermsAndAccept,
  );
  yield fork(takeFlowStep, STEPS.DEPOSIT_INFO, finishedDepositInfo);
  yield fork(takeFlowStep, STEPS.PROMPT_FOR_BANK, finishedPromptForBank);
  yield fork(takeFlowStep, STEPS.AUTOPAY_ENROLLMENT, finishedAutoPayEnrollment);
  yield fork(takeFlowStep, STEPS.LOAN_RECAP, finishedLoanRecap, onFinish);
  yield fork(
    takeFlowStep,
    STEPS.APPLICATION_REJECTED,
    finishedApplicationRejected,
  );
  yield fork(takeFlowStep, STEPS.MANUAL_REVIEW, finishedManualReview);
  yield fork(
    takeFlowStep,
    STEPS.REMOVE_CREDIT_FREEZE,
    finishedRemovingCreditFreeze,
  );

  yield fork(takeFlowStep, STEPS.NO_LINKED_ACCOUNT, finishedNoLinkedAccount);
  yield fork(
    takeFlowStep,
    STEPS.INCOME_VERIFICATION,
    initiatePlaidIncomeVerification,
  );

  yield put(showLoadingSpinner());
  const {
    status,
    needsDisbursement,
    hasActivePersonalLoan,
    applicationId,
    loanWithdrawn,
    hasFailedPersonalLoanDisbursement,
    hasPendingDisbursement,
    loanId,
    hasDisbursementOption,
    isEligibleToApplyForPersonalLoan,
  }: ApplicationInformation = yield call(
    fetchPersonalLoansApplicationInfo,
    savedLoanIdFromApplication,
  );

  // CXIO check for if user has the integrated onboarding feature flag and has an incomplete profile (Module 1 & Module 2)
  const hasIncompleteProfile = yield call(userHasIncompleteProfile);
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield put(hideLoadingSpinner());

  if (loanWithdrawn) {
    yield call(changeStep, STEPS.LANDING_PAGE);
    return;
  }

  if (loanId) {
    yield put({
      type: ACTIONS.PERSONAL_LOANS_STORE_LOAN_ID,
      payload: {
        loanId,
      },
    });
  }

  if (applicationId) {
    yield put({
      type: ACTIONS.PERSONAL_LOANS_STORE_APPLICATION_ID,
      payload: {
        applicationId,
      },
    });
  }

  if (!isEligibleToApplyForPersonalLoan) {
    yield call(changeStep, STEPS.NOT_ELIGIBLE);
    return;
  } else if (hasFailedPersonalLoanDisbursement) {
    yield call(changeStep, STEPS.DISBURSEMENT_ERROR);
  } else if (hasActivePersonalLoan || hasPendingDisbursement) {
    yield call(navigate, { to: '/d/borrow/personal/transactions' });
    return;
  } else if (needsDisbursement) {
    if (hasDisbursementOption) {
      yield call(changeStep, STEPS.DEPOSIT_INFO, true);
    } else {
      yield call(changeStep, STEPS.PROMPT_FOR_BANK, true);
    }
  } else if (step === 'no-linked-account') {
    yield call(changeStep, STEPS.NO_LINKED_ACCOUNT, true);
  } else if (step === 'not-right-now') {
    yield call(changeStep, STEPS.APPLICATION_REJECTED, true);
  } else if (hasIncompleteProfile) {
    // CX IO flow: Navigate user to financial suitability (Module 2) if they have not completed it yet
    yield put(startFlow('FINANCIAL_SUITABILITY'));
    yield call(navigate, {
      to: '/onboarding/financial-details/disclosures',
      query: { product: 'personal_loans', previousRouteName: '/d/borrow' },
    });
    return;
  } else {
    switch (status) {
      case 'EXPIRED':
        yield call(changeStep, STEPS.LANDING_PAGE);
        break;
      case 'REJECTED_CREDIT_FROZEN':
        yield call(changeStep, STEPS.REMOVE_CREDIT_FREEZE, true);
        break;
      case 'REJECTED':
        yield call(changeStep, STEPS.APPLICATION_REJECTED, true);
        break;
      case 'OFFERS_PROVIDED':
        yield call(changeStep, STEPS.LOAN_OFFERS_AND_SUBMIT, true);
        break;
      case 'APPROVED':
        yield call(changeStep, STEPS.LOAN_TERMS_AND_ACCEPT, true);
        break;
      case 'INCOME_VERIFICATION_REQUIRED':
        yield call(changeStep, STEPS.INCOME_VERIFICATION, true);
        break;
      case 'MANUAL_REVIEW_REQUIRED':
        yield call(changeStep, STEPS.MANUAL_REVIEW, true);
        break;
      case 'QUEUED':
      case 'OFFER_SELECTION_QUEUED':
      case 'ERROR_SUBMITTING_APPLICATION':
      case 'ERROR_ACCEPTING_OFFER':
        yield call(changeStep, STEPS.APPLICATION_RECEIVED, true);
        break;
      default:
        // If the user clicks on the browser's back button, they will be redirected to the emptystate marketing page, not a blank screen
        yield call(replaceRouterHistory, '/d/borrow/marketing');
        yield call(changeStep, STEPS.LANDING_PAGE);
        break;
    }
  }

  yield takeEvery(
    ACTIONS.COMPLETE_PLAID_INCOME_VERIFICATION,
    handleCompletePlaidIncomeVerification,
  );

  // Make this check last as all other checks should be completed before moving forward to Landing Page
  yield fork(takeFlowStep, STEPS.LANDING_PAGE, finishedLandingPage);
}

function* finishedLandingPage(): SagaIterator<void> {
  yield call(changeStep, STEPS.LOAN_INFORMATION);
}

function* finishedLoanInformation(): SagaIterator<void> {
  yield call(changeStep, STEPS.FINANCIAL_INFORMATION);
}

function* finishedFinancialInformation(): SagaIterator<void> {
  const { annualIncome } = yield select(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  // if the users annual income was updated,
  // sync it with their user profile
  if (annualIncome !== null) {
    try {
      yield put(showLoadingSpinner());

      yield call(
        updateUserProfile,
        {
          suitability: {
            annualIncomeAmount: toNumber(annualIncome),
          },
        },
        true,
        null,
      );
    } catch (e) {
      // do nothing
    } finally {
      yield put(hideLoadingSpinner());
    }
  }

  yield call(changeStep, STEPS.FINANCIAL_REVIEW);
}
function* initiatePlaidIncomeVerification({
  payload,
}: Record<string, any>): SagaIterator<void> {
  if (payload.withdrawingApplication) {
    const { applicationId } = yield select(
      (state: AppState) => state.newFlows.PERSONAL_LOANS_APPLICATION,
    );
    yield call(
      handleWithdrawPersonalLoanApplication,
      applicationId,
      STEPS.INCOME_VERIFICATION,
    );
  } else {
    yield call(changeStep, STEPS.PLAID_VERIFICATION_FLOW);
  }
}
function* handleCompletePlaidIncomeVerification(
  action: CompletePlaidIncomeVerificationAction,
): SagaIterator<void> {
  const { onExit, onSuccess } = action.payload;

  if (onExit) {
    yield call(changeStep, STEPS.INCOME_VERIFICATION);
    return;
  } else if (onSuccess) {
    const { applicationId } = yield select(
      (state: AppState) => state.newFlows.PERSONAL_LOANS_APPLICATION,
    );

    yield call(changeStep, STEPS.PROCESSING_OFFER_SUBMISSION);

    try {
      const { data }: CompletePlaidIncomeVerificationMutationResult =
        yield call(apolloMutationSaga, {
          mutation: CompletePlaidIncomeVerificationDocument,
          variables: {
            input: {} satisfies CompletePlaidIncomeVerificationInput,
          },
        });
      if (
        !data?.completePlaidIncomeVerification ||
        !data.completePlaidIncomeVerification.didSucceed
      ) {
        yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
        return;
      }
      // TODO Add Analytics
      const applicationStatus = yield call(
        handlePollingLogic,
        pollingApplicationStatus,
        applicationId,
        {
          statusesIgnore: [
            'OFFER_ACCEPTED',
            'OFFER_SELECTION_QUEUED',
            'INCOME_VERIFICATION_REQUIRED',
          ] as any,
        },
      );

      if (!applicationStatus) {
        yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
        return;
      }

      if (applicationStatus === 'MANUAL_REVIEW_REQUIRED') {
        yield call(changeStep, STEPS.MANUAL_REVIEW);
        return;
      }

      if (applicationStatus === 'APPROVED') {
        const loanStatus = yield call(
          handlePollingLogic,
          pollingLoanStatus,
          applicationId,
          { statusesMatch: ['CREATED'] as any },
        );

        if (loanStatus === 'CREATED') {
          yield call(changeStep, STEPS.LOAN_TERMS_AND_ACCEPT);
        } else {
          yield call(changeStep, STEPS.APPLICATION_RECEIVED);
        }
      } else {
        yield call(handleApplicationErrorStates, applicationStatus);
      }
    } catch (e: any) {
      const errorMessage = (e as ApolloError).graphQLErrors.find(
        (err) => err.extensions.code !== null,
      );
      yield call(handleApplicationErrorStates, errorMessage?.extensions.code);
    }
  } else {
    yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
  }
}

function* finishedFinancialReview(action: any): SagaIterator<void> {
  if (action.payload === 'edit') {
    yield call(changeStep, STEPS.LOAN_INFORMATION);
  } else if (action.payload?.goBack) {
    yield call(changeStep, STEPS.FINANCIAL_INFORMATION);
  } else {
    yield call(changeStep, STEPS.PROCESSING_APPLICATION);

    const toastObj = {
      payload: {
        content:
          'Something went wrong. Try again or contact us for further assistance.',
        duration: 'short',
        kind: 'alert',
      } satisfies ToastProps,
      type: 'ADD_TOAST',
    };

    const inputValues: SubmitPersonalLoanApplicationInput = yield select(
      (state) => {
        const {
          annualIncome,
          monthlyHousingCosts,
          employmentStatus,
          loanAmount,
          loanPurpose,
          loanTerm,
          termsAndConditionsSignature,
        } = state.newFlows.PERSONAL_LOANS_APPLICATION;

        return {
          amount: loanAmount,
          requestedTerm: loanTerm,
          employmentStatus,
          annualIncome: annualIncome ? toNumber(annualIncome) : null,
          yearlyHousingCost: toNumber(monthlyHousingCosts),
          purpose: loanPurpose,
          termsAndConditionsSignature,
        } as SubmitPersonalLoanApplicationInput;
      },
    );

    try {
      // perform mutation with input values
      const { data }: SubmitPersonalLoanApplicationMutationResult = yield call(
        apolloMutationSaga,
        {
          mutation: SubmitPersonalLoanApplicationDocument,
          variables: { input: inputValues },
        },
      );
      const applicationId =
        data?.submitPersonalLoanApplication.outcome?.applicationId;

      if (applicationId) {
        yield put({
          type: ACTIONS.PERSONAL_LOANS_STORE_APPLICATION_ID,
          payload: {
            applicationId,
          },
        });
        yield put({
          type: 'SET_USER_HAS_ONBOARDED',
          payload: true,
        });
      }

      const applicationStatus = yield call(
        handlePollingLogic,
        pollingApplicationStatus,
        applicationId,
        { statusesIgnore: ['SUBMITTED', 'QUEUED'] as any },
      );

      if (!applicationStatus) {
        yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
        return;
      }

      yield call(replaceRouterHistory, '/d/borrow');

      switch (applicationStatus) {
        case 'REJECTED':
          yield call(changeStep, STEPS.APPLICATION_REJECTED);
          break;
        case 'REJECTED_CREDIT_FROZEN':
          yield call(changeStep, STEPS.REMOVE_CREDIT_FREEZE);
          break;
        case 'OFFERS_PROVIDED':
          yield call(
            handleOffersProvided,
            data?.submitPersonalLoanApplication.outcome?.applicationId,
          );
          break;
        default:
          yield call(handleApplicationErrorStates, applicationStatus);
      }
    } catch (e: any) {
      yield put(toastObj);
      const errorMessage = (e as ApolloError).graphQLErrors.find(
        (err) => err.extensions.code !== null,
      );
      yield call(handleApplicationErrorStates, errorMessage?.extensions.code);
    }
  }
}

function* finishedSelectOfferSubmission({
  payload,
}: Record<string, any>): SagaIterator<void> {
  if (payload) {
    const { withdrawingApplication, applicationId } = payload;

    if (withdrawingApplication) {
      yield call(
        handleWithdrawPersonalLoanApplication,
        applicationId,
        STEPS.LOAN_OFFERS_AND_SUBMIT,
      );
    } else {
      yield call(changeStep, STEPS.PROCESSING_OFFER_SUBMISSION);
      try {
        const { data }: AcceptPersonalLoanOfferMutationResult = yield call(
          apolloMutationSaga,
          {
            mutation: AcceptPersonalLoanOfferDocument,
            variables: {
              input: {
                offerId: payload.offerId,
              } satisfies AcceptPersonalLoanOfferInput,
            },
          },
        );

        if (
          !data?.acceptPersonalLoanOffer ||
          !data.acceptPersonalLoanOffer.outcome
        ) {
          yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
          return;
        }

        // TODO Add Analytics

        const applicationStatus = yield call(
          handlePollingLogic,
          pollingApplicationStatus,
          applicationId,
          {
            statusesIgnore: ['OFFER_ACCEPTED', 'OFFER_SELECTION_QUEUED'] as any,
          },
        );

        if (!applicationStatus) {
          yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
          return;
        }

        switch (applicationStatus) {
          case 'INCOME_VERIFICATION_REQUIRED':
            yield call(changeStep, STEPS.INCOME_VERIFICATION);
            break;
          case 'MANUAL_REVIEW_REQUIRED':
            yield call(changeStep, STEPS.MANUAL_REVIEW);
            break;
          case 'APPROVED': {
            const loanStatus = yield call(
              handlePollingLogic,
              pollingLoanStatus,
              applicationId,
              { statusesMatch: ['CREATED'] as any },
            );

            if (loanStatus === 'CREATED') {
              yield call(changeStep, STEPS.LOAN_TERMS_AND_ACCEPT);
            } else {
              yield call(changeStep, STEPS.APPLICATION_RECEIVED);
            }
            break;
          }
          default:
            yield call(handleApplicationErrorStates, applicationStatus || '');
        }
      } catch (e: any) {
        const errorMessage = (e as ApolloError).graphQLErrors.find(
          (err) => err.extensions.code !== null,
        );
        yield call(handleApplicationErrorStates, errorMessage?.extensions.code);
      }
    }
  }
}

function* finishedLoanTermsAndAccept({
  payload,
}: {
  payload?: {
    loanAcceptanceSignature: string;
    loanId: string;
    withdrawingApplication?: boolean;
  };
}): SagaIterator<void> {
  if (payload) {
    const { loanId, withdrawingApplication } = payload;
    if (withdrawingApplication) {
      yield call(
        handleWithdrawPersonalLoan,
        loanId,
        STEPS.LOAN_TERMS_AND_ACCEPT,
      );
    } else {
      try {
        yield put(showLoadingSpinner());

        const { data }: AcceptPersonalLoanMutationResult = yield call(
          apolloMutationSaga,
          {
            mutation: AcceptPersonalLoanDocument,
            variables: {
              input: {
                loanAcceptanceSignature: payload.loanAcceptanceSignature,
                loanId,
              } satisfies AcceptPersonalLoanInput,
            },
          },
        );

        if (
          data?.acceptPersonalLoan?.outcome?.loanDisbursement &&
          data.acceptPersonalLoan.didSucceed
        ) {
          if (
            data.acceptPersonalLoan.outcome.loanDisbursement.destinationList &&
            data.acceptPersonalLoan.outcome.loanDisbursement.destinationList
              .length === 0
          ) {
            yield call(changeStep, STEPS.PROMPT_FOR_BANK);
          } else {
            // only set the account as active if the user has a place for disbursement.
            // this prevents a user from accessing the loan dashboard before finishing bank connection.
            if (loanId) {
              yield put({
                type: ACTIONS.SET_ACTIVE_PERSONAL_LOAN_ACCOUNT,
                payload: loanId,
              });
            }
            yield call(changeStep, STEPS.DEPOSIT_INFO);
          }
        } else {
          yield call(
            handleApplicationErrorStates,
            'Error calling AcceptPersonalLoan mutation.',
          );
        }
      } catch (e: any) {
        const errorMessage = (e as ApolloError).graphQLErrors.find(
          (err) => err.extensions.code !== null,
        );
        yield call(handleApplicationErrorStates, errorMessage?.extensions.code);
      } finally {
        yield put(hideLoadingSpinner());
      }
    }
  }
}

function* finishedPromptForBank(): SagaIterator<void> {
  yield call(changeStep, STEPS.DEPOSIT_INFO);
}

function* finishedDepositInfo({
  payload,
}: {
  payload?: { loanId: string; transferParticipantId: string };
}): SagaIterator<void> {
  if (payload) {
    try {
      yield put(showLoadingSpinner());
      const { data }: QueueLoanDisbursementMutationResult = yield call(
        apolloMutationSaga,
        {
          mutation: QueueLoanDisbursementDocument,
          variables: {
            input: payload,
          } satisfies QueueLoanDisbursementMutationVariables,
        },
      );
      const queueLoanDisbursement = data?.queueLoanDisbursement;

      if (!queueLoanDisbursement || !queueLoanDisbursement.didSucceed) {
        yield call(changeStep, STEPS.DISBURSEMENT_ERROR);
        return;
      }
      const { analytics } = yield call(getLoggers);
      analytics.recordEvent('m1_personal_loan_funding_account_confirmed');
      yield call(changeStep, STEPS.AUTOPAY_ENROLLMENT);
    } catch (e: any) {
      yield call(changeStep, STEPS.DISBURSEMENT_ERROR);
    } finally {
      yield put(hideLoadingSpinner());
    }
  }
}

function* finishedNoLinkedAccount(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield call(navigate, {
    to: '/d/c/connect-bank',
    query: {
      connectionType: 'personal_loans',
      previousRouteName: '/d/borrow/marketing',
    },
  });
}

function* finishedLoanRecap(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield call(navigate, { to: '/d/borrow/personal/transactions' });
}

function* finishedApplicationRejected(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield call(navigate, { to: '/d/borrow' });
}

function* finishedManualReview(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield call(navigate, { to: '/d/home' });
}

function* finishedRemovingCreditFreeze({
  payload,
}: Record<string, any>): SagaIterator<void> {
  const { withdrawingApplication, loanId, applicationId } = payload;

  if (withdrawingApplication) {
    if (loanId) {
      yield call(
        handleWithdrawPersonalLoan,
        loanId,
        STEPS.REMOVE_CREDIT_FREEZE,
      );
    } else {
      yield call(
        handleWithdrawPersonalLoanApplication,
        applicationId,
        STEPS.REMOVE_CREDIT_FREEZE,
      );
    }
  } else {
    yield call(changeStep, STEPS.PROCESSING_RESUBMISSION);

    const { data }: ResubmitLoanAfterCreditFreezeMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: ResubmitLoanAfterCreditFreezeDocument,
        variables: {
          input: {} satisfies ResubmitLoanAfterCreditFreezeInput,
        },
      },
    );

    if (
      !data?.resubmitLoanAfterCreditFreeze ||
      !data.resubmitLoanAfterCreditFreeze.outcome
    ) {
      yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
      return;
    }

    const { applicationId } = yield select(
      (state: AppState) => state.newFlows.PERSONAL_LOANS_APPLICATION,
    );

    const applicationStatus = yield call(
      handlePollingLogic,
      pollingApplicationStatus,
      applicationId,
      {
        statusesIgnore: [
          'QUEUED',
          'OFFER_SELECTION_QUEUED',
          // generally, we should ignore the statuses that we started with
          'REJECTED_CREDIT_FROZEN',
        ] as any,
      },
    );

    switch (applicationStatus) {
      case 'INCOME_VERIFICATION_REQUIRED':
        yield call(changeStep, STEPS.INCOME_VERIFICATION);
        break;
      case 'OFFERS_PROVIDED':
        yield call(handleOffersProvided, applicationId);
        break;
      case 'APPROVED': {
        const loanStatus = yield call(
          handlePollingLogic,
          pollingLoanStatus,
          applicationId,
          { statusesMatch: ['CREATED'] as any },
        );

        if (loanStatus === 'CREATED') {
          yield call(changeStep, STEPS.LOAN_TERMS_AND_ACCEPT);
        } else {
          yield call(changeStep, STEPS.APPLICATION_RECEIVED);
        }

        break;
      }
      default:
        yield call(handleApplicationErrorStates, applicationStatus || '');
    }
  }
}

function* handleWithdrawPersonalLoan(
  loanId: string,
  failureStep: ValueOf<typeof STEPS>,
): SagaIterator<void> {
  try {
    yield put(showLoadingSpinner());
    const { data }: WithdrawPersonalLoanMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: WithdrawPersonalLoanDocument,
        variables: { input: { loanId } satisfies WithdrawPersonalLoanInput },
      },
    );
    if (!data?.withdrawPersonalLoan || !data.withdrawPersonalLoan.didSucceed) {
      yield call(handleWithdrawError, failureStep);
    } else {
      yield call(handleWithdrawSuccess);
    }
  } catch (e: any) {
    yield call(handleWithdrawError, failureStep);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* handleWithdrawPersonalLoanApplication(
  applicationId: string,
  failureStep: ValueOf<typeof STEPS>,
): SagaIterator<void> {
  yield put(showLoadingSpinner());
  try {
    const { data }: WithdrawPersonalLoanApplicationMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: WithdrawPersonalLoanApplicationDocument,
        variables: {
          input: {
            applicationId,
          } satisfies WithdrawPersonalLoanApplicationInput,
        },
      },
    );
    if (
      !data?.withdrawPersonalLoanApplication ||
      !data.withdrawPersonalLoanApplication.outcome ||
      !data.withdrawPersonalLoanApplication.didSucceed
    ) {
      yield call(handleWithdrawError, failureStep);
    } else {
      yield call(handleWithdrawSuccess);
    }
  } catch (e: any) {
    yield call(handleWithdrawError, failureStep);
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* handleWithdrawError(failureStep: ValueOf<typeof STEPS>) {
  yield call(changeStep, failureStep);
  yield put(withdrawErrorToast);
}

function* handleWithdrawSuccess(): SagaIterator {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield call(navigate, { to: '/d/home' });
  yield put(withdrawSuccessToast);
}

function* handleApplicationErrorStates(errorMessage: unknown) {
  switch (errorMessage) {
    case 'REJECTED':
      yield call(changeStep, STEPS.APPLICATION_REJECTED);
      break;
    case 'REJECTED_CREDIT_FROZEN':
      yield call(changeStep, STEPS.REMOVE_CREDIT_FREEZE);
      break;
    case 'QUEUED':
    case 'OFFER_SELECTION_QUEUED':
    case 'ERROR_SUBMITTING_APPLICATION':
    case 'ERROR_ACCEPTING_OFFER':
      yield call(changeStep, STEPS.APPLICATION_RECEIVED, true);
      break;
    default:
      yield call(changeStep, STEPS.GENERAL_ERROR_PAGE);
      break;
  }
}

function* handlePollingLogic(
  pollingFunction: (...args: any) => void,
  applicationId: string | null | undefined,
  statuses: {
    statusesIgnore?: Array<LoanApplicationStatusEnum | PersonalLoanStatusEnum>;
    statusesMatch?: Array<LoanApplicationStatusEnum | PersonalLoanStatusEnum>;
  },
) {
  let shouldReFetch = true;

  const startTime = Date.now();

  while (shouldReFetch) {
    const { stopPolling, status } = yield call(
      pollingFunction,
      applicationId,
      statuses,
    );

    if (stopPolling || Date.now() - startTime >= POLL_DURATION) {
      shouldReFetch = false;
      return status;
    }
    yield call(delay, POLL_INTERVAL);
  }
}

function* pollingApplicationStatus(
  applicationId: string,
  statuses: {
    statusesIgnore?: Array<LoanApplicationStatusEnum>;
    statusesMatch?: Array<LoanApplicationStatusEnum>;
  },
) {
  const { status, loanId }: ApplicationByIdStatusPolling = yield call(
    fetchPersonalLoansApplicationByIdInfo,
    applicationId,
  );

  const statusesIgnore = statuses.statusesIgnore;
  const statusesMatch = statuses.statusesMatch;
  const stopPolling = statusesIgnore
    ? !statusesIgnore?.includes(status)
    : statusesMatch?.includes(status);

  if (loanId) {
    yield put({
      type: ACTIONS.PERSONAL_LOANS_STORE_LOAN_ID,
      payload: {
        loanId: loanId,
      },
    });
  }
  return {
    stopPolling,
    status,
  };
}

function* pollingLoanStatus(
  applicationId: string,
  statuses: {
    statusesIgnore?: Array<PersonalLoanStatusEnum>;
    statusesMatch?: Array<PersonalLoanStatusEnum>;
  },
) {
  const { loanStatus }: ApplicationByIdStatusPolling = yield call(
    fetchPersonalLoansApplicationByIdInfo,
    applicationId,
  );

  const statusesIgnore = statuses.statusesIgnore;
  const statusesMatch = statuses.statusesMatch;

  const stopPolling = statusesIgnore
    ? !statusesIgnore?.includes(loanStatus)
    : statusesMatch?.includes(loanStatus);

  return {
    stopPolling,
    status: loanStatus,
  };
}

function* handleOffersProvided(applicationId: string | null | undefined) {
  yield put({
    type: ACTIONS.PERSONAL_LOANS_STORE_APPLICATION_ID,
    payload: {
      applicationId,
    },
  });
  yield call(changeStep, STEPS.LOAN_OFFERS_AND_SUBMIT);
}
