import { Text } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { components } from 'react-select';

import { requireNonNil } from '~/forms/validators';
import { RecurrenceScheduleInput } from '~/graphql/types';

import { ControlledDropdown } from '../../ControlledDropdown';

type BiweeklyIsEvenWeeksDropdownFieldProps = {
  isEvenWeek: boolean;
  disabled?: boolean;
  label?: string;
};

export const BiweeklyIsEvenWeeksDropdownField = ({
  disabled = false,
  isEvenWeek,
  label,
}: BiweeklyIsEvenWeeksDropdownFieldProps) => {
  const { setValue, watch, control } = useFormContext<{
    schedule: RecurrenceScheduleInput;
  }>();

  const options = [
    {
      value: isEvenWeek,
      label: 'This week',
    },
    {
      value: !isEvenWeek,
      label: 'Next week',
    },
  ];

  const handleChange = (isEvenWeeks: boolean) => {
    setValue('schedule.biweekly.isEvenWeeks', isEvenWeeks);
  };

  const currentValue = watch('schedule.biweekly.isEvenWeeks');

  return (
    <ControlledDropdown
      control={control}
      label={label}
      name="schedule.biweekly.isEvenWeeks"
      rules={{
        validate: {
          requireNonNil: requireNonNil,
        },
      }}
      style={{
        marginTop: 16,
      }}
      options={options}
      components={{ Placeholder: BiweeklyIsEvenPlaceholder }}
      disabled={Boolean(
        disabled || !(Array.isArray(options) && options.length > 0),
      )}
      onChange={handleChange}
      value={currentValue}
    />
  );
};

const BiweeklyIsEvenPlaceholder = (
  props: BiweeklyIsEvenWeeksDropdownFieldProps,
) => {
  const hasOptionsLoaded = props;

  const textProps = hasOptionsLoaded
    ? { content: 'Select' }
    : {
        color: 'foregroundNeutralMain',
        content: 'Loading...',
      };
  return (
    // @ts-expect-error - TS2740 - Type '{ children: Element; disabled: boolean; options?: Record<string, any>[] | null | undefined; }' is missing the following properties from type 'CommonProps<any>': clearValue, cx, getStyles, getValue, and 5 more. | TS2786 - 'components.Placeholder' cannot be used as a JSX component.
    <components.Placeholder {...props}>
      {/* @ts-expect-error - TS2322 - Type '{ color: string; content: string; } | { content: string; color?: undefined; }' is not assignable to type 'IntrinsicAttributes & Omit<TextProps, DisallowedTextPropsKeys> & { color?: "success" | "warning" | "grey00" | ... 96 more ... | undefined; } & Partial<...>'. */}
      <Text {...textProps} />
    </components.Placeholder>
  );
};
