import React from 'react';

import {
  StyledTitleBar,
  StyledPaginationButtonContainer,
  StyledResultTable,
  ScreenerTableTitle,
  ScreenerPaginationButtonGroup,
} from '~/components/research/Screener';
import { ScreenerSecurityFragment } from '~/graphql/types';
import { Table } from '~/toolbox/table';

import { StockScreenerTableHeader } from './StockScreenerTableHeader';
import { StockScreenerTableRow } from './StockScreenerTableRow';
import { useStockScreenerResult } from './useStockScreenerResult';

export const StockScreenerTable = () => {
  const {
    queryResult: { data, loading },
    handleNextPage,
    handlePreviousPage,
    hasPreviousPage,
  } = useStockScreenerResult();

  const total = data?.viewer.securities.total;
  const hasNextPage = data?.viewer.securities.pageInfo.hasNextPage;
  const securities = data?.viewer.securities.edges;

  return (
    <div>
      <StyledTitleBar>
        <ScreenerTableTitle loading={loading} total={total} />
        <ScreenerPaginationButtonGroup
          hasNextPage={hasNextPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          hasPreviousPage={hasPreviousPage}
        />
      </StyledTitleBar>
      <StyledResultTable>
        <StockScreenerTableHeader />
        {Boolean(securities?.length) && (
          <Table.Body
            emptyMessage="No results to display!"
            dataTestId="stock-results-table-body"
          >
            {securities?.map((edge, index) => (
              <StockScreenerTableRow
                security={edge as ScreenerSecurityFragment}
                key={index}
              />
            ))}
          </Table.Body>
        )}
      </StyledResultTable>

      <StyledPaginationButtonContainer>
        <ScreenerPaginationButtonGroup
          hasNextPage={hasNextPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          hasPreviousPage={hasPreviousPage}
        />
      </StyledPaginationButtonContainer>
    </div>
  );
};
