import { LS, PS, styled, Theme } from '@m1/liquid-react';

import { SelectProps } from './Dropdown.types';

// FIXME(Wolf): Move these back into the `SelectContainer` file after upgrading react-select.

export const SelectContainerStyledLabel = styled(LS)<
  Pick<SelectProps, 'error'> & {
    appTheme: Theme;
    backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
    isDisabled?: boolean;
    isFocused?: boolean;
  }
>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.backgroundNeutralSecondary};
  left: 8px;
  line-height: 1.5rem;
  padding: 0 8px;
  position: absolute;
  top: 1px;
  z-index: 1;
  font-weight: ${({ error }) => error && 600};
  color: ${({ error, isDisabled, isFocused, theme }) => {
    if (isDisabled) {
      return theme.colors.foregroundNeutralTertiary;
    }
    if (error) {
      return theme.colors.critical;
    }
    if (isFocused) {
      return theme.colors.primary;
    }
    return theme.colors.foregroundNeutralSecondary;
  }};
`;

export const SelectContainerStyledMessage = styled(PS)<
  Pick<SelectProps, 'error'> & {
    isDisabled?: boolean;
  }
>`
  pointer-events: auto;
  font-weight: ${({ error }) => error && 600};
  color: ${({ error, isDisabled, theme }) => {
    if (isDisabled) {
      return theme.colors.foregroundNeutralTertiary;
    }
    if (error) {
      return theme.colors.critical;
    }
    return theme.colors.foregroundNeutralSecondary;
  }};
`;
