import {
  Box,
  Button,
  PL,
  OneColumnConstrained,
  HM,
  TabularDataSet,
  PM,
} from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { ConfirmModal } from '~/components/modals';
import {
  useArchiveTransferRuleMutation,
  useGetTransferRuleQuery,
} from '~/graphql/hooks';
import { TransferRuleDetail_ScheduledTransferRule_Fragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useParams } from '~/hooks/useParams';
import { useSearchParams } from '~/hooks/useSearchParams';
import { useToast } from '~/toasts';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';
import { CashFormatter, isNil, isNotNil } from '~/utils';

import { ScheduleDetails } from './components/ScheduleDetails';
import { ScheduleSwitch } from './components/ScheduleSwitch';

/*
 * Please see additional tests for this page for AutoPay schedules in:
 * functional-tests/tests/credit-card/autoPay.test.ts
 */

export const TransferRuleDetailsPage = () => {
  const navigate = useNavigate();
  const { transferRuleId } = useParams();
  const [searchParams] = useSearchParams();
  const { addToast } = useToast();

  const previousRouteName = searchParams.get('previousRouteName');

  const { data, loading } = useGetTransferRuleQuery({
    variables: {
      id: transferRuleId as string,
    },
    skip: !transferRuleId,
  });

  const transferRule =
    data?.node as TransferRuleDetail_ScheduledTransferRule_Fragment | null;

  const isAutoPay =
    isNotNil(transferRule) &&
    (transferRule?.isCreditCardAutoPay || transferRule?.isPersonalLoanAutoPay);
  const pageTitle = isAutoPay ? 'AutoPay details' : 'Recurring transfer';
  const scheduleLabel = isAutoPay ? 'AutoPay schedule' : 'recurring transfer';
  const deleteButtonText = isAutoPay
    ? 'Delete schedule'
    : 'Delete recurring transfer';

  const [archiveTransferRule] = useArchiveTransferRuleMutation({
    variables: {
      input: {
        transferRuleId: transferRuleId as string,
      },
    },
    onCompleted: () => {
      addToast({
        content: `Your ${scheduleLabel} has been deleted`,
        kind: 'success',
        duration: 'short',
      });
      navigate({
        to: '/d/transfers/rules',
      });
    },
    onError: (err) => {
      addToast({
        content: err.message,
        kind: 'alert',
        duration: 'short',
      });
    },
  });

  const amount = React.useMemo(
    () =>
      isAutoPay &&
      isNotNil(transferRule?.schedulePresetIndicator) &&
      transferRule?.schedulePresetIndicator !== 'FIXED_AMOUNT'
        ? capitalize(transferRule?.schedulePresetIndicator.replace('_', ' '))
        : CashFormatter.format(transferRule?.amount ?? 0),
    [isAutoPay, transferRule?.schedulePresetIndicator, transferRule?.amount],
  );

  if (loading) {
    return <Spinner />;
  }

  if (isNil(transferRuleId) || isNil(transferRule)) {
    return <GenericSystemError />;
  }

  return (
    <OneColumnConstrained>
      <BackArrow
        caret
        content="Back"
        mt={16}
        onClick={() => {
          // We can't navigate "back" if the previous route was the transfer creation page
          (previousRouteName ?? '').includes('transfer-created')
            ? navigate({ to: '/d/transfers/rules' })
            : window.history.back();
        }}
      />
      <HM my={40} fontWeight={300}>
        {pageTitle}
      </HM>
      <PM color="foregroundNeutralSecondary">Amount</PM>
      <HM mt={4} mb={24} fontWeight={600}>
        {amount}
      </HM>

      <Divider spacing="none" />

      <Box mt={40}>
        <TabularDataSet
          hasBorder={false}
          tabularData={[
            {
              label: 'Transfer from',
              data: (
                <PL fontWeight={600}>
                  {transferRule.from?.transferParticipantName}
                </PL>
              ),
            },
            {
              label: 'Transfer to',
              data: (
                <PL fontWeight={600}>
                  {transferRule.to?.transferParticipantName}
                </PL>
              ),
            },
          ]}
        />
      </Box>

      {isNotNil(transferRule?.schedule) && (
        <ScheduleDetails
          mt={40}
          schedule={transferRule.schedule}
          isAutoPay={isAutoPay}
        />
      )}

      <ScheduleSwitch
        isRuleEnabled={transferRule.isEnabled}
        isPersonalLoanAutoPay={transferRule.isPersonalLoanAutoPay}
        isAutoPay={isAutoPay}
        transferRuleId={transferRuleId}
      />

      <ConfirmModal
        title={`Are you sure you want to delete this ${scheduleLabel}?`}
        trigger={
          <Button my={64} kind="destructive" size="large">
            {deleteButtonText}
          </Button>
        }
        onConfirm={() => {
          archiveTransferRule();
        }}
      />
    </OneColumnConstrained>
  );
};
