import { Box, Flex, LS, PL, PM } from '@m1/liquid-react';
import clamp from 'lodash-es/clamp';
import * as React from 'react';

import { InvestFundingFragment } from '~/graphql/types';
import { EXTERNAL_LINKS } from '~/static-constants';
import { Link } from '~/toolbox/link';
import { formatCurrencyWithCommasOrNull } from '~/utils';

export type IraTotalsProps = {
  account: InvestFundingFragment;
};

export const IraTotals = ({ account }: IraTotalsProps) => {
  const totalsByYear = account.iraContributionTotals
    ? [...account.iraContributionTotals.totalsByYear].sort(
        (a, b) => b.year - a.year,
      )
    : null;
  if (!totalsByYear) {
    return (
      <PL content="Temporarily unable to retrieve IRA contributions history." />
    );
  }
  return (
    <Box>
      {totalsByYear.map((byYear) => (
        <Flex p="2.4rem 0" key={byYear.year}>
          <Box width="4.8rem" alignSelf="center">
            {byYear.year}
          </Box>
          <Box position="relative" height="4rem" flex="1 0 auto">
            <Flex
              position="absolute"
              width="100%"
              bottom="calc(100% + 3px)"
              left={0}
              justifyContent="space-between"
            >
              <PM
                fontWeight={600}
                content={formatCurrencyWithCommasOrNull(
                  byYear.totalContribution,
                )}
              />
              <PM
                fontWeight={600}
                content={formatCurrencyWithCommasOrNull(byYear.maxContribution)}
              />
            </Flex>
            <Flex
              width="100%"
              height="4rem"
              alignItems="center"
              backgroundColor="backgroundNeutralTertiary"
            >
              {byYear.totalContribution ? (
                <Box
                  m="-1px"
                  height="4rem"
                  backgroundColor="primary"
                  width={`${calcBarWidthPct(
                    byYear.totalContribution,
                    byYear.maxContribution,
                  )}%`}
                />
              ) : (
                <LS
                  color="foregroundNeutralSecondary"
                  content="You have no contributions for this year."
                  ml={16}
                />
              )}
            </Flex>
          </Box>
        </Flex>
      ))}
      <Box pl={48}>
        <PM color="foregroundNeutralSecondary" pb={8}>
          Represents your contributions compared to the maximum amount alloted
          specified in official IRS guidelines. You may have a different limit.
          See{' '}
          <Link
            fontWeight={400}
            fontSize="14px"
            to={EXTERNAL_LINKS.IRS_IRA_CONTRIB_LIMITS}
            target="_blank"
          >
            here
          </Link>{' '}
          for more information.
        </PM>
        <PM
          color="foregroundNeutralSecondary"
          content="Amounts include only contributions to this M1 IRA."
          pb={8}
        />
      </Box>
    </Box>
  );
};

function calcBarWidthPct(total: number, max: number): number {
  return clamp((total / max) * 100, 0, 100);
}
