import React from 'react';
import { Outlet } from 'react-router-dom';

import { useWireTransferAccountsQuery } from '~/graphql/hooks';
import { TransferParticipantFragment } from '~/graphql/types';

type WireTransferContextType = {
  accounts: TransferParticipantFragment[] | null | undefined;
  selectedAccount: string | null;
  setSelectedAccount: React.Dispatch<
    React.SetStateAction<string | null>
  > | null;
};

export const WireTransferContext = React.createContext<WireTransferContextType>(
  {
    accounts: null,
    selectedAccount: null,
    setSelectedAccount: null,
  },
);

export const WireTransferProvider = () => {
  const [selectedAccount, setSelectedAccount] = React.useState<null | string>(
    null,
  );

  const { data } = useWireTransferAccountsQuery();

  const accounts =
    data?.viewer.transfers?.moveMoneyV2?.wireTransferParticipants?.list;

  return (
    <WireTransferContext.Provider
      value={{
        accounts,
        selectedAccount,
        setSelectedAccount,
      }}
    >
      <Outlet />
    </WireTransferContext.Provider>
  );
};
