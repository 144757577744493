import { SagaIterator } from 'redux-saga';

import {
  MoveMoneyDirectDepositDocument,
  MoveMoneyDirectDepositQueryResult,
} from '~/graphql/hooks';
import { TransferParticipantTypeEnum } from '~/graphql/types';

import { NavigateFunction } from '~/hooks/useNavigate';
import { ACTION_TYPES } from '~/redux/actions';
import { GlobalState } from '~/redux/reducers/globalReducer';
import {
  CREATE_TRANSFER_FLOW_MODES,
  MOVE_MONEY_FLOW_STEPS as STEPS,
} from '~/static-constants';

import type { AppState } from '../../../reducers/types';
import { apolloQuerySaga } from '../../apolloQuerySaga';
import { call, fork, put, select } from '../../effects';
import { changeStep, makeFlowFuncs } from '../utils';

import { loanAutoPayQuery } from './loanAutoPayQuery';
import { queryAccountIds } from './remote';

const { takeFlow, takeFlowStep } = makeFlowFuncs('MOVE_MONEY');

export function* moveMoneySaga(): SagaIterator<void> {
  yield fork(takeFlow, beginMoveMoneyFlow);
}

// eslint-disable-next-line consistent-return
function* beginMoveMoneyFlow({
  payload: { previousRouteName },
}: {
  payload: {
    previousRouteName: string;
    fromParticipantTypeParam: string | null | undefined;
    toParticipantTypeParam: string | null | undefined;
    mode: 'ONE_TIME' | 'SCHEDULE';
    onFinish: (...args: Array<any>) => any;
  };
}): SagaIterator<void> {
  yield fork(takeFlowStep, STEPS.MOVE_MONEY, finishedMoveMoneyStep);

  const isDirectDepositIntroStep = window.location.pathname.includes(
    STEPS.DIRECT_DEPOSIT,
  );
  const isDirectHysa = window.location.pathname.includes(
    STEPS.DIRECT_DEPOSIT_HYSA,
  );
  const isDirectInvest = window.location.pathname.includes(
    STEPS.DIRECT_DEPOSIT_INVEST,
  );

  if (isDirectDepositIntroStep || isDirectHysa || isDirectInvest) {
    // If we are in a direct deposit step, load account options.
    const { data }: MoveMoneyDirectDepositQueryResult = yield call(
      apolloQuerySaga,
      {
        query: MoveMoneyDirectDepositDocument,
      },
    );

    const hysaIds = data?.viewer.save?.savings?.savingsAccounts?.edges?.reduce(
      (acc, edge) => {
        if (edge?.node?.id) {
          acc.push(edge.node.id);
        }
        return acc;
      },
      [] as string[],
    );
    const investIds = data?.viewer.accounts?.edges?.reduce((acc, edge) => {
      if (
        edge?.node?.id &&
        edge?.node?.originator === 'M1' &&
        edge?.node?.registration === 'INDIVIDUAL'
      ) {
        acc.push(edge.node.id);
      }
      return acc;
    }, [] as string[]);

    yield put({
      type: ACTION_TYPES.SET_MOVE_MONEY_DIRECT_DEPOSIT_IDS,
      payload: {
        hysaIds,
        investIds,
      },
    });

    if (isDirectHysa || isDirectInvest) {
      // If we are in a step that already has a selected option, navigate there.
      yield call(
        changeStep,
        isDirectHysa ? STEPS.DIRECT_DEPOSIT_HYSA : STEPS.DIRECT_DEPOSIT_INVEST,
      );
    }
  }

  if (previousRouteName) {
    yield put({
      type: 'SET_MOVE_MONEY_FROM',
      payload: previousRouteName,
    });
  }

  // Redirect move money if query params require it on saga load
  yield call(handleRedirect);
}

function* finishedMoveMoneyStep(): SagaIterator<void> {
  // Redirect move money after clicking a move money option
  yield call(handleRedirect);
}

const {
  Borrow,
  CreditCard,
  Crypto,
  CryptoExternal,
  External,
  FundingSource,
  Invest,
  Loan,
  PhysicalCheck,
  Rewards,
  Save,
  Spend,
} = TransferParticipantTypeEnum;

function isValidParticipantType(
  query: string,
): query is TransferParticipantTypeEnum {
  const possibleParticipantTypes: Array<TransferParticipantTypeEnum> = [
    Invest,
    Spend,
    Borrow,
    Loan,
    External,
    PhysicalCheck,
    CreditCard,
    Rewards,
    Crypto,
    CryptoExternal,
    Save,
    FundingSource,
  ];

  return possibleParticipantTypes.includes(
    query as TransferParticipantTypeEnum,
  );
}

/**
 * Returns the account of the corresponding participant type from global state
 *
 * @todo EXTERNAL, PHYSICAL_CHECK, REWARDS, CRYPTO_EXTERNAL, FUNDING_SOURCE are unhandled - we can get the first of those types using queries
 * EXTERNAL and possibly others do not have easy ways to query from a list of that type. We could either query for participants of that type
 * from Transfers.participants, query from some other field that exposes it (Account.externalAccounts exposes the list of exteranl accounts for a single invest account),
 * query for the opposite participants of the given opposite type that does resolve an id (ex. we have an invest account ID in global state, what are it's
 * external account opposite participants?), or we can keep this as-is and say these account types are not supported. Some, like physical check,
 * may never be supported anyway (regardless of checking sunsetting).
 *
 * @param {(string | null | undefined)} participantTypeQueryParam
 * @param {GlobalState} globalState
 * @return {*}  {(Generator<{
 *   id: string | null | undefined;
 *   type: TransferParticipantTypeEnum | null | undefined;
 * }>)}
 */
function* getParticipant(
  participantTypeQueryParam: string | null | undefined,
  globalState: GlobalState,
): Generator<{
  id: string | null | undefined;
  type: TransferParticipantTypeEnum | null | undefined;
}> {
  let type;
  if (
    !participantTypeQueryParam ||
    !isValidParticipantType((type = participantTypeQueryParam.toUpperCase()))
  ) {
    return yield {
      id: null,
      type: null,
    };
  }
  switch (type) {
    // If the user has these account types, the ID will exist in global state
    // due to `selectInitialAccounts` that bootstraps the user's accounts in redux
    // If they don't have the account in redux, then we don't care about the ID
    // And the query param will effectively be ignored
    case 'INVEST':
      return yield {
        id: globalState.investAccountId,
        type,
      };
    case 'SPEND':
      return yield {
        id: globalState.checkingAccountId,
        type,
      };
    case 'BORROW':
      return yield {
        id: globalState.borrowAccountId,
        type,
      };
    case 'CREDIT_CARD':
      return yield {
        id: globalState.creditAccountId,
        type,
      };
    case 'SAVE':
      return yield {
        id: globalState.savingsAccountId,
        type,
      };
    case 'LOAN':
      return yield {
        id: globalState.personalLoanAccountId,
        type,
      };
    case 'CRYPTO':
      return yield {
        id: globalState.cryptoAccountId,
        type,
      };
    // TODO - we could handle the other missing cases by making queries for the participants for each given type
    default:
      return {
        id: null,
        type: null,
      };
  }
}

const getMode = (
  query: string | null,
): keyof typeof CREATE_TRANSFER_FLOW_MODES | null => {
  if (typeof query !== 'string') {
    return null;
  }
  const normalizedQuery =
    query.toUpperCase() as keyof typeof CREATE_TRANSFER_FLOW_MODES;
  return ['SCHEDULE', 'ONE_TIME'].includes(normalizedQuery)
    ? normalizedQuery
    : null;
};

// eslint-disable-next-line consistent-return
function* handleRedirect(): SagaIterator<void> {
  const global: GlobalState = yield select((state: AppState) => state.global);
  const {
    navigate,
    previousRouteName,
  }: {
    navigate: NavigateFunction;
    previousRouteName: string | null | undefined;
  } = yield select((state: AppState) => ({
    navigate: state.routing.navigate,
    previousRouteName: state.newFlows.MOVE_MONEY.previousRouteName,
  }));
  const query = new URLSearchParams(window.location.search);
  let fromParticipantTypeParam = query.get('fromParticipantType');
  let toParticipantTypeParam = query.get('toParticipantType');
  let mode = getMode(query.get('mode'));

  /**
   * These are legacy query params - do not remove until marketing confirms they are no longer used
   * Also do not use them anymore in web - alternatives are provided as comments
   * I don't believe any of these are used in web code, but they may be used in lens still
   *
   * This did not appear to be used anywhere in web, and it is not in confluence:
   * const isPersonalLoansAutoPay = query.get('isPersonalLoansAutoPay') === 'true';
   *
   * The query params below were used in web and migrated:
   */
  if (query.get('autoPay') === 'true') {
    fromParticipantTypeParam = 'CREDIT_CARD';
    mode = 'SCHEDULE';
  } else if (query.get('isPersonalLoansAutoPay') === 'true') {
    toParticipantTypeParam = 'LOAN';
    mode = 'SCHEDULE';
  } else if (query.get('checkingDeposit') === 'true') {
    toParticipantTypeParam = 'SPEND';
    mode = 'ONE_TIME';
  } else if (query.get('creditPayment') === 'true') {
    fromParticipantTypeParam = 'CREDIT_CARD';
    mode = 'ONE_TIME';
  }

  const { id: fromParticipantId } = yield call(
    getParticipant,
    fromParticipantTypeParam,
    global,
  );
  const { id: toParticipantId, type: toParticipantType } = yield call(
    getParticipant,
    toParticipantTypeParam,
    global,
  );

  // Query Param Re-routes
  if (
    mode === 'SCHEDULE' &&
    toParticipantType &&
    ['LOAN', 'CREDIT_CARD'].includes(toParticipantType)
  ) {
    // if query params might be an autopay, let them view it if one exists
    const { autoPayId } =
      toParticipantType === 'LOAN'
        ? yield call(loanAutoPayQuery)
        : yield call(queryAccountIds);
    if (autoPayId) {
      const ruleDetailsRoute = '/d/rule-details';
      return yield call(navigate, {
        to: `${ruleDetailsRoute}/${autoPayId}`,
        query: {
          ...(previousRouteName && { previousRouteName }),
        },
        options: {
          replace: true,
        },
      });
    }
  }

  if (fromParticipantId || toParticipantId || mode) {
    // An ID was able to be matched or a mode was provided
    // any of these query params will trigger redirect to create transfer
    yield call(navigate, {
      to: '/d/c/create-transfer',
      query: {
        ...(fromParticipantId && { fromParticipantId }),
        ...(toParticipantId && { toParticipantId }),
        ...(mode && { mode }),
        ...(previousRouteName && { previousRouteName }),
      },
      options: {
        replace: true,
      },
    });
  }
}
