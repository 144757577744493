// Step 1: Pull in the RHF type from the form itself
import { AddressFormFields } from '~/components/form/address/types';
import { AnnualIncomeFormValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/AnnualIncome';
import { ContactInfoFormValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/ContactInfo';
import { DisclosuresFormValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/Disclosures';
import { EmploymentFormValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/Employment';
import { IdentityFormValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/Identity';
import { InvestmentExperienceValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/InvestmentExperience';
import { LiquidityNeedsValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/LiquidityNeeds';
import { LiquidNetWorthValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/LiquidNetWorth';
import { NetWorthValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/NetWorth';
import { RiskToleranceValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/RiskTolerance';
import { SocialSecurityNumberFormValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/SocialSecurityNumber';
import { TimeHorizonValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/TimeHorizon';
import { TrustedContactValues } from '~/flows/components/OpenInvestJointAccountWizard/steps/TrustedContact';
import { InvestmentExperienceEnum } from '~/graphql/types';
import { TransferDetailsFormValues } from '~/pages/dashboard/covers/create-transfer/steps/TransferDetails.types';
import { TransferNiaCalculatorFormValues } from '~/pages/dashboard/covers/create-transfer/steps/TransferNiaCalculator';
import { EnhancedAuthPasswordForm } from '~/pages/dashboard/settings/security/components/steps/PerformEnhancedAuth';
import { DateOfBirthAndCitizenshipFormValues } from '~/pages/personal-loan-direct/components/DateOfBirthAndCitizenshipForm';
import { DirectToPersonalLoansSignUpFormValues } from '~/pages/personal-loan-direct/components/DirectToPersonalLoansSignUpForm';
import { FinancialDisclosuresFormValues } from '~/pages/personal-loan-direct/components/FinancialDisclosuresForm.constants';
import { LoanInfoFormValues } from '~/pages/personal-loan-direct/components/LoanInfoForm';
import { DirectToPersonalLoansAnnualIncomeMonthlyRentMortgageFormValues } from '~/pages/personal-loan-direct/steps/AnnualIncomeMonthlyRentMortgage';
import { AvailableCashNetWorthFormValues } from '~/pages/personal-loan-direct/steps/AvailableCash';
import { EmploymentInfoFormValues } from '~/pages/personal-loan-direct/steps/EmploymentStatusAndInfo';

// Step 2: Update this type to include the form name and its associated value type
type OpenJointInvestAccountFormValues = {
  openJointInvestAccountContactInfo?: ContactInfoFormValues;
  openJointInvestAccountIdentity?: IdentityFormValues;
  openJointInvestAccountSocialSecurityNumber?: SocialSecurityNumberFormValues;
  openJointInvestAccountDisclosures?: DisclosuresFormValues;
  openJointInvestAccountEmployment?: EmploymentFormValues;
  openJointInvestAccountAnnualIncome?: AnnualIncomeFormValues;
  openJointInvestAccountNetWorth?: NetWorthValues;
  openJointInvestAccountLiquidNetWorth?: LiquidNetWorthValues;
  openJointInvestAccountInvestmentExperience?: InvestmentExperienceValues;
  openJointInvestAccountRiskTolerance?: RiskToleranceValues;
  openJointInvestAccountTimeHorizon?: TimeHorizonValues;
  openJointInvestAccountLiquidityNeeds?: LiquidityNeedsValues;
  openJointInvestAccountTrustedContact?: TrustedContactValues;
};

type OpenCashAccountFormValues = {
  openCashAccountInvestmentExperience?: {
    investmentExperience: InvestmentExperienceEnum;
  };
};

type ChangePasswordFormValues = {
  changePassword?: {
    oldPassword: string;
    newPassword: string;
  };
};

type CreateTransferFormValues = {
  createTransferDetails?: TransferDetailsFormValues;
};

type NiaCalculatorFormValues = {
  niaCalculator?: TransferNiaCalculatorFormValues;
};

type OpenPersonalLoansDirectFormValues = {
  directToPersonalLoansSignUpInfo?: DirectToPersonalLoansSignUpFormValues;
  dateOfBirthAndCitizenshipInfo?: DateOfBirthAndCitizenshipFormValues;
  directToPersonalLoansPhoneNumber?: {
    phoneNumber: string;
  };
  directToPersonalLoansPreApprovedOffer?: directToPersonalLoansPreApprovedOfferValues;
  homeAddress?: AddressFormFields;
  annualIncomeMonthlyHousingCost?: DirectToPersonalLoansAnnualIncomeMonthlyRentMortgageFormValues;
  directToPersonalLoansEmploymentInfo?: EmploymentInfoFormValues;
  financialDisclosures?: FinancialDisclosuresFormValues;
  directToPersonalLoansAvailableCashNetWorthInfo?: AvailableCashNetWorthFormValues;
  directToPersonalLoansTransferParticipant?: DirectToPersonalLoansTransferParticipantValues;
};

type DirectToPersonalLoansTransferParticipantValues = {
  transferParticipantId: string;
  transferParticipantName?: string;
};

type directToPersonalLoansPreApprovedOfferValues = {
  offerId: string;
  formattedMonthlyPayment: string;
  formattedAnnualPercentageInfo: string;
  loanAmount: string;
  loanTerm: number;
};

type LoanInformationFormValues = {
  loanInfo?: LoanInfoFormValues;
};

type EnhancedAuthPasswordFormValues = {
  enhancedAuthPasswordForm?: EnhancedAuthPasswordForm;
};

export type SupportedReactHookFormTypes = OpenJointInvestAccountFormValues &
  OpenCashAccountFormValues &
  CreateTransferFormValues &
  OpenPersonalLoansDirectFormValues &
  LoanInformationFormValues &
  NiaCalculatorFormValues &
  ChangePasswordFormValues &
  EnhancedAuthPasswordFormValues;

// Step 3: That's it! You can now dispatch 'submitReactFormData' from React on form submission.
export type SubmitReactHookFormAction = {
  payload: {
    [K in keyof SupportedReactHookFormTypes]: SupportedReactHookFormTypes[K];
  };
  type: 'SUBMIT_REACT_HOOK_FORM_DATA';
};

export type ClearSingleReactHookFormAction = {
  payload: keyof SupportedReactHookFormTypes;
  type: 'CLEAR_SINGLE_REACT_HOOK_FORM';
};

export type ResetAllReactHookFormDataAction = {
  type: 'RESET_ALL_REACT_FORM_DATA';
};

export const submitReactFormData = (
  payload: SubmitReactHookFormAction['payload'],
): SubmitReactHookFormAction => ({
  payload,
  type: 'SUBMIT_REACT_HOOK_FORM_DATA',
});

export const clearSingleReactHookForm = (
  payload: ClearSingleReactHookFormAction['payload'],
): ClearSingleReactHookFormAction => ({
  payload,
  type: 'CLEAR_SINGLE_REACT_HOOK_FORM',
});

export const resetAllReactHookFormData =
  (): ResetAllReactHookFormDataAction => ({
    type: 'RESET_ALL_REACT_FORM_DATA',
  });
