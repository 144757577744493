import * as React from 'react';

import { FundScreenerQueryResultContext } from './FundScreenerProvider';

export const useFundScreenerResult = () => {
  const queryResult = React.useContext(FundScreenerQueryResultContext);
  if (!queryResult.queryResult) {
    throw new Error(
      'useFundScreenerResult must be used within a FundScreenerProvider',
    );
  }
  return queryResult;
};
