import moment from 'moment';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import {
  GetTransferWizardPreloadQueryResult,
  useGenerateIdempotencyKeyMutation,
  useGetTransferWizardPreloadQuery,
} from '~/graphql/hooks';
import {
  TransferParticipantSideEnum,
  TransferScenarioTypeEnum,
} from '~/graphql/types';
import { useSearchParams } from '~/hooks/useSearchParams';
import { CREATE_TRANSFER_FLOW_MODES } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';

type TransferMode = keyof typeof CREATE_TRANSFER_FLOW_MODES;
export type CreateTransferContextType = {
  mode: TransferMode;
  pivot: TransferParticipantSideEnum;
  scenarioQueryParam: TransferScenarioTypeEnum | null;
  fromParticipantIdQueryParam: string | null;
  toParticipantIdQueryParam: string | null;
  idempotencyKey: string | null | undefined;
} & GetTransferWizardPreloadQueryResult;

export const CreateTransferContext =
  React.createContext<CreateTransferContextType | null>(null);
export const CreateTransferProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const [defaultDate] = React.useState(moment().toISOString());
  const [params] = useSearchParams();

  /*
   * The pivot is the side of the transfer that filters the participants for the other side.
   * Pivoting on 'From' means that the 'To' side will be filtered by the 'From' side.
   */
  const pivot =
    (params.get('pivot') ?? '').toLowerCase() === 'destination'
      ? TransferParticipantSideEnum.To
      : TransferParticipantSideEnum.From;

  const scenarioQueryParam =
    (params.get('scenario') as TransferScenarioTypeEnum) || null;

  const data = useGetTransferWizardPreloadQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      date: defaultDate,
      scenario:
        scenarioQueryParam ?? TransferScenarioTypeEnum.MoveMoneyTransfer,
    },
  });

  /*
   * We need to generate a unique idempotency key for every transfer.
   * Note that this work is temporary and will be deprecated by
   * april 2024. By then BE will be able to accept any random string as
   * the idempotency key, and will generate a UUID accordingly.
   */
  const [
    generateIdempotencyKey,
    { data: idempotencyKeyData, loading: idempotencyKeyLoading },
  ] = useGenerateIdempotencyKeyMutation({});

  React.useEffect(() => {
    generateIdempotencyKey({
      variables: {
        input: {},
      },
    });
  }, [generateIdempotencyKey]);

  const { loading, error } = data;

  if (loading || idempotencyKeyLoading) {
    return <Spinner fullScreen />;
  }

  if (error) {
    return <GenericSystemError />;
  }

  const idempotencyKey =
    idempotencyKeyData?.generateIdempotencyKey?.outcome?.idempotencyKey;

  const mode = (params.get('mode') ?? 'ONE_TIME') as TransferMode;
  const fromParticipantIdQueryParam = params.get('fromParticipantId') ?? null;
  const toParticipantIdQueryParam = params.get('toParticipantId') ?? null;

  return (
    <CreateTransferContext.Provider
      value={{
        mode,
        fromParticipantIdQueryParam,
        toParticipantIdQueryParam,
        scenarioQueryParam,
        idempotencyKey,
        pivot,
        ...data,
      }}
    >
      {children}
    </CreateTransferContext.Provider>
  );
};
