import React from 'react';

import {
  SecurityDetailsQueryHookResult,
  useSecurityDetailsQuery,
} from '~/graphql/hooks';

type Props = {
  disableActions?: boolean;
  disableBreadcrumbs?: boolean;
  disableWatchlist?: boolean;
  securityId: string;
};

type TSecurityDetailsContext = {
  queryResult: SecurityDetailsQueryHookResult;
  disableActions?: boolean;
  disableBreadcrumbs?: boolean;
  disableWatchlist?: boolean;
  isCrypto: boolean;
} | null;

export const SecurityDetailsContext =
  React.createContext<TSecurityDetailsContext>(null);

export const SecurityDetailsProvider = ({
  disableActions = false,
  disableWatchlist = false,
  disableBreadcrumbs = false,
  securityId,
  children,
}: React.PropsWithChildren<Props>) => {
  const { data, loading, error, ...rest } = useSecurityDetailsQuery({
    variables: {
      id: securityId,
    },
    fetchPolicy: 'network-only',
  });

  const isCrypto = location.pathname.includes('crypto');

  return (
    <SecurityDetailsContext.Provider
      value={{
        queryResult: {
          data,
          loading,
          error,
          ...rest,
        },
        disableActions,
        disableWatchlist,
        disableBreadcrumbs,
        isCrypto,
      }}
    >
      {children}
    </SecurityDetailsContext.Provider>
  );
};
