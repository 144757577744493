import { HXXS } from '@m1/liquid-react';
import * as React from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ControlledInput } from '~/components/form/ControlledInput';
import { CountryOfCitizenshipDropdown } from '~/components/form/Dropdowns/CountryOfCitizenshipDropdown';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { useWizardContext } from '~/flows/wizard';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { printableAsciiChars, date } from '~/forms/validators';
import { OnlineAccountRegistrationEnum } from '~/graphql/types';
import { AppState } from '~/redux';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export interface DateOfBirthAndCitizenshipFormValues {
  dateOfBirth: any;
  countryOfCitizenship: any;
}

export const DateOfBirthAndCitizenshipForm = () => {
  const dispatch = useDispatch();

  const { next } = useWizardContext();

  const persistedFormValues = useSelector((state: AppState) => {
    return state.reactHookForm['dateOfBirthAndCitizenshipInfo'];
  });
  const formMethods = useForm<DateOfBirthAndCitizenshipFormValues>({
    defaultValues: persistedFormValues,
  });
  const { control, watch, handleSubmit, register, setValue } = formMethods;

  const countryOfCitizenship = watch('countryOfCitizenship');

  const onSubmit: SubmitHandler<DateOfBirthAndCitizenshipFormValues> = (
    data,
  ) => {
    dispatch(
      submitReactFormData({
        dateOfBirthAndCitizenshipInfo: data,
      }),
    );

    next();
  };

  const warning = (
    <>
      You must be a <b>U.S. Citizen</b> to be eligible for a Personal Loan
      offered by M1.
    </>
  );

  const dateValidation = date({
    checkAge: true,
    rejectFutureDateOfBirth: true,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HXXS pt={24}>Date of birth</HXXS>
        <ControlledInput
          name="dateOfBirth"
          label="Date of birth"
          id="dateOfBirth"
          placeholder="MM/DD/YYYY"
          control={control}
          rules={{
            required: 'Required',
            validate: {
              printableAsciiChars: (v) =>
                typeof v === 'string' && printableAsciiChars(v),
              dateValidation: (v) => dateValidation(v),
            },
          }}
          maskType="date"
          maxLength={30}
        />

        <HXXS mt={24}>Citizenship</HXXS>
        <CountryOfCitizenshipDropdown
          {...register('countryOfCitizenship', {
            required: true,
          })}
          accountRegistration={OnlineAccountRegistrationEnum.Individual}
          control={control}
          warning={
            typeof countryOfCitizenship === 'string' &&
            countryOfCitizenship !== 'USA' &&
            warning
          }
          backgroundColor="backgroundNeutralSecondary"
        />
        <ResponsiveButton
          mt={48}
          type="submit"
          size="large"
          label="Continue"
          kind="primary"
        />
        <FormMockerReactHookForm
          setValue={setValue}
          fields={[
            {
              name: 'dateOfBirth',
              value: '05/15/1987',
            },
            {
              name: 'countryOfCitizenship',
              value: 'USA',
            },
          ]}
        />
      </form>
    </FormProvider>
  );
};
