import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';
import { PlaidLinkResult } from 'react-plaid-link';

import {
  OnlineAccountRegistrationEnum,
  ProductListIdentifier,
} from '~/graphql/types';

import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { UserProfileInput } from '~/redux/actions/newFlows/identityFirst/identityFirstActions.types';
import {
  IRAFundingType,
  OnboardingProductSelection,
} from '~/redux/actions/newFlows/onboarding/onboardingActions.types';
import { ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createStepReducer } from '../utils';

export type OnboardingFlowState = FlowState<
  typeof STEPS,
  {
    accountId: string | null | undefined;
    achRelationshipId: string | null | undefined;
    basePath: string;
    phone: string | null | undefined;
    plaidFailure: boolean;
    plaidPayload: PlaidLinkResult | null | undefined;
    product: OnboardingProductSelection;
    skippedBankSetup: boolean;
    subProduct: OnlineAccountRegistrationEnum | null | undefined;
    fundingType: IRAFundingType | null;
    userProfileInput: UserProfileInput | null;
    upcomingStep: ValueOf<typeof STEPS> | null | undefined;
  }
>;

export const onboardingFlowInitialState: OnboardingFlowState = {
  accountId: null,
  achRelationshipId: null,
  basePath: '',
  phone: null,
  plaidFailure: false,
  plaidPayload: null,
  product: {
    destination: 'Invest',
    productIdentifier: ProductListIdentifier.InvestIndividual,
  },
  subProduct: null,
  fundingType: null,
  skippedBankSetup: false,
  step: STEPS.PHONE_VERIFICATION,
  stepTitle: 'Welcome to M1',
  userProfileInput: null,
  upcomingStep: null,
};

const stepReducer = createStepReducer(onboardingFlowInitialState);

const readOnboardingHeaderStep = (
  upcomingStep: ValueOf<typeof STEPS> | null | undefined,
) => {
  switch (upcomingStep) {
    case STEPS.PHONE_VERIFICATION:
    case STEPS.SETUP_ACCOUNT:
      return 'Open Account';
    case STEPS.INITIAL_FUNDING:
      return 'Fund your account';
    default:
      return 'Welcome to M1';
  }
};

export function onboarding(
  state: OnboardingFlowState = onboardingFlowInitialState,
  action: any,
): OnboardingFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_ONBOARDING_FLOW:
      return {
        ...onboardingFlowInitialState,
        ...pick(action.payload.initialState, keys(onboardingFlowInitialState)),
        basePath: action.payload.basePath,
        step: STEPS.PHONE_VERIFICATION,
        product: action.payload.product,
        plaidFailure: action.payload.plaidFailure,
        plaidPayload: action.payload.plaidRedirect,
        subProduct: action.payload.subProduct,
      };
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action) as ValueOf<typeof STEPS>,
      };
    case ACTIONS.ACCOUNT_SETUP_FLOW_FINISHED:
    case ACTIONS.INVEST_ACCOUNT_SETUP_FLOW_FINISHED:
      return Object.assign(
        {
          ...state,
        },
        {
          accountId: action.payload,
        },
      );
    case ACTIONS.IDENTITY_FIRST_FLOW_FINISHED:
      return {
        ...state,
        userProfileInput: action.payload,
      };
    case ACTIONS.FINISHED_ONBOARDING_BANK_SETUP:
      return Object.assign(
        {
          ...state,
        },
        {
          achRelationshipId: action.payload,
        },
      );
    case ACTIONS.SET_ONBOARDING_PRODUCT:
      // We want to reset the subProduct so that if the user goes back and
      // selects a new product it doesn't get overridden by the sub product
      return {
        ...state,
        product: action.payload,
        subProduct: null,
      };
    case ACTIONS.SET_ONBOARDING_SUBPRODUCT: {
      return {
        ...state,
        subProduct: action.payload,
      };
    }
    case ACTIONS.SET_IRA_FUNDING_TYPE: {
      return {
        ...state,
        fundingType: action.payload,
      };
    }
    case ACTIONS.SET_ONBOARDING_NEXT_STEP:
      return {
        ...state,
        upcomingStep: action.payload,
        stepTitle: readOnboardingHeaderStep(action.payload),
      };
    case ACTIONS.SKIPPED_BANK_SETUP:
      return Object.assign(
        {
          ...state,
        },
        {
          skippedBankSetup: true,
        },
      );
    case ACTIONS.FINISHED_ONBOARDING_FLOW:
      return {
        ...state,
        basePath: '',
      };
    default:
      return state;
  }
}
