import { Atomic, Product } from '@atomicfi/transact-javascript';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useGenerateAtomicTokenMutation,
  useSetDirectDepositDestinationMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { SegmentEventName } from '~/loggers/analytics-reporter/segment.types';
import { useSelector } from '~/redux/hooks';
import { MOVE_MONEY_FLOW_STEPS } from '~/static-constants';

import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { InvestDirectDespositAccounts } from './InvestDirectDepositAccounts';

export const AtomicDirectDepositStep = () => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();
  const [generateAtomicToken, { loading: tokenLoading }] =
    useGenerateAtomicTokenMutation({});
  const [setDirectDepositDestination, { loading: destinationloading }] =
    useSetDirectDepositDestinationMutation();
  usePortaledSpinner(tokenLoading || destinationloading);
  const { hysaIds, investIds } = useSelector(
    (state) => state.newFlows.MOVE_MONEY.directDepositIds,
  );
  const { previousRouteName } = useSelector(
    (state) => state.newFlows.MOVE_MONEY,
  );

  const isDirectHysa = location.pathname.includes(
    MOVE_MONEY_FLOW_STEPS.DIRECT_DEPOSIT_HYSA,
  );
  const isDirectInvest = location.pathname.includes(
    MOVE_MONEY_FLOW_STEPS.DIRECT_DEPOSIT_INVEST,
  );
  const accountIds = isDirectHysa ? hysaIds : investIds;

  /** We can only start Atomic with a single Invest account because all the direct deposits
   * land in the same M1 account for everyone and we need to know where to send those funds. */
  const canStartAtomic =
    accountIds &&
    ((isDirectHysa && hysaIds) || (isDirectInvest && investIds?.length === 1));
  const atomicStatusPrefix = isDirectHysa ? 'm1_hysa' : 'm1_invest';
  const m1AtomicInitiated: SegmentEventName = `${atomicStatusPrefix}_atomic_initiated`;
  const m1AtomicError: SegmentEventName = `${atomicStatusPrefix}_atomic_error`;
  const m1AtomicCompleted: SegmentEventName = `${atomicStatusPrefix}_atomic_completed`;
  const m1AtomicClosed: SegmentEventName = `${atomicStatusPrefix}_atomic_closed`;

  const handleAtomic = async (accountIds: string[]) => {
    try {
      let successMessage: string | null = null;
      if (isDirectInvest) {
        const { data } = await setDirectDepositDestination({
          variables: { input: { accountId: accountIds[0] } },
        });
        if (
          data?.setDirectDepositDestination.didSucceed &&
          data?.setDirectDepositDestination.outcome?.successMessage
        ) {
          successMessage =
            data.setDirectDepositDestination.outcome.successMessage;
        }
      }
      analytics.recordEvent(m1AtomicInitiated);
      generateAtomicToken({
        variables: {
          input: {
            accountIds,
          },
        },
        onCompleted: (data) => {
          if (
            data.generateAtomicToken?.didSucceed &&
            data.generateAtomicToken.outcome?.token
          ) {
            Atomic.transact({
              config: {
                publicToken: data.generateAtomicToken.outcome.token,
                tasks: [{ product: Product.DEPOSIT }],
              },
              onFinish: () => {
                analytics.recordEvent(m1AtomicCompleted);
                navigate({ to: previousRouteName ?? '/d/move-money' });
                if (successMessage) {
                  addToast({
                    content: successMessage,
                    kind: 'success',
                  });
                }
              },
              onClose: () => {
                analytics.recordEvent(m1AtomicClosed);
                if (isDirectInvest) {
                  // stay on page to select an invest account again
                } else {
                  navigate({ to: previousRouteName ?? '/d/move-money' });
                }
              },
            });
          }
        },
      });
    } catch {
      analytics.recordEvent(m1AtomicError);
      addToast({
        content:
          'Something went wrong. Please try again or contact us for further assistance.',
        kind: 'alert',
      });
    }
  };

  React.useEffect(() => {
    if (canStartAtomic) {
      handleAtomic(accountIds);
    }
  }, [canStartAtomic]);

  if (canStartAtomic) {
    return <Spinner />;
  }

  if (isDirectInvest && investIds) {
    return (
      <InvestDirectDespositAccounts
        accountIds={investIds}
        onSelect={(id) => handleAtomic([id])}
      />
    );
  }

  return <GenericSystemError />;
};
