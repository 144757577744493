import { Theme, Box } from '@m1/liquid-react';
import * as React from 'react';
import { components } from 'react-select';

import { SelectProps } from './Dropdown.types';
import {
  SelectContainerStyledLabel,
  SelectContainerStyledMessage,
} from './SelectContainer.styled';

type SelectContainerProps = {
  backgroundColor?: string;
  children?: React.ReactNode;
  selectProps: SelectProps & {
    appTheme: Theme;
  };
};

export class SelectContainer extends React.Component<SelectContainerProps> {
  render() {
    // FIXME(Wolf): Fix this once you upgrade react-select.
    const props = this.props as any;

    return (
      <components.SelectContainer {...props}>
        <Box position="relative" py={8}>
          {this.props.selectProps.label && (
            <SelectContainerStyledLabel
              htmlFor={this.props.selectProps.name}
              appTheme={this.props.selectProps.appTheme}
              backgroundColor={this.props.selectProps.backgroundColor}
              error={this.props.selectProps.error}
              isDisabled={this.props.selectProps.isDisabled}
              isFocused={this.props.selectProps.isFocused}
            >
              {this.props.selectProps.label}
            </SelectContainerStyledLabel>
          )}
          {this.props.children}
          {this.renderMessage()}
        </Box>
      </components.SelectContainer>
    );
  }

  renderMessage() {
    const { error, warning, info, isDisabled } = this.props.selectProps;
    const message = error || warning || info;
    if (message) {
      return (
        <Box mt={8} ml={16}>
          <SelectContainerStyledMessage
            content={message}
            error={error}
            isDisabled={isDisabled}
          />
        </Box>
      );
    }
    return null;
  }
}
