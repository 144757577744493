import merge from 'lodash-es/merge';
import set from 'lodash-es/set';

import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { IDENTITY_PROFILE_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createStepReducer } from '../utils';

export type IdentityProfileFlowState = FlowState<
  string,
  {
    accountId: string | null | undefined;
    basePath: string;
    input: Record<string, any>;
  }
>;

export const identityFlowInitialState: IdentityProfileFlowState = {
  accountId: null,
  basePath: '',
  input: {},
  step: STEPS.COLLECT_PRIMARY_CONTACT_INFO,
  stepTitle: '',
};

const stepReducer = createStepReducer(identityFlowInitialState);

export function identityProfile(
  state: IdentityProfileFlowState = identityFlowInitialState,
  action: any,
): IdentityProfileFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...identityFlowInitialState,
        basePath: action.payload.basePath,
      };
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return {
        ...state,
        input: inputReducer(state.input, action),
      };
  }
}

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'action' implicitly has an 'any' type.
function inputReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SUBMITTED_CONTACT_INFO_FORM:
    case ACTIONS.SUBMITTED_IDENTITY_INFO_FORM:
    case ACTIONS.COLLECTED_HOLDER_SSN: {
      const { holder, ssn } = action.payload;
      return merge({}, state, {
        [`${holder}Ssn`]: ssn,
        holder,
      });
    }
    case ACTIONS.SUBMITTED_PROFILE_INPUT: {
      const { field, value } = action.payload;
      return merge(
        {
          ...state,
        },
        set({}, field, value),
      );
    }
    default:
      return state;
  }
}
