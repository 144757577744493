import { ApolloError } from '@apollo/client';
import {
  PL,
  Box,
  Flex,
  Color,
  ModalContent,
  ModalTitle,
} from '@m1/liquid-react';
import { Icon, type MonochromaticIconName } from '@m1/liquid-react/icons';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { CopyInput } from '~/components/form/Inputs/CopyInput';
import { useSharePieQuery } from '~/graphql/hooks';
import { useCreatePieShareUrlOnRender } from '~/hooks/useCreatePieShareUrlOnRender';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useToast } from '~/toasts';

import { Spinner } from '~/toolbox/spinner';

type Props = {
  pieId: string;
  onError?: () => void;
};

export const SharePieModal = ({
  pieId,
  onError,
}: Props): React.ReactElement | null => {
  const { analytics, sentry } = React.useContext(AppContext);
  const { addToast } = useToast();

  function handleUrlError(error: ApolloError) {
    sentry.exception(new Error('Error opening SharePieModal'), {
      extra: {
        error,
      },
    });

    // if callback exists, run it
    onError?.(); // same as --> if (onError) { onError(); }

    const inputErrors = error.graphQLErrors.some(
      (err) =>
        err.extensions.code === 'INELIGIBLE_FOR_SHARING' ||
        err.extensions.code === 'REMOVE_BEFORE_SHARING',
    );
    addToast({
      content: inputErrors
        ? error.message
        : 'Something went wrong with your request. Please try again later or contact support.',
      kind: 'alert',
      duration: 'long',
    });
  }

  const { data, loading } = useSharePieQuery({
    onError: handleUrlError,
  });
  const { loading: loadingUrl, data: createPieShareUrlData } =
    useCreatePieShareUrlOnRender(pieId, { onError: handleUrlError });
  const { url, token, socialMediaShareButtons } =
    createPieShareUrlData?.createPieShareUrl ?? {};

  if (loading || loadingUrl) {
    return (
      <Flex my={20} alignContent="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  const sharePieModal = data?.viewer.invest?.sharePie?.sharePieModal;
  const referralsTermsLink = sharePieModal?.referrals?.referralsTermsLink;

  if (!sharePieModal || !url || !referralsTermsLink) {
    return null;
  }

  return (
    <ModalContent>
      <Illustration name="shareAPie" />
      <ModalTitle>{sharePieModal?.title}</ModalTitle>
      <Box pb={16}>
        <PL>{sharePieModal?.subtitle}</PL>
      </Box>
      <Box pr={24} borderRadius={2} mb={32}>
        <PL mb={16} fontWeight={600} color="foregroundNeutralSecondary">
          {sharePieModal.referrals?.referralsTitle}
        </PL>
        <PL mb={16} color="foregroundNeutralSecondary">
          {sharePieModal.referrals?.referralsDescription}
        </PL>
        <Box>
          {Boolean(referralsTermsLink) && (
            <LinkableLink linkable={referralsTermsLink} />
          )}
        </Box>
      </Box>
      <Box mb={16}>
        <CopyInput
          value={url}
          onCopyInput={() =>
            token &&
            analytics.recordEvent('m1_invest_pieshare_link_copied', null, {
              share_pie_token: token,
            })
          }
        />
      </Box>
      <Flex justifyContent="center" gap={24}>
        {socialMediaShareButtons?.map((button) => (
          <a
            key={button.url}
            href={button.url}
            target="_blank"
            rel="noopener noreferrer"
            title={button.title}
          >
            <Icon
              name={button.icon?.names?.[0] as MonochromaticIconName}
              color={button.icon?.color as Color}
              aria-label={button.title}
            />
          </a>
        ))}
      </Flex>
    </ModalContent>
  );
};
