import {
  OnlineAccountRegistrationEnum,
  ProductListIdentifier,
} from '~/graphql/types';
import {
  IDENTITY_FIRST_FLOW_STEP_VALUES,
  INVEST_ONBOARDING_FLOW_STEP_VALUES,
  ONBOARDING_FLOW_STEP_VALUES,
} from '~/static-constants';

export const ONBOARDING_ACTIONS = Object.freeze({
  BEGIN_ONBOARDING_FLOW: 'BEGIN_ONBOARDING_FLOW' as const,
  CHANGE_ONBOARDING_FLOW_STEP: 'CHANGE_ONBOARDING_FLOW_STEP' as const,
  FINISHED_READING_ONBOARDING_INTERSTITIAL:
    'FINISHED_READING_ONBOARDING_INTERSTITIAL' as const,
  FINISHED_ONBOARDING_ACCOUNT_SETUP:
    'FINISHED_ONBOARDING_ACCOUNT_SETUP' as const,
  FINISHED_ONBOARDING_BANK_SETUP: 'FINISHED_ONBOARDING_BANK_SETUP' as const,
  SET_ONBOARDING_NEXT_STEP: 'SET_ONBOARDING_NEXT_STEP' as const,
  SET_ONBOARDING_PRODUCT: 'SET_ONBOARDING_PRODUCT' as const,
  SKIPPED_BANK_SETUP: 'SKIPPED_BANK_SETUP' as const,
  SKIPPED_INITIAL_FUNDING: 'SKIPPED_INITIAL_FUNDING' as const,
  SET_HAS_LOADED_GMAPS_LIBRARY: 'SET_HAS_LOADED_GMAPS_LIBRARY' as const,
  SET_IRA_FUNDING_TYPE: 'SET_IRA_FUNDING_TYPE' as const,
  SUBMITTED_INITIAL_DEPOSIT: 'SUBMITTED_INITIAL_DEPOSIT' as const,
  SKIPPED_INITIAL_DEPOSIT: 'SKIPPED_INITIAL_DEPOSIT' as const,
  REVIEWED_INITIAL_DEPOSIT_ERROR: 'REVIEWED_INITIAL_DEPOSIT_ERROR' as const,
  FINISHED_ONBOARDING_FLOW: 'FINISHED_ONBOARDING_FLOW' as const,
  SET_ONBOARDING_SUBPRODUCT: 'SET_ONBOARDING_SUBPRODUCT' as const,
});

export type ChangeOnboardingFlowStep = {
  type: typeof ONBOARDING_ACTIONS.CHANGE_ONBOARDING_FLOW_STEP;
  payload: {
    step: ONBOARDING_FLOW_STEP_VALUES;
    query: {
      initialStep?:
        | IDENTITY_FIRST_FLOW_STEP_VALUES
        | INVEST_ONBOARDING_FLOW_STEP_VALUES;
      registration?: OnlineAccountRegistrationEnum;
    };
  };
};

export type SetOnboardingProduct = {
  payload: OnboardingProductSelection;
  type: typeof ONBOARDING_ACTIONS.SET_ONBOARDING_PRODUCT;
};

export type OnboardingProductSelection = {
  destination: string;
  productIdentifier: ProductListIdentifier | null | undefined;
};

export type SetOnboardingSubProduct = {
  payload: string;
  type: typeof ONBOARDING_ACTIONS.SET_ONBOARDING_SUBPRODUCT;
};

export type SkippedInitialFundingAction = {
  type: typeof ONBOARDING_ACTIONS.SKIPPED_INITIAL_FUNDING;
};

export type SkippedBankSetupAction = {
  type: typeof ONBOARDING_ACTIONS.SKIPPED_BANK_SETUP;
};

export type SetHasLoadedGmapsLibrary = {
  type: typeof ONBOARDING_ACTIONS.SET_HAS_LOADED_GMAPS_LIBRARY;
};

export type FinishedReadingOnboardingInterstitial = {
  type: typeof ONBOARDING_ACTIONS.FINISHED_READING_ONBOARDING_INTERSTITIAL;
};

export type BeginOnboardingFlowAction = {
  payload: unknown;
  type: typeof ONBOARDING_ACTIONS.BEGIN_ONBOARDING_FLOW;
};

export type FinishedReadingOnboardingInterstitialAction = {
  payload: unknown;
  type: typeof ONBOARDING_ACTIONS.FINISHED_READING_ONBOARDING_INTERSTITIAL;
};

export type ReviewedInitialDepositErrorAction = {
  payload: unknown;
  type: typeof ONBOARDING_ACTIONS.REVIEWED_INITIAL_DEPOSIT_ERROR;
};

export type FinishedOnboardingAccountSetupAction = {
  payload: unknown;
  type: typeof ONBOARDING_ACTIONS.FINISHED_ONBOARDING_ACCOUNT_SETUP;
};

export type FinishedOnboardingBankSetupAction = {
  payload: unknown;
  type: typeof ONBOARDING_ACTIONS.FINISHED_ONBOARDING_BANK_SETUP;
};

export type SubmittedInitialDepositAction = {
  payload: unknown;
  type: typeof ONBOARDING_ACTIONS.SUBMITTED_INITIAL_DEPOSIT;
};

export type SkippedInitialDepositAction = {
  payload: unknown;
  type: typeof ONBOARDING_ACTIONS.SKIPPED_INITIAL_DEPOSIT;
};

export type IRAFundingType = 'NEW' | 'ROLLOVER';
export type SetIRAFundingTypeAction = {
  payload: IRAFundingType;
  type: typeof ONBOARDING_ACTIONS.SET_IRA_FUNDING_TYPE;
};

export type OnboardingAction =
  | BeginOnboardingFlowAction
  | ChangeOnboardingFlowStep
  | FinishedOnboardingAccountSetupAction
  | FinishedReadingOnboardingInterstitial
  | FinishedReadingOnboardingInterstitialAction
  | FinishedOnboardingBankSetupAction
  | ReviewedInitialDepositErrorAction
  | SetHasLoadedGmapsLibrary
  | SetOnboardingProduct
  | SetOnboardingSubProduct
  | SkippedInitialFundingAction
  | SkippedInitialDepositAction
  | SkippedBankSetupAction
  | SubmittedInitialDepositAction
  | SetIRAFundingTypeAction;
