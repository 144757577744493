import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { PieEditorAddSlicesComplete } from '~/redux/actions/PieEditorAction';
import { useDispatch } from '~/redux/hooks';

export type IdArg = {
  pieEditorRouteParam?: Maybe<string | 'new' | 'new-crypto'>;
  isCrypto?: boolean;
  withAddedSlice?: PieEditorAddSlicesComplete['payload']['slices'][number];
};

export const useNewPieEditorEntry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  return ({ pieEditorRouteParam, isCrypto = false, withAddedSlice }: IdArg) => {
    const newRoute = isCrypto ? 'new-crypto' : 'new';
    dispatch({ type: 'PIE_EDITOR_CLEAR_PIE' });
    navigate({
      to: `/d/w/pie-editor/${pieEditorRouteParam ?? newRoute}`,
      query: {
        previousRouteName: location.pathname,
      },
      options: {
        state: {
          addedSlice: withAddedSlice,
        },
      },
    });
  };
};
