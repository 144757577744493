import * as React from 'react';

import { WireTransferContext } from './WireTransferProvider';

export const useWireTransferContext = () => {
  const wireTransfer = React.useContext(WireTransferContext);
  if (!wireTransfer) {
    throw new Error(
      'You must use useWireTransferContext within WireTransferProvider!',
    );
  }
  return wireTransfer;
};
