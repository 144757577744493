import * as React from 'react';

import { ScreenerPriceHistory } from '~/components/research/Screener/ScreenerTable';
import {
  CryptoSortOptionInput,
  CryptoSortTypeEnum,
  HistoricalQuotePeriodEnum,
} from '~/graphql/types';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { Table } from '~/toolbox/table';

import { PERIOD_TO_SORT } from './constants';
import { useCryptoScreenerResult } from './useCryptoScreenerResult';

export const CryptoResultsHeader = ({
  hasCryptoAccount,
}: {
  hasCryptoAccount: boolean | undefined;
}) => {
  const {
    queryResult: { variables },
    handleSortChange,
    handlePeriodChange,
  } = useCryptoScreenerResult();

  const addMode = useSelector((state: AppState) => state.mode === 'ADD');

  const sort = Array.isArray(variables?.sort)
    ? variables?.sort[0]
    : variables?.sort;
  const period = variables?.period;

  const currentPeriodSort =
    PERIOD_TO_SORT[period || HistoricalQuotePeriodEnum.OneYear] ||
    CryptoSortTypeEnum.PriceChange_1Y;

  const readSortDirection = (
    type: CryptoSortTypeEnum,
    sort: CryptoSortOptionInput | null | undefined,
  ): CryptoSortOptionInput['direction'] | null | undefined => {
    return sort && sort.type === type ? sort.direction : null;
  };
  return (
    <Table.Row>
      <Table.HeaderCell
        content="Name"
        width="33%"
        onClick={() => handleSortChange(CryptoSortTypeEnum.Name)}
        sort={readSortDirection(CryptoSortTypeEnum.Name, sort)}
      />
      <Table.HeaderCell
        content="Current Price"
        align="right"
        width="10%"
        onClick={() => handleSortChange(CryptoSortTypeEnum.Price)}
        sort={readSortDirection(CryptoSortTypeEnum.Price, sort)}
      />
      <Table.HeaderCell
        content="Market Cap"
        align="right"
        width="10%"
        onClick={() => handleSortChange(CryptoSortTypeEnum.MarketCap)}
        sort={readSortDirection(CryptoSortTypeEnum.MarketCap, sort)}
      />
      <Table.HeaderCell
        content="Volume"
        align="right"
        width="10%"
        onClick={() => handleSortChange(CryptoSortTypeEnum.Volume)}
        sort={readSortDirection(CryptoSortTypeEnum.Volume, sort)}
      />
      <Table.HeaderCell
        content="Circulating Supply"
        align="right"
        width="15%"
        onClick={() => handleSortChange(CryptoSortTypeEnum.Supply)}
        sort={readSortDirection(CryptoSortTypeEnum.Supply, sort)}
      />
      <Table.HeaderCell
        align="right"
        onClick={() => handleSortChange(currentPeriodSort)}
        sort={readSortDirection(currentPeriodSort, sort)}
        width="20%"
      >
        <ScreenerPriceHistory handlePeriodChange={handlePeriodChange} />
      </Table.HeaderCell>
      <Table.HeaderCell
        align="right"
        width={hasCryptoAccount && !addMode ? '12%' : '0%'}
      >
        {hasCryptoAccount && !addMode ? 'Add to Portfolio' : ''}
      </Table.HeaderCell>
    </Table.Row>
  );
};
