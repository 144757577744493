import {
  Box,
  Card,
  CardProps,
  Flex,
  LS,
  PM,
  PXL,
  Tooltip,
} from '@m1/liquid-react';
import * as React from 'react';

import { TransferDetailsMessageCardFragment } from '~/graphql/types';
import { OnClickLinkable } from '~/hooks/useLinkable';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Pill } from '~/toolbox/Pill';

import { TransferDetailsMessageTooltipBody } from './TransferDetailsMessageTooltipBody';

export type TransferDetailsMessageCardProps = CardProps & {
  detailsMessage: TransferDetailsMessageCardFragment;
  onTransferSuggestionLinkClick?: OnClickLinkable;
};

export const TransferDetailsMessageCard = ({
  detailsMessage,
  onTransferSuggestionLinkClick,
  ...cardProps
}: TransferDetailsMessageCardProps) => {
  const {
    description,
    descriptionTooltip,
    items,
    link,
    title,
    titleTag,
    transferSuggestionLink,
    icon,
  } = detailsMessage;

  let linkToRender = null;
  if (transferSuggestionLink) {
    linkToRender = (
      <LinkableLink
        onClick={onTransferSuggestionLinkClick}
        linkable={transferSuggestionLink}
        font="PS"
      />
    );
  } else if (link) {
    linkToRender = <LinkableLink linkable={link} font="PS" />;
  }

  return (
    <Card
      backgroundColor="backgroundInfoSubtle"
      borderColor="borderInfo"
      {...cardProps}
    >
      <Flex color="foregroundNeutralMain">
        {icon && (
          <Box mr={13}>
            <AppImage appImage={icon} />
          </Box>
        )}
        <Flex flexDirection="column" gap={16} style={{ width: '100%' }}>
          <Flex flexDirection="column" gap={4}>
            {title && (
              <Flex justifyContent="space-between">
                <Flex alignItems="center">
                  <PXL fontWeight={600} content={title} />
                  {titleTag && (
                    <Pill
                      label={titleTag}
                      kind="important"
                      ml={8}
                      size="small"
                    />
                  )}
                </Flex>
              </Flex>
            )}

            {description && (
              <PM color="foregroundNeutralSecondary">
                <Flex alignItems="center">
                  {description}
                  {descriptionTooltip?.icon && (
                    <Tooltip
                      placement="top-start"
                      body={
                        <Box width={330}>
                          <TransferDetailsMessageTooltipBody
                            tooltipBody={descriptionTooltip.tooltipBody}
                          />
                        </Box>
                      }
                    >
                      <AppImage
                        color="primary"
                        appImage={descriptionTooltip.icon}
                        ml={4}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </PM>
            )}
          </Flex>

          {items && (
            <Flex flexDirection="column" gap={4}>
              {items.map(({ title, value, description }, i: number) => (
                <Flex justifyContent="space-between" key={i}>
                  <Flex>
                    <PM content={title} />
                    {description && (
                      <Tooltip
                        icon="tooltip16"
                        iconColor="primary"
                        body={<LS content={description} />}
                      />
                    )}
                  </Flex>
                  <PM content={value} />
                </Flex>
              ))}
            </Flex>
          )}

          {linkToRender && <Box mt={12}>{linkToRender}</Box>}
        </Flex>
      </Flex>
    </Card>
  );
};
