import React from 'react';
import { useFormContext } from 'react-hook-form';

import { RecurrenceScheduleInput } from '~/graphql/types';

import { MonthlyScheduleDateEnumDropdownOptions } from '~/static-constants/dropdown-options';

import { ControlledDropdown } from '../../ControlledDropdown';

export const DayOfMonthDropdown = () => {
  const { control } = useFormContext<{
    schedule: RecurrenceScheduleInput;
  }>();

  return (
    <ControlledDropdown
      control={control}
      rules={{
        required: 'Required',
      }}
      name="schedule.monthly.dayOfMonth"
      label="Day of month"
      placeholder="Select"
      source={MonthlyScheduleDateEnumDropdownOptions}
      style={{
        marginTop: 16,
      }}
    />
  );
};
