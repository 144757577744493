import { Box, Button, Flex, HXS, PXL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { CryptoDisclosure } from '~/components/CryptoDisclosure';
import {
  useCreateTransferInstanceMutation,
  useSetScheduledTransferRuleMutation,
} from '~/graphql/hooks';
import {
  FundedAccountFragment,
  FundingAccountParticipantFragment,
  IraContributionYearEnum,
  RecurrenceScheduleInput,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Logo } from '~/toolbox/logo';
import { Spinner } from '~/toolbox/spinner';
import { isTrue, isNil } from '~/utils';

import { FundAccountConfirmationCard } from './FundAccountConfirmationCard';

type FundAccountConfirmationProps = {
  account: FundedAccountFragment;
  amount: number;
  fromParticipantId: string | null;
  iraContributionYear: IraContributionYearEnum | null;
  isCryptoAccount: boolean;
  isScheduleSwitchOn: boolean;
  onCancelClick: () => void;
  participantsList: FundingAccountParticipantFragment[];
  schedule: RecurrenceScheduleInput | null;
};

export const FundAccountConfirmation = ({
  account,
  amount,
  fromParticipantId,
  iraContributionYear,
  isScheduleSwitchOn,
  onCancelClick,
  participantsList,
  schedule,
}: FundAccountConfirmationProps) => {
  const [fromParticipant, setFromParticipant] =
    React.useState<FundingAccountParticipantFragment | null>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  React.useEffect(() => {
    const selectedFromParticipant = participantsList.find(
      (participant) => participant?.id === fromParticipantId,
    );
    setFromParticipant(selectedFromParticipant ?? null);
  }, [participantsList, fromParticipantId]);

  const navigateToInvest = () => {
    navigate({ to: '/d/invest/portfolio' });
  };

  const showSuccessToast = () => {
    addToast({
      kind: 'success',
      content: `Your deposit’s processing. Once that’s done, your buying power will update.`,
    });
  };

  const [createTransferInstance, { loading: isCreateTransferInstanceLoading }] =
    useCreateTransferInstanceMutation();

  const [
    setScheduledTransferRule,
    { loading: isSetScheduledTransferRuleLoading },
  ] = useSetScheduledTransferRuleMutation();

  const onConfirmClick = (): void => {
    if (fromParticipantId === null) {
      addToast({
        kind: 'alert',
        content: `There was an error. Please try again later. If the problem persists, please contact support.`,
      });
      return;
    }

    createTransferInstance({
      variables: {
        input: {
          toParticipantId: account.id,
          fromParticipantId: fromParticipantId,
          amount,
          iraContributionYear,
        },
      },
      onCompleted() {
        if (isScheduleSwitchOn) {
          setScheduledTransferRule({
            variables: {
              input: {
                toParticipantId: account.id,
                fromParticipantId: fromParticipantId,
                amount,
                schedule: schedule as RecurrenceScheduleInput,
              },
            },
            onCompleted() {
              showSuccessToast();

              navigateToInvest();
            },
            onError(err) {
              addToast({ kind: 'alert', content: err.message });
            },
          });
        } else {
          // Only show the success toast if we're also not
          // setting a scheduled transfer rule:
          showSuccessToast();
          navigateToInvest();
        }
      },
      onError(err) {
        addToast({ kind: 'alert', content: err.message });

        if (!isScheduleSwitchOn) {
          navigateToInvest();
        }
      },
    });
  };

  if (isCreateTransferInstanceLoading || isSetScheduledTransferRuleLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <Box>
      <BackArrow content="Back" my={32} onClick={onCancelClick} />

      <Flex flexDirection="column" alignItems="center" mb={32}>
        <HXS mb={24} content="Confirm deposit" />
        <Flex alignItems="center">
          <Flex mr={16}>
            <Logo
              style={{ height: 26, width: 26 }}
              content={<Icon name="createPie32" />}
              placeholder=""
            />
          </Flex>
          <PXL fontWeight={600} content={account.name} />
        </Flex>
      </Flex>

      <PXL
        fontWeight={600}
        mb={16}
        color="foregroundNeutralMain"
        content="Deposit"
      />

      <FundAccountConfirmationCard
        account={account}
        amount={amount}
        fromParticipant={fromParticipant}
        isScheduleSwitchOn={isScheduleSwitchOn}
        schedule={schedule}
      />

      {isTrue(account.isCryptoAccount) && <CryptoDisclosure />}

      <ButtonGroup my={32}>
        <Button
          size="large"
          kind="secondary"
          label="Cancel"
          onClick={onCancelClick}
        />
        <Button
          size="large"
          label="Confirm"
          onClick={onConfirmClick}
          disabled={isNil(fromParticipant) || amount === 0}
        />
      </ButtonGroup>
    </Box>
  );
};
