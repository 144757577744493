import { styled } from '@m1/liquid-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { LinkProps } from '~/toolbox/link';
import { PillProps } from '~/toolbox/Pill';
import { Tabs, Tab } from '~/toolbox/tabs';

const StyledNavLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
`;

export type SecondaryNavigationItemProps = {
  label: string;
  pill?: PillProps;
  to: Pick<LinkProps, 'to'>['to'];
  state?: Record<string, any>;
};

export type SecondaryNavigationProps = {
  items: SecondaryNavigationItemProps[];
};

export const SecondaryNavigation = ({
  items,
  ...rest
}: SecondaryNavigationProps) => {
  return (
    <Tabs as="nav" {...rest}>
      {items.map((item) => (
        <StyledNavLink to={item.to} key={item.label} state={item.state}>
          {({ isActive }) => <Tab isActive={isActive} {...item} />}
        </StyledNavLink>
      ))}
    </Tabs>
  );
};
