import * as React from 'react';

import { CryptoScreenerQueryResultContext } from './CryptoScreenerProvider';

export const useCryptoScreenerResult = () => {
  const queryResult = React.useContext(CryptoScreenerQueryResultContext);
  if (!queryResult) {
    throw new Error(
      'useCryptoScreenerResult must be used within a CryptoScreenerProvider',
    );
  }
  return queryResult;
};
