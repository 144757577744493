import { InputProps } from '@m1/liquid-react';
import * as React from 'react';

import { ControllerProps, FieldValues, Path } from 'react-hook-form';

import { ControlledInput } from '../ControlledInput';

type InputFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  InputProps & {
    transformInput?: (v: any, prevValue?: string) => any; // display value => data value
    transformValue?: (v: any) => string | number | boolean; // data value => display value
    hint?: string;
  };

type PasswordInputType = 'password' | 'text';

export const PasswordInput = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  transformInput,
  transformValue,
  hint = '10 character minimum; include one lowercase, one uppercase, and one number.',
  ...inputProps
}: InputFieldProps<TName, TFieldValues>) => {
  const [type, setType] = React.useState<PasswordInputType>('password');

  return (
    <ControlledInput
      name={name}
      control={control}
      type={type}
      rules={rules}
      onIconRightClick={() => setType(type === 'text' ? 'password' : 'text')}
      iconRight={type === 'text' ? 'hide24' : 'show24'}
      label="Password"
      helpText={hint}
      {...inputProps}
    />
  );
};
