import { AnalyticsEventFragment } from '~/graphql/types';

import type { SentryReporter } from '../sentry-reporter/sentry-reporter';

import type { SegmentEventName } from './segment.types.ts';

type SegmentOpts = {
  anonymousId?: string | null | undefined;
  context?: any | null | undefined;
  integrations?: any;
  path?: string;
  referrer?: string;
  title?: string;
  url?: string;
};

type Segment = {
  debug: (state?: boolean) => void;
  identify: (
    userId: string,
    traits?: any,
    options?: SegmentOpts,
    callback?: () => void,
  ) =>
    | void
    | ((
        userId: string,
        traits: any,
        callback?: () => void,
      ) =>
        | void
        | ((
            userId: string,
            callback?: () => void,
          ) =>
            | void
            | ((
                traits?: any,
                options?: SegmentOpts,
                callback?: () => void,
              ) =>
                | void
                | ((
                    traits?: any,
                    callback?: () => void,
                  ) => void | ((callback: () => void) => void)))));
  load: (writeKey: string) => void;
  page: (
    name?: string,
    properties?: any,
    callback?: () => void,
  ) =>
    | void
    | ((
        name?: string,
        properties?: any,
        options?: SegmentOpts,
        callback?: () => void,
      ) =>
        | void
        | ((
            category?: string,
            name?: string,
            properties?: any,
            options?: SegmentOpts,
            callback?: () => void,
          ) => void | ((name?: string, callback?: () => void) => void)));
  ready: (callback: () => void) => void;
  reset: () => void;
  timeout: (milliseconds: number) => void;
  track: (
    event: string,
    properties?: any,
    options?: SegmentOpts,
    callback?: () => void,
  ) =>
    | void
    | ((
        event: string,
        properties?: any,
        callback?: () => void,
      ) => void | ((event: string, callback?: () => void) => void));
  user: () => {
    anonymousId(newId?: string): string;
    id(newId?: string | null): string | null | undefined;
    logout(): void;
    reset(): void;
    traits(newTraits?: any): void;
  };
};

type EventParameters = {
  __is_legacy?: boolean;
  event_category?: string;
  event_label?: string;
} & { [key: string]: string | number | boolean };

export class AnalyticsReporter {
  _segment: Segment | null | undefined;
  _isReady: boolean;
  _correlationKey: string | null | undefined;

  constructor() {
    this._isReady = false;
    if (typeof window.analytics !== 'undefined') {
      // Setup Segment object before it's fully loaded to ensure events
      // are still captured to be sent after ready() callback is fired
      this._segment = window.analytics;
      window.analytics.ready(() => {
        // Re-setup Segment once fully loaded to start firing event requests
        this._isReady = true;
        this._segment = window.analytics;
      });
    }
  }

  // will attempt to wait until the analytics object is ready for use
  // if wait time exceeds max wait, then we go ahead and move on
  waitForReady(maxWait: number = 80, interval: number = 20) {
    let waitTime = 0;
    const wait = (resolve: any) => {
      if (this._isReady === true || waitTime > maxWait) {
        resolve();
      } else {
        waitTime += interval;
        setTimeout(() => wait(resolve), interval);
      }
    };
    return new Promise(wait);
  }

  pageView(path: string): void {
    if (this._segment) {
      this._segment.page(path, {
        title: path,
      });
    }
  }

  identifyUser(correlationKey: string): void {
    this._correlationKey = correlationKey;
    if (this._segment) {
      this._segment.identify(correlationKey);
    }
  }

  recordEvent(
    eventName: SegmentEventName,
    eventValue?: number | null | undefined,
    eventParameters?: EventParameters | null | undefined,
  ): void {
    let eventData = {
      ...eventParameters,
    };

    if (typeof eventValue === 'number') {
      eventData = {
        ...eventParameters,
        value: eventValue,
      };
    }
    if (this._segment) {
      this._segment.track(eventName, {
        ...eventData,
      });
    }
  }

  recordAppAnalyticsEvent({
    name,
    valueParameter,
    customParameters,
    customNumberParameters,
    customBoolParameters,
  }: DeepReadonly<AnalyticsEventFragment>): void {
    this.recordEvent(
      // Assume values from lens are valid event names.
      name as SegmentEventName,
      valueParameter,
      fromPairs([
        ...(customParameters ?? []),
        ...(customNumberParameters ?? []),
        ...(customBoolParameters ?? []),
      ]),
    );
  }

  event(category: string, action: SegmentEventName): void {
    this.recordEvent(action, null);
  }

  userProperties(properties: Record<string, string>): void {
    if (this._segment && this._correlationKey) {
      this._segment.identify(this._correlationKey, properties);
    }
  }

  mutation(category: string, action: SegmentEventName): void {
    this.event(category, action);
  }

  reset() {
    this._correlationKey = null;
    if (this._segment) {
      this._segment.reset();
    }
  }

  async getValidAnonymousUserId(sentry: SentryReporter): Promise<string> {
    // wait for segment to be ready before generating anonymous id
    await this.waitForReady();

    let anonymousUserId = `m1-web_${__VERSION__}_${Date.now()}`;
    // Just checking for `analtyics._segment` isn't sufficient. We need to ensure
    // that the `user` function is defined, otherwise the E2E tests will fail:
    try {
      if (typeof this._segment?.user !== 'undefined') {
        anonymousUserId = this._segment.user().anonymousId();
      }
    } catch (e: any) {
      sentry.message('Segment user.anonymousId failed', {
        level: 'error',
        extra: {
          error: e,
        },
      });
    }
    return anonymousUserId;
  }
}

function fromPairs(
  pairs:
    | ReadonlyArray<{
        name: string;
        value: string | number | boolean;
      }>
    | null
    | undefined,
): Record<string, string | number | boolean> | null | undefined {
  if (!pairs) {
    return null;
  }
  const obj: Record<string, string | number | boolean> = {};
  for (const pair of pairs) {
    obj[pair.name] = pair.value;
  }
  return obj;
}
