import * as React from 'react';

import { SecurityDetailsContext } from './SecurityDetailsProvider';

export const useSecurityDetailsContext = () => {
  const securityDetails = React.useContext(SecurityDetailsContext);
  if (!securityDetails) {
    throw new Error(
      'useSecurityDetailsContext must be used within a SecurityDetailsProvider',
    );
  }
  return securityDetails;
};
