import { styled, Flex, theme } from '@m1/liquid-react';
import { Icon, type AllIconName } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { AccountRegistrationEnum, NavigationQuery } from '~/graphql/types';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useLayout } from '~/hooks/useLayout';
import { useNavigate } from '~/hooks/useNavigate';
import { setActiveInvestAccount } from '~/redux/actions/account/accountActions';
import { useSelector } from '~/redux/hooks';

import { InvestAccount } from '../utils/getInvestAccounts';

import { SideNavBorrow } from './SideNavBorrow';
import { SideNavInvest } from './SideNavInvest';
import { SideNavLink } from './SideNavLink';
import { SideNavResearch } from './SideNavResearch';
import { SideNavSave } from './SideNavSave';
import { SideNavSpend } from './SideNavSpend';

export const SIDENAV_TRANSITION_DURATION = '200ms';

export type AccountOptionProps = {
  descriptor?: string | number;
  iconName: AllIconName;
  // TODO see if we can restrict this even further to just the accent versions of the names
  id: string;
  name: string;
  registration?: AccountRegistrationEnum;
  to?: string;
  type?: string;
};

const StyledNav = styled.nav<{ sideNavWidth: number }>`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  border-right: ${({ theme }) => `1px solid ${theme.colors.borderMain}`};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  width: ${({ sideNavWidth }) => `${sideNavWidth}px`};
  transition: ${SIDENAV_TRANSITION_DURATION};
  z-index: 20;

  @media screen and (min-width: ${theme.breakpoints.XSMALL}) {
    height: 100vh;
  }
`;

const StyledHeaderContainer = styled(Flex)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderMain}`};
  flex-direction: column;
  margin: 0 0;
  padding: 0 0 8px 0;
`;

const StyledContentContainer = styled(Flex)`
  flex-direction: column;
  margin: 0 0;
  padding: 8px 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledFooterContainer = styled(Flex)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderMain}`};
  flex-direction: column;
  margin: auto 0 12px 0;
  padding: 8px 0;

  @media screen and (min-width: ${theme.breakpoints.XSMALL}) {
    padding: 8px 0 0;
  }
`;

const StyledLogoBar = styled(Flex)`
  justify-content: space-between;
`;

export type SideNavProps = {
  firstName: string;
  investAccounts: InvestAccount[];
  selectedNavigation: DashboardNavigation;
  data: NavigationQuery | undefined;
};

export const SideNav = ({
  firstName,
  investAccounts,
  selectedNavigation,
  data,
}: SideNavProps) => {
  const { panelStatus, isOpen, openPanel, closePanel, sideNavWidth } =
    useLayout();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedInvestAcctId = useSelector(
    (state) => state.global.investAccountId,
  );

  /*
   * Make sure that an active invest account is always set.
   * This prevents the invest portfolio page from getting into a
   * bad state and forwarding folks to the invest marketing screen
   */
  React.useEffect(() => {
    if (investAccounts.length > 0 && !selectedInvestAcctId) {
      const investAccount = investAccounts.find((acct) => {
        return acct.transferParticipantType === 'INVEST';
      });

      if (investAccount) {
        dispatch(setActiveInvestAccount(investAccount.id));
      }
    }
  }, [dispatch, investAccounts, selectedInvestAcctId]);

  return (
    <StyledNav
      sideNavWidth={sideNavWidth}
      onClick={!isOpen ? openPanel : undefined}
    >
      <StyledHeaderContainer>
        <StyledLogoBar>
          <Icon
            data-testid="side-nav-home"
            name="m1Logo24"
            mt={16}
            mb={16}
            ml={24}
            cursor="pointer"
            onClick={() => {
              navigate({ to: '/d/home' });
            }}
          />
          {panelStatus !== 'collapsed' ? (
            <Icon
              name="exitArrow20"
              mt={16}
              mb={8}
              mr={16}
              onClick={() => {
                if (isOpen) {
                  closePanel();
                } else {
                  openPanel();
                }
              }}
              cursor="pointer"
            />
          ) : null}
        </StyledLogoBar>
        <SideNavLink
          selected={selectedNavigation.activeL1Nav === 'settings'}
          icon={{
            position: 'left',
            default: 'userBasic24',
            active: 'userElevated24',
          }}
          label={firstName}
          linkTo="/d/settings"
          id="settings-nav-link"
        />
      </StyledHeaderContainer>
      <StyledContentContainer>
        <SideNavLink
          id="home-nav-link"
          selected={selectedNavigation.activeL1Nav === 'home'}
          icon={{
            position: 'left',
            default: 'home24',
            active: 'homeElevated24',
          }}
          label="Home"
          linkTo="/d/home"
        />
        <SideNavLink
          id="save-nav-link"
          selected={selectedNavigation.activeL1Nav === 'save'}
          icon={{
            position: 'left',
            default: 'savings24',
            active: 'savingsElevated24',
          }}
          label="Earn"
          linkTo="/d/save"
          children={
            <SideNavSave data={data} selectedNavigation={selectedNavigation} />
          }
        />
        <SideNavLink
          id="spend-nav-link"
          selected={selectedNavigation.activeL1Nav === 'spend'}
          icon={{
            position: 'left',
            default: 'spend24',
            active: 'spendElevated24',
          }}
          label="Spend"
          linkTo="/d/spend"
          children={
            <SideNavSpend data={data} selectedNavigation={selectedNavigation} />
          }
        />
        <SideNavLink
          id="invest-nav-link"
          selected={
            selectedNavigation.activeL1Nav === 'invest' ||
            selectedNavigation.activeL3Nav === 'acat-wizard'
          }
          icon={{
            position: 'left',
            default: 'invest24',
            active: 'investElevated24',
          }}
          label="Invest"
          linkTo="/d/invest"
          children={
            <SideNavInvest
              hasActiveInvestAccount={
                data?.viewer?.invest?.hasActiveInvestAccount ??
                investAccounts.length > 0
              }
              selectedNavigation={selectedNavigation}
              accounts={investAccounts}
              acatPromotion={data?.viewer.invest?.acatWizardInfo?.acatPromotion}
            />
          }
        />
        <SideNavLink
          id="borrow-nav-link"
          selected={selectedNavigation.activeL1Nav === 'borrow'}
          icon={{
            position: 'left',
            default: 'borrow24',
            active: 'borrowElevated24',
          }}
          label="Borrow"
          linkTo="/d/borrow"
          children={
            <SideNavBorrow
              selectedNavigation={selectedNavigation}
              data={data}
            />
          }
        />
        <SideNavLink
          id="research-nav-link"
          selected={selectedNavigation.activeL1Nav === 'research'}
          icon={{
            position: 'left',
            default: 'research24',
            active: 'researchElevated24',
          }}
          label="Research"
          linkTo="/d/research"
          children={<SideNavResearch selectedNavigation={selectedNavigation} />}
        />
      </StyledContentContainer>
      <StyledFooterContainer>
        <SideNavLink
          id="disclosures-nav-link"
          icon={{
            position: 'left',
            default: 'disclosures24',
            active: 'disclosures24',
          }}
          label="Disclosures"
          openInNewWindow="https://m1.com/legal/disclosures/"
        />
        <SideNavLink
          id="help-nav-link"
          icon={{
            position: 'left',
            default: 'contactSupport24',
            active: 'contactSupport24',
          }}
          label="Help"
          onClick={() => {
            if (window.Intercom === undefined) {
              navigate({ to: '/d/contact-us' });
            } else {
              window.Intercom('show');
            }
          }}
        />
        <SideNavLink
          id="logout-nav-link"
          icon={{
            position: 'left',
            default: 'logOut24',
            active: 'logOut24',
          }}
          label="Log out"
          linkTo="/logout"
        />
      </StyledFooterContainer>
    </StyledNav>
  );
};
