import { Button, type ButtonProps } from '@m1/liquid-react';
import * as React from 'react';

import { MoveMoneyMenu } from '~/components/MoveMoneyMenu/MoveMoneyMenu';
import {
  TransferFundsButtonFragment,
  TransferFundsButtonViewerFragment,
} from '~/graphql/types';

import { NavigatePropsObject, useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';

type TransferFundsButtonProps = {
  account: TransferFundsButtonFragment | null | undefined;
  kind: ButtonProps['kind'];
  size: ButtonProps['size'];
  viewer: TransferFundsButtonViewerFragment | null | undefined;
};

export const TransferFundsButton = ({
  account,
  kind = 'primary',
  size = 'medium',
  viewer,
}: TransferFundsButtonProps) => {
  const navigate = useNavigate();
  const { currentRoute, toParticipantId } = useSelector<{
    currentRoute: string | null | undefined;
    toParticipantId: string | null | undefined;
  }>((state) => {
    const { appHistory } = state.routing;

    return {
      currentRoute: appHistory.length > 0 ? state.routing.appHistory[0] : null,
      toParticipantId: state.global.activeAccountId,
    };
  });

  // If the account is liquidating then its ineligible
  if (!account || !viewer || account.isLiquidating) {
    return null;
  }

  const isAchRelationshipRequired = Boolean(
    typeof account.allowsExternalFunding === 'boolean' &&
      account.allowsExternalFunding &&
      (!account.lastAchRelationship || !account.lastAchRelationship.isActive),
  );

  const hasAvailableTransferParticipants = Boolean(
    viewer.transfers?.participants && !viewer.transfers.participants.isEmpty,
  );

  const TransferButton = ({
    label,
    to,
    query,
    showMenu,
  }: {
    label: string;
    showMenu: boolean;
  } & NavigatePropsObject) => {
    if (showMenu) {
      return (
        <MoveMoneyMenu>
          <Button kind={kind} label={label} size={size} />
        </MoveMoneyMenu>
      );
    }

    const finalQuery = currentRoute
      ? {
          previousRouteName: currentRoute,
          ...query,
        }
      : query;

    return (
      <Button
        kind={kind}
        label={label}
        onClick={() => {
          navigate({
            to,
            query: finalQuery,
          });
        }}
        size={size}
      />
    );
  };

  if (isAchRelationshipRequired) {
    return (
      <TransferButton
        label="Add bank"
        to="/d/c/connect-bank"
        showMenu={false}
      />
    );
  }

  if (account.isCryptoAccount && !account.isFunded) {
    return (
      <TransferButton
        label="Move money"
        to="/d/invest/fund-account"
        showMenu={false}
      />
    );
  }

  if (hasAvailableTransferParticipants && toParticipantId) {
    return <TransferButton label="Move money" to="/d/move-money" showMenu />;
  }

  return null;
};
