import { DocumentTypeFilterEnumType } from '~/graphql/types';

export const routes = [
  {
    documentType: DocumentTypeFilterEnumType.Statement,
    label: 'Statements',
    path: 'statements',
  },
  {
    documentType: DocumentTypeFilterEnumType.TaxForm,
    label: 'Tax forms',
    path: 'tax-forms',
  },
  {
    documentType: DocumentTypeFilterEnumType.TradeConfirmation,
    label: 'Trade confirmations',
    path: 'trade-confirmations',
  },
  {
    documentType: DocumentTypeFilterEnumType.AccountDocument,
    label: 'Account documents',
    path: 'account-documents',
  },
];
