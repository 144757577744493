import { Box, Button } from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PasswordInput } from '~/components/form/Inputs/PasswordInput';
import { securePassword } from '~/forms/validators';
import { useChangePasswordMutation } from '~/graphql/hooks';
import { ChangePasswordInput } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';

const MAX_LENGTH = 255;

export type ChangePasswordPayload = Omit<
  ChangePasswordInput,
  'clientMutationId'
>;

export const ChangePasswordForm = () => {
  const { addToast } = useToast();

  const [changePassword, { loading }] = useChangePasswordMutation();

  usePortaledSpinner(loading);

  const formMethods = useForm<ChangePasswordPayload>({ mode: 'all' });

  const handleChangePasswordFormSubmit = async (
    payload: ChangePasswordPayload,
  ) => {
    await changePassword({
      variables: { input: payload },
      onCompleted: () => {
        addToast({
          content: 'Password Changed',
          kind: 'success',
          duration: 'short',
        });

        formMethods.reset({ oldPassword: '', newPassword: '' });
      },
      onError: () => {
        addToast({
          content: 'There was a problem changing your password.',
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleChangePasswordFormSubmit)}>
        <Box mt={8} maxWidth={550}>
          <PasswordInput
            name="oldPassword"
            id="oldPassword"
            rules={{
              required: 'Required',
            }}
            control={formMethods.control}
            label="Current password"
            hint=""
          />
        </Box>
        <Box maxWidth={550}>
          <PasswordInput
            name="newPassword"
            id="newPassword"
            rules={{
              required: 'Required',
              minLength: 4,
              maxLength: MAX_LENGTH,
              validate: (value = '') => {
                if (!value) {
                  return undefined;
                }
                const oldPassword = formMethods.watch('oldPassword');

                if (oldPassword === value) {
                  return 'Passwords cannot be reused.';
                }

                return securePassword(value) ?? undefined;
              },
            }}
            control={formMethods.control}
            label="New password"
          />
        </Box>
        <Button
          mt={32}
          disabled={
            !formMethods.formState.isDirty || !formMethods.formState.isValid
          }
          label="Submit"
          kind="primary"
          type="submit"
        />
      </form>
    </FormProvider>
  );
};
